import {gql} from "@apollo/client"

export default gql`
  query GetSchoolClassrooms(
    $schoolId: GraphQLObjectId
    $teacherIds: [GraphQLObjectId!]
    $includeArchived: Boolean
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
  ) {
    classrooms(
      schoolId: $schoolId
      teacherIds: $teacherIds
      includeArchived: $includeArchived
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        id: _id
        createdAt
        updatedAt
        defaultExams {
          displayCode
        }
        isArchived
        joinCode
        name
        school {
          _id
          createdAt
          updatedAt
          code
          name
          state
          city
          logo
          remainingLicenseSeats {
            coursesRemainTotal
            examsRemainTotal
            labsRemainTotal
            practicesRemainTotal
          }
        }
        status
        assignedTeachers
        createdByUserId
        students {
          total
          hasMore
        }
        teachers {
          _id
          id: _id
          username
          firstName
          lastName
          fullName
        }
        invitations {
          _id
          status
        }
        waitingList {
          _id
          createdAt
          updatedAt
          status
          failedReason
          decidedAt
          studentId
          classroomId
          student {
            _id
            id: _id
            username
            firstName
            lastName
            email
            fullName
            photoUrl
          }
        }
      }
    }
  }
`
