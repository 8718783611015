import {gql} from "@apollo/client"

export default gql`
  query ExportTestingSessions(
    $districtId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $userId: GraphQLObjectId
    $examCodes: [String!]
    $dateStart: DateTimeISO
    $dateEnd: DateTimeISO
    $showCanceled: Boolean
    $isPassed: Boolean
    $showResult: String
    $classroomId: GraphQLObjectId
  ) {
    exportTestingSessions(
      districtId: $districtId
      schoolId: $schoolId
      userId: $userId
      examCodes: $examCodes
      dateStart: $dateStart
      dateEnd: $dateEnd
      showCanceled: $showCanceled
      isPassed: $isPassed
      showResult: $showResult
      classroomId: $classroomId
    ) {
      url
    }
  }
`
