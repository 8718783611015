import {useLazyQuery} from "@apollo/client"
import GET_EXAM_SESSION from "api/apollo/queries/GET_EXAM_SESSION"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {LINKS} from "consts/links"
import {
  ExamSessionStatus,
  GetExamSessionQuery,
  GetExamSessionQueryVariables
} from "generated/graphql"
import React, {useEffect, useMemo} from "react"
import {Box, CircularProgress} from "@mui/material"
import {useDispatch} from "react-redux"
import Info from "pages/SessionDetails/components/Info"
import Objectives from "pages/SessionDetails/components/Objectives"
import {useNavigate, useParams} from "react-router-dom"
import {
  fetchSessionExamDetails,
  getTasksAnswers,
  resetSessionInfo
} from "store/slices/practiceSession/practiceSession"
import {useSelector} from "store"
import {
  clearCurrentExamDetails,
  fetchExamDetailsByIdThunk
} from "store/slices/exams"
import useMainPageLink from "hooks/useMainPageLink"
import {unwrapResult} from "@reduxjs/toolkit"
import {ICorrectAnswers} from "pages/PracticeAnswers"
import ControlledAccordion from "components/ControlledAccordion"

interface Props {
  isPractice?: boolean
  breadcrumbs: {text: string, path?: "VAR" | string}[]
  isDistrict?: boolean
}

export default function SessionDetails({
  isPractice,
  isDistrict,
  breadcrumbs
}: Props) {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const session = useSelector((state) => {
    if (params.id) {
      if (isPractice) {
        const loading = state.practiceSession.loading
        const curr = state.practiceSession.currentSession
        const {
          result,
          examCode,
          userName,
          examName,
          finishDate,
          status,
          displayCode,
          options
        } = curr || {}

        const {score, isPassed, objectives} = result || {}

        return {
          examCode,
          displayCode,
          score,
          isPassed,
          objectives,
          userName,
          examName,
          finishDate,
          loading,
          status,
          showAnswers: options?.showResult
        }
      } else {
        const loading = state.examsSlice.loading
        const curr = state.examsSlice.currentExamDetails
        const {
          score,
          isPassed,
          objectives,
          userName,
          examName,
          finishDate,
          examCode,
          displayCode
        } = curr || {}

        return {
          examCode,
          displayCode,
          score,
          isPassed,
          objectives,
          userName,
          examName,
          finishDate,
          loading,
          showAnswers: undefined
        }
      }
    }
  })
  const currentSession = useSelector(state => state.practiceSession.currentSession)
  const taskAnswers = useSelector(state => state.practiceSession.tasksAnswers)

  const {mainPageLink, isStudent} = useMainPageLink()

  const [examSessionQueryFetch, examSessionQuery] = useLazyQuery<
    GetExamSessionQuery,
    GetExamSessionQueryVariables
  >(GET_EXAM_SESSION, {
    fetchPolicy: "network-only"
  })

  const examSession = useMemo(() => {
    return examSessionQuery.data?.getExamSession || null
  }, [examSessionQuery.data])

  useEffect(() => {
    return () => {
      dispatch(resetSessionInfo())
      dispatch(clearCurrentExamDetails())
    }
  }, [])

  const pageTitle = useMemo(() => {
    return isPractice ? "Practice Test Details" : "Exam Details"
  }, [isPractice])

  const serializedObjectives = useMemo(() => {
    if (!!session?.objectives?.length) {
      return session.objectives.map((obj) => {
        return {...obj, color: obj.score > 74 ? "#1ddc47" : "#ea4545"}
      })
    } else {
      return []
    }
  }, [session])

  const serializedTasks: Array<ICorrectAnswers> = useMemo(() => {
    if (!isPractice) {
      return null
    }

    const tasks = currentSession?.tasks

    if (!tasks?.length) {
      return []
    }

    return tasks.reduce((acc, item) => {
      const {id, submitted, validated, task, correctAnswer} = item || {}
      const {description, displayName, type, multipleDetails} = task || {}
      const codeSnippet = multipleDetails?.codeSnippet || null
      const answers = taskAnswers?.filter(i => !!i.answered).find(i => i.taskId === id)?.answers || undefined

      if (!displayName) return acc

      const taskObject = {
        id,
        submitted,
        validated,
        correctAnswer,
        description,
        displayName,
        type,
        multipleDetails,
        codeSnippet,
        answers
      }

      acc.push(taskObject)

      return acc
    }, [])
  }, [currentSession?.tasks, taskAnswers, isPractice])

  const breadcrumbsPathBack = useMemo(() => {
    if (isStudent) {
      return isPractice ? "/practice-results" : "/my-results"
    }

    return isPractice ? "/student-practice-results" : "/student-results"
  }, [isStudent, isPractice])

  const title = useMemo(() => {
    if (session) {
      const name = session.loading ? "Loading..." : session.examName || "Unknown"
      const code = session.examCode?.toUpperCase() || ""

      return name + ((session.loading || !code) ? "" : ` (${code})${isPractice ? " - Practice Test" : ""}`)
    } else {
      return ""
    }
  }, [isPractice, session])

  useEffect(() => {
    const initPage = async () => {
      if (!!params?.id) {
        if (!isPractice) {
          try {
            await examSessionQueryFetch({
              variables: {
                examSessionId: params.id
              }
            })

            const wrappedDetails = await dispatch(fetchExamDetailsByIdThunk(params.id))

            unwrapResult(wrappedDetails as any)
          } catch (err) {
            console.warn(err)
            navigate(isDistrict ? LINKS.districtAdmin : mainPageLink)
          }
        } else {
          try {
            const wrappedDetails = await dispatch(fetchSessionExamDetails({sessionId: params.id}))
            const wrappedAnswers = await dispatch(getTasksAnswers(params.id))

            unwrapResult(wrappedDetails as any)
            unwrapResult(wrappedAnswers as any)
          } catch (err) {
            console.warn(err)
            navigate(isDistrict ? LINKS.districtAdmin : mainPageLink)
          }
        }
      }
    }

    initPage()
  }, [params?.id, isPractice])

  return (
    <LayoutWithBreadcrumbs
      card={false}
      helmetTitle={`${pageTitle} | KP Platform`}
      title={pageTitle}
      breadcrumbs={[...breadcrumbs.map(i => {
        if (i.path === "VAR") {
          return {
            ...i,
            path: breadcrumbsPathBack
          }
        } else {
          return i
        }
      }), {
        text: title
      }]}>
      {(session.loading || examSessionQuery.loading) ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={32}>
          <CircularProgress/>
        </Box>
      ) : (
        <Box>
          <Box>
            <Info
              examScore={session.score}
              examStatus={session.isPassed}
              examCode={session.examCode}
              displayCode={session.displayCode}
              candidateName={session.userName}
              examName={session.examName}
              finishDate={session.finishDate}
              isPractice={isPractice}
              showAnswers={session.showAnswers}
              status={session.status}
              certificateId={examSession?.certificateId}
            />
            {session.status !== ExamSessionStatus.InProgress ? (
              <Objectives
                objectives={serializedObjectives}
                isPassed={session.isPassed}
                isPracticeResPage={isPractice}
                examName={session.examName}
                examCode={session.examCode}
              />
            ) : null}
          </Box>
          {!!serializedTasks?.length && (
            <Box mt={3}>
              <ControlledAccordion questions={serializedTasks} backgroundColorProp="#fff"/>
            </Box>
          )}
        </Box>
      )}
    </LayoutWithBreadcrumbs>
  )
}
