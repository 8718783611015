import React from "react"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import {LoadingButton} from "@mui/lab"

interface Props {
  handlePrev?: () => void
  handleNext?: () => void
  prevText?: string
  nextText?: string
  disabledPrev?: boolean
  disabledNext?: boolean
  prevSecondary?: boolean
  loading?: boolean
}

export default function PrevNextSection({
  handlePrev,
  handleNext,
  prevText = "PREVIOUS",
  nextText = "NEXT",
  disabledPrev = false,
  disabledNext = false,
  prevSecondary,
  loading
}: Props) {
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          alignItems: "flex-start",
          display: "flex"
        }}
      >
        {handlePrev && (
          <Button
            variant={prevSecondary ? "contained" : "outlined"}
            color="secondary"
            onClick={() => !loading && handlePrev()}
            disabled={disabledPrev}
            size="large">
            {prevText}
          </Button>
        )}
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {handleNext && (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={() => handleNext()}
            disabled={disabledNext}
            size="large">
            {nextText}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  )
}
