import {gql} from "@apollo/client"

export default gql`
  query GetExamSession($examSessionId: GraphQLObjectId!) {
    getExamSession(examSessionId: $examSessionId) {
      _id
      type
      status
      expirationDate
      examCode
      isActive
      certificateId
      _isPassed
      _score
      user {
        fullName
      }
      exam {
        passingScoreRate
        displayName {
          value
        }
      }
      instance {
        urlForEmbedding
        username
        password
      }
      flags
      _taskIds
      _tasks {
        _id
        type
        displayName
        description
        examCode
        subObjectiveCode
        objectiveCode
      }
      answers {
        _id
        taskId
        answered
        validated
        score
        sourceCode
      }
      progress {
        averageObjectiveScore
        objective {
          _id
          title {
            value
          }
        }
      }
    }
  }
`
