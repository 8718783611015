export const ENDPOINT = {
  program: "/program",
  exam: "/exam",
  exams: "/exams",
  programs: "/programs",
  examSession: "/exam-session",
  organization: "/organization",
  license: "/license",
  group: "organization/exam-group",
  currentGroup: "/exam-group",
  user: "/user",
  userRole: "/user/role",
  usersByRole: "/user/exam-group-info",
  payment: "/pay-off",
  notification: "/notification",
  sme: "/sme/",
  smeExamQuestions: "/sme/",
  smeTask: "/sme/task/",
  taskEvaluationScore: "/smeTasks/score",
  taskEvaluationReview: "/smeTasks/review",
  practiceVoucher: "/testing/vouchers",
  redeemVoucher: "/voucher/redeem",
  getAvailableExamCodes: "/testing/exams/codes",
  getExamCodesHaveSessions: "/testing/exams-sessions/codes",
  getExamCodesHavePracticeSessions: "/testing/sessions/codes",
  createNewSession: "/testing/sessions",
  getObjectives: "/objectives",
  getTask: "/testing/sessions",
  finishSessionExam: "/testing/sessions",
  testingGroups: "/testing-groups",
  students: "/users",
  batchStudents: "/users/batch",
  batchStudentsToClass: "/users/batch/classroom",
  users: "/users",
  registerAsProctor: "/users/me/proctor",
  updateUserInfo: "/users/me",
  assignPracticeTests: "/testing/vouchers/students",
  classrooms: "/classrooms"
}
