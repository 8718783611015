import {useLazyQuery} from "@apollo/client"
import {Box} from "@mui/material"
import GET_CLASSROOM_DEFAULT_EXAMS from "api/apollo/queries/GET_CLASSROOM_DEFAULT_EXAMS"
import GET_CLASSROOM_STUDENTS_PROGRESS from "api/apollo/queries/GET_CLASSROOM_STUDENTS_PROGRESS"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import DataTable from "components/DataTable"
import {DataTableFilters, DataTableQueryUpdate, DataTableSchema} from "components/DataTable/types.t"
import ScoreLinear from "components/ScoreLinear"
import {
  Classroom, Exam, GetClassroomDefaultExamsQuery, GetClassroomDefaultExamsQueryVariables,
  GetClassroomStudentsProgressQuery,
  GetClassroomStudentsProgressQueryVariables,
  GetExamCodesQuery,
  GetExamCodesQueryVariables,
  User
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"

interface Props {
  classroom: DeepPartial<Classroom>
}

export default function ProgressContent({
  classroom
}: Props) {
  const dispatch = useDispatch()

  const [progressData, setProgressData] = useState<{
    objectives: Array<{
      id: string
      title: string
    }>
    students: QueryDataType<DeepPartial<User>>
  }>(null)

  const [examCodesData, setExamCodesData] = useState<
    DeepPartial<Exam>[] | null
  >(null)

  const [examCodesQueryFetch, examCodesQuery] = useLazyQuery<
    GetExamCodesQuery,
    GetExamCodesQueryVariables
  >(GET_EXAM_CODES)

  const [classroomDefaultExamsQueryFetch, classroomDefaultExamsQuery] = useLazyQuery<
    GetClassroomDefaultExamsQuery,
    GetClassroomDefaultExamsQueryVariables
  >(GET_CLASSROOM_DEFAULT_EXAMS)

  const [classroomStudentsProgressQueryFetch, classroomStudentsProgressQuery] = useLazyQuery<
    GetClassroomStudentsProgressQuery,
    GetClassroomStudentsProgressQueryVariables
  >(GET_CLASSROOM_STUDENTS_PROGRESS)

  const loading = useMemo(() => {
    return examCodesQuery.loading || classroomStudentsProgressQuery.loading || classroomDefaultExamsQuery.loading
  }, [examCodesQuery.loading, classroomDefaultExamsQuery.loading, classroomStudentsProgressQuery.loading])

  const progressError = useMemo(() => {
    return !(progressData?.objectives?.length || !progressData?.students?.items?.length) && ((
      classroomStudentsProgressQuery.error
    ) || (
      examCodesQuery.error
    ) || (
      classroomDefaultExamsQuery.error
    ) || null)
  }, [progressData, examCodesQuery.error, classroomDefaultExamsQuery.error, classroomStudentsProgressQuery.error])

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Last Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "lastName",
        sort: "lastName"
      },
      {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "firstName",
        sort: "firstName"
      },
      ...(progressData?.objectives || []).map(i => ({
        type: "custom" as const,
        headerText: i.title,
        headerNoWrap: true,
        content: (data: User) => {
          const {
            answerRate,
            successRate
          } = data.studentProgress?.progress.find(item => item.objective._id === i.id) || {}

          return (
            <Box position="relative" width={140} pr={2}>
              <Box>
                <ScoreLinear label="Coverage" value={answerRate * 100}/>
              </Box>
              <Box mt={1.5}>
                <ScoreLinear label="Score" value={successRate * 100}/>
              </Box>
            </Box>
          )
        }
      }))
    ]
  }, [progressData, examCodesData])

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      resettable: false,
      main: [
        examCodesData?.length && {
          id: "examCode",
          type: "select-single",
          label: "Exam Code",
          loading: classroomDefaultExamsQuery.loading || examCodesQuery.loading,
          loadOnce: true,
          width: 400,
          initialValue: examCodesData[0].code,
          options: [
            ...(examCodesData || []).filter(i => Boolean(i.code)).map(i => ({
              label: `${i.displayCode} - ${i.displayName.value}`,
              value: i.code
            }))
          ]
        }
      ]
    }
  }, [examCodesData, examCodesQuery.loading, classroomDefaultExamsQuery.loading])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, filters} = state
    const examCode = filters?.find(i => i.id === "examCode")?.value

    if (examCode) {
      classroomStudentsProgressQueryFetch({
        fetchPolicy,
        variables: {
          classroomId: classroom._id,
          examCode,
          take: itemsPerPage,
          offset: itemsPerPage * page,
          sortBy: sort?.key,
          order: sort?.order
        }
      }).then(res => {
        const getObjectives = (students: DeepPartial<User>[]) => {
          return students.reduce<typeof progressData["objectives"]>((acc, item) => {
            item.studentProgress.progress.forEach(i => {
              if (!acc.find(el => el.id === i.objective._id)) {
                acc.push({
                  id: i.objective._id,
                  title: i.objective.title.value
                })
              }
            })

            return acc
          }, [])
        }

        const studentsData = res.data?.getClassroom?.students
        const sortedObjectives = getObjectives(studentsData?.items || []).sort((a, b) => {
          return a.title.localeCompare(b.title, undefined, {
            numeric: true,
            sensitivity: "base"
          })
        })

        setProgressData({
          objectives: sortedObjectives,
          students: studentsData
        })
      }).catch(err => {
        dispatch(handleError(err))
      })
    } else {
      setProgressData(null)
    }
  }

  useEffect(() => {
    if (classroom.defaultExamFilter.length) {
      classroomDefaultExamsQueryFetch({
        variables: {
          classroomId: classroom._id
        }
      }).then(res => {
        setExamCodesData(res.data?.getClassroom?.defaultExams || null)
      })
    } else {
      examCodesQueryFetch().then(res => {
        setExamCodesData(res.data?.exams?.items || null)
      })
    }
  }, [classroom])

  return (
    <Box position="relative">
      <DataTable
        schema={tableSchema}
        data={progressData?.students?.items}
        loading={loading}
        loadingMessage="Collecting data..."
        emptyCondition={() => {
          return !progressData?.objectives?.length
        }}
        error={!!progressError}
        rowsPerPageLimit={10}
        itemsTotalCount={progressData?.students?.total}
        lastPage={!progressData?.students?.hasMore}
        onQueryUpdate={handleQuery}
        filters={tableFilters}
      />
    </Box>
  )
}
