import client from "api/apollo"
import GET_DISTRICT from "api/apollo/queries/GET_DISTRICT"
import {
  GetDistrictQuery, GetDistrictQueryVariables
} from "generated/graphql"
import {IDistrictService, IFetchDistrictServiceMethods} from "types/services/district.service.t"

const FetchDistrictServiceMethods: IFetchDistrictServiceMethods = {
  fetchDistrictInfo: async (districtId) => {
    const {data} = await client.query<
      GetDistrictQuery,
      GetDistrictQueryVariables
    >({
      query: GET_DISTRICT,
      fetchPolicy: "network-only",
      variables: {
        districtId
      }
    })

    return data?.getDistrict
  }
}


const DistrictService: IDistrictService = {
  ...FetchDistrictServiceMethods
}

export default DistrictService
