import "react-perfect-scrollbar/dist/css/styles.css"
import "nprogress/nprogress.css"
import "./styles/index.css"
import "./styles/fonts.css"
import {Amplify} from "aws-amplify"
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import {HelmetProvider} from "react-helmet-async"
import {BrowserRouter} from "react-router-dom"
import {Provider as ReduxProvider} from "react-redux"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import App from "./App"
import {AuthProvider} from "contexts/AmplifyContext"
import {SettingsProvider} from "contexts/SettingsContext"
import store from "store"
import {amplifyConfig} from "config"
import {cognitoUserPoolsTokenProvider} from "aws-amplify/auth/cognito"
import {CookieStorage} from "aws-amplify/utils"

const kpTarget = /\.knowledge-pillars\.com/

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      networkDetailAllowUrls: [kpTarget]
    })
  ],
  tracePropagationTargets: ["localhost", kpTarget],
  sampleRate: 0.075,
  tracesSampleRate: 0.075,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.05
})

Amplify.configure(amplifyConfig)

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: window.location.hostname,
  sameSite: "none"
}))

if (typeof Object.hasOwn !== "function") {
  Object.defineProperty(Object, "hasOwn", {
    value: function (obj: object, prop: PropertyKey): boolean {
      return Object.prototype.hasOwnProperty.call(obj, prop)
    },
    writable: true,
    configurable: true,
    enumerable: false
  })
}

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
)
