import {useMutation, useQuery} from "@apollo/client"
import CancelIcon from "@mui/icons-material/Cancel"
import DoneIcon from "@mui/icons-material/Done"
import {Box, Button, Paper, Typography} from "@mui/material"
import DELETE_INVITATION from "api/apollo/mutations/DELETE_INVITATION"
import RESPOND_JOIN_REQUEST from "api/apollo/mutations/RESPOND_JOIN_REQUEST"
import GET_INVITATIONS from "api/apollo/queries/GET_INVITATIONS"
import AddStudents from "components/AddStudents"
import ConfirmationAlert from "components/ConfirmationAlert"
import DataTable from "components/DataTable"
import {DataTableSchema} from "components/DataTable/types.t"
import StudentsTable from "components/StudentsTable"
import {
  Classroom,
  DeleteInvitationMutation,
  DeleteInvitationMutationVariables,
  GetInvitationsQuery,
  GetInvitationsQueryVariables,
  InvitationStatus, InvitationTarget,
  RespondClassroomJoinRequestMutation,
  RespondClassroomJoinRequestMutationVariables,
  WaitingListStatus
} from "generated/graphql"
import React, {Fragment, useMemo, useState} from "react"
import {useDispatch} from "store"
import {customNotifications} from "store/slices/notifier/notificationObject"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import formatDate from "utils/formatDate"

interface Props {
  classroom: DeepPartial<Classroom>
  onUpdate?: () => void
}

export default function RosterContent({
  classroom,
  onUpdate
}: Props) {
  const dispatch = useDispatch()

  const [studentTableKey, setStudentTableKey] = useState(0)
  const [invitationToCancel, setInvitationToCancel] = useState<typeof waitingList[0] | null>(null)

  const invitationsQuery = useQuery<
    GetInvitationsQuery,
    GetInvitationsQueryVariables
  >(GET_INVITATIONS, {
    variables: {
      classroomId: classroom._id,
      targetEntity: InvitationTarget.Classroom,
      status: InvitationStatus.Pending
    }
  })

  const pendingInvitationsData = useMemo(() => {
    return invitationsQuery.data?.invitations.items
  }, [invitationsQuery.data])

  const waitingList = useMemo(() => {
    if (pendingInvitationsData?.length || classroom.waitingList) {
      const list = [...(pendingInvitationsData || []), ...classroom.waitingList]

      return list.map(i => ({
        ...i,
        id: i._id,
        user: i.__typename === "WaitingList" ? i.student : i.user
      }))
    }
  }, [pendingInvitationsData, classroom.waitingList])

  const [respondJoinRequest] = useMutation<
    RespondClassroomJoinRequestMutation,
    RespondClassroomJoinRequestMutationVariables
  >(RESPOND_JOIN_REQUEST)

  const [cancelInvitation] = useMutation<
    DeleteInvitationMutation,
    DeleteInvitationMutationVariables
  >(DELETE_INVITATION)

  const tableSchema: DataTableSchema<typeof waitingList[0]> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Email",
        fieldName: "user.email"
      },
      {
        type: "custom",
        headerText: "Name",
        content: data => {
          return data.user ? `${data.user.lastName} ${data.user.firstName}` : ""
        }
      },
      {
        type: "text",
        headerText: "Status",
        fieldName: "status"
      },
      {
        type: "custom",
        headerText: "Created At",
        content: data => {
          return "createdAt" in data ? formatDate(data.createdAt) : ""
        },
        sort: "createdAt"
      },
      {
        type: "custom",
        headerText: "",
        content: data => (
          <>
            {data.__typename === "Invitation" ? (
              <Button
                color="error"
                size="small"
                onClick={() => setInvitationToCancel(data)}>
                Cancel
              </Button>
            ) : (
              <Box>
                <Button
                  color="success"
                  size="small"
                  onClick={() => handleRespondJoinRequest(data._id, WaitingListStatus.Accepted)}
                  startIcon={<DoneIcon/>}>
                  Accept
                </Button>
                <Button
                  color="error"
                  size="small"
                  onClick={() => handleRespondJoinRequest(data._id, WaitingListStatus.Rejected)}
                  startIcon={<CancelIcon/>}
                  sx={{ml: 2}}>
                  Reject
                </Button>
              </Box>
            )}
          </>
        )
      }
    ]
  }, [waitingList])

  const handleCancelInvitation = (invitation: typeof waitingList[0]) => {
    cancelInvitation({
      variables: {
        invitationId: invitation.id
      }
    }).then(() => {
      invitationsQuery.refetch()

      if (onUpdate) {
        onUpdate()
      }

      dispatch(notifyUser({
        message: customNotifications.INVITATION_CANCELED_SUCCESS
      }))
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleRespondJoinRequest = (
    id: string,
    decision: WaitingListStatus.Accepted | WaitingListStatus.Rejected
  ) => {
    respondJoinRequest({
      variables: {
        waitingListId: id,
        decision
      }
    }).then(() => {
      if (onUpdate) {
        onUpdate()
      }
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box position="relative">
      <Box mb={2}>
        <AddStudents
          classroom={classroom}
          onInvitationGenerated={invitationsQuery.refetch}
          onUpdateStudents={() => setStudentTableKey(studentTableKey + 1)}
        />
      </Box>
      {waitingList?.length > 0 && (
        <Box mt={1} mb={2}>
          <Paper variant="outlined" sx={{p: 2}}>
            <Typography color="textPrimary" variant="h6">
              Waiting List
            </Typography>
            <Box mt={2}>
              <DataTable
                schema={tableSchema}
                data={waitingList}
                loading={invitationsQuery.loading}
                error={!!invitationsQuery.error}
                search={{
                  label: "Search Students",
                  disabled: true
                }}
              />
              <ConfirmationAlert
                isOpen={!!invitationToCancel}
                setOpen={() => setInvitationToCancel(null)}
                handleConfirm={() => handleCancelInvitation(invitationToCancel)}
                handleCancel={() => setInvitationToCancel(null)}
                dialogTitle="Are you sure you want to cancel invitation?"
                cancelButton="No"
                confirmButton={{color: "error", text: "Yes"}}
              />
            </Box>
          </Paper>
        </Box>
      )}
      <Box mb={4}>
        <Paper variant="outlined" sx={{p: 2}}>
          <Typography color="textPrimary" variant="h6" mb={2}>
            Enrolled Students
          </Typography>
          <Fragment key={studentTableKey}>
            <StudentsTable classroomId={classroom._id} type="classroomStudents"/>
          </Fragment>
        </Paper>
      </Box>
    </Box>
  )
}
