import {useLazyQuery, useMutation} from "@apollo/client"
import {
  Box,
  Paper,
  TextField,
  Typography
} from "@mui/material"
import JOIN_CLASSROOM from "api/apollo/mutations/JOIN_CLASSROOM"
import LEAVE_CLASSROOM from "api/apollo/mutations/LEAVE_CLASSROOM"
import GET_MY_CLASSROOMS from "api/apollo/queries/GET_MY_CLASSROOMS"
import ConfirmationAlert from "components/ConfirmationAlert"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableQueryUpdate,
  DataTableSchema
} from "components/DataTable/types.t"
import Dialog from "components/Dialog"
import {useFormik} from "formik"
import {
  Classroom,
  GetMyClassroomsQuery,
  GetMyClassroomsQueryVariables,
  JoinClassroomByCodeMutation,
  JoinClassroomByCodeMutationVariables,
  LeaveClassroomMutation,
  LeaveClassroomMutationVariables
} from "generated/graphql"
import Plus from "assets/icons/Plus"
import React, {useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import {QueryDataType} from "types/typeUtils"

interface Props {
  onUpdate: () => void
}

export default function Classrooms({
  onUpdate
}: Props) {
  const dispatch = useDispatch()

  const [classroomsData, setClassroomsData] = useState<QueryDataType<DeepPartial<Classroom>>>(null)
  const [showAddClassroomPopUp, setShowAddClassroomPopUp] = useState(false)
  const [classroomToUnenroll, setClassroomToUnenroll] = useState<Classroom | null>(null)

  const [classroomsQueryFetch, classroomsQuery] = useLazyQuery<
    GetMyClassroomsQuery,
    GetMyClassroomsQueryVariables
  >(GET_MY_CLASSROOMS)

  const [joinClassroom] = useMutation<
    JoinClassroomByCodeMutation,
    JoinClassroomByCodeMutationVariables
  >(JOIN_CLASSROOM)

  const [leaveClassroom] = useMutation<
    LeaveClassroomMutation,
    LeaveClassroomMutationVariables
  >(LEAVE_CLASSROOM)

  const classroomsError = useMemo(() => {
    return !classroomsData && (classroomsQuery.error || null)
  }, [classroomsData, classroomsQuery.error])

  const formik = useFormik({
    initialValues: {
      code: ""
    },
    onSubmit: async (values) => {
      handleJoin(values.code)
      formik.resetForm()
      setShowAddClassroomPopUp(false)
    }
  })

  const tableSchema: DataTableSchema<Classroom> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "School",
        fieldName: "school.name"
      },
      {
        type: "text",
        headerText: "Classroom",
        fieldName: "name"
      },
      {
        type: "custom",
        headerText: "Teachers",
        content: data => {
          return String(data.teachers.length)
        }
      }
    ]
  }, [])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "joinClassroom",
        label: "Join Classroom",
        icon: <Plus/>,
        variant: "outlined",
        color: "success",
        onClick: () => setShowAddClassroomPopUp(true)
      }
    ]
  }, [])

  const handleQuery: DataTableQueryUpdate = (state) => {
    const {itemsPerPage, page, sort, searchQuery} = state

    classroomsQueryFetch({
      fetchPolicy: "network-only",
      variables: {
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order,
        search: searchQuery
      }
    }).then(res => {
      setClassroomsData(res.data?.getMyClassrooms || null)
    })
  }

  const handleUpdate = () => {
    onUpdate()
  }

  const handleJoin = (joinCode: string) => {
    joinClassroom({
      variables: {
        joinCode
      }
    }).then(() => {
      handleUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const handleUnenroll = (id: string) => {
    leaveClassroom({
      variables: {
        classroomId: id
      }
    }).then(() => {
      handleUpdate()
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box>
      <Typography color="textPrimary" variant="h6" mb={2}>
        Classrooms
      </Typography>
      <Paper variant="outlined" sx={{p: 2}}>
        <DataTable
          schema={tableSchema}
          data={classroomsData?.items}
          loading={classroomsQuery.loading}
          error={!!classroomsError}
          itemsTotalCount={classroomsData?.total}
          onQueryUpdate={handleQuery}
          search="Search Classrooms"
          emptyDataMessage="No classrooms found"
          actionButtons={tableActionButtons}
          lastPage={!classroomsData?.hasMore}
        />
        <Dialog
          open={showAddClassroomPopUp}
          maxWidth="md"
          title="Add New Classroom"
          onClose={() => setShowAddClassroomPopUp(false)}
          actions={[{
            label: "Cancel",
            onClick: () => setShowAddClassroomPopUp(false)
          }, {
            label: "Join",
            variant: "contained",
            onClick: () => formik.handleSubmit()
          }]}>
          <Box
            display="flex"
            justifyItems="center"
            alignItems="center"
            pt={2}
            width="30vw">
            <TextField
              id="code"
              size="small"
              fullWidth
              autoFocus
              placeholder="Join Code"
              onChange={formik.handleChange}
              value={formik.values.code}
            />
          </Box>
        </Dialog>
        <ConfirmationAlert
          isOpen={!!classroomToUnenroll}
          setOpen={() => setClassroomToUnenroll(null)}
          handleConfirm={() => handleUnenroll(classroomToUnenroll._id)}
          handleCancel={() => setClassroomToUnenroll(null)}
          dialogTitle={`Are you sure you want to leave ${classroomToUnenroll?.name}?`}
          cancelButton="No"
          confirmButton={{color: "error", text: "Yes"}}
        />
      </Paper>
    </Box>
  )
}
