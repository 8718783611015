import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {useMutation} from "@apollo/client"
import {Box, Button, Grid, IconButton, TextField, Typography} from "@mui/material"
import CREATE_CLASSROOM_INVITATION from "api/apollo/mutations/CREATE_CLASSROOM_INVITATION"
import {Formik} from "formik"
import {
  Classroom,
  CreateClassroomInvitationMutation,
  CreateClassroomInvitationMutationVariables
} from "generated/graphql"
import React, {useMemo} from "react"
import {useDispatch} from "store"
import {customNotifications} from "store/slices/notifier/notificationObject"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  classroom: DeepPartial<Classroom>
  backHandler?: () => void
  onFinish: () => void
  onClose: (email?: string) => void
}

export default function InviteForm({
  classroom,
  backHandler,
  onFinish,
  onClose
}: Props) {
  const dispatch = useDispatch()

  const formInitialValues = useMemo(() => {
    return {
      email: ""
    }
  }, [])

  const [createClassroomInvitation, invitationMutation] = useMutation<
    CreateClassroomInvitationMutation,
    CreateClassroomInvitationMutationVariables
  >(CREATE_CLASSROOM_INVITATION)

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    createClassroomInvitation({
      variables: {
        createClassroomInvitationInput: {
          classroomId: classroom._id,
          userEmail: data.email
        }
      }
    }).then(() => {
      dispatch(notifyUser({
        message: customNotifications.STUDENT_INVITED_SUCCESS
      }))
      onFinish()
    }).catch((err) => {
      if (err.message.includes("No document found for query")) {
        onClose(data.email)
      } else {
        dispatch(handleError(err))
      }
    })
  }

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255).required("Email is required")
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit}>
          <Box pt={3} pb={2} px={2}>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  {!!backHandler && (
                    <IconButton color="secondary" onClick={backHandler}>
                      <ArrowBackIcon/>
                    </IconButton>
                  )}
                </Box>
                <Box px={2}>
                  <Typography variant="h5" textAlign="center">
                    Invite Student to your classroom
                  </Typography>
                </Box>
                <Box width={!!backHandler ? 40 : 0}/>
              </Box>
            </Box>
            <Box>
              <Box px={2} py={4}>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    value={values["email"]}
                    error={!!(touched["email"] && errors["email"])}
                    helperText={touched["email"] && errors["email"]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Box>
              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button
                  disabled={invitationMutation.loading}
                  onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={invitationMutation.loading}>
                  Invite
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
