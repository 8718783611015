import {gql} from "@apollo/client"

export default gql`
  mutation AssignPracticeTest(
    $examCode: String!
    $studentIds: [GraphQLObjectId!]!
    $licenseId: GraphQLObjectId!
  ) {
    assignPracticeTest(
      examCode: $examCode
      studentIds: $studentIds
      licenseId: $licenseId
    ) {
      _id
    }
  }
`
