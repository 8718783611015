import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import {Box, Button, Typography} from "@mui/material"
import AddMultipleStudentsForm from "components/AddStudentsForm/AddMultipleStudentsForm"
import AddSingleStudentForm from "components/AddStudentsForm/AddSingleStudentForm"
import CustomModal from "components/CustomModal"
import {Classroom} from "generated/graphql"
import InviteForm from "./components/InviteForm"
import React, {useState} from "react"
import {useDispatch, useSelector} from "store"
import {fetchSchoolStudentsThunk, fetchStudentsForClassThunk} from "store/slices/schoolSlice/schoolSlice"

type AddStudentModal = "OPTIONS" | "INVITE" | "ADD_SINGLE" | "ADD_MULTIPLE"

interface Props {
  classroom: DeepPartial<Classroom>
  onInvitationGenerated?: () => void
  onUpdateStudents?: () => void
}

export default function AddStudents({
  classroom,
  onInvitationGenerated,
  onUpdateStudents
}: Props) {
  const dispatch = useDispatch()

  const schoolId = useSelector(store => store.schoolSlice.currentSchool)

  const [addStudentsModal, setAddStudentsModal] = useState<AddStudentModal | null>(null)
  const [userEmail, setUserEmail] = useState<string | null>(null)

  const handleStudentsAdded = () => {
    setAddStudentsModal(null)
    onUpdateStudents()

    if (!!classroom) {
      dispatch(fetchSchoolStudentsThunk(schoolId))
      dispatch(fetchStudentsForClassThunk({classroomId: classroom._id}))
    }
  }

  const handleClose = () => {
    setAddStudentsModal(null)
    setUserEmail(null)
  }

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setAddStudentsModal("OPTIONS")}
        startIcon={<PersonAddAlt1Icon fontSize="medium"/>}
        sx={{px: 2.5, py: 1.5}}>
        Add Students
      </Button>
      <CustomModal open={addStudentsModal === "OPTIONS"} onClose={() => setAddStudentsModal(null)}>
        <Box px={4} pt={2}>
          <Typography variant="h5" textAlign="center">
            Add New Students to your Classroom
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} p={4}>
          <Button variant="outlined" fullWidth onClick={() => setAddStudentsModal("INVITE")}>
            Invite Student to your classroom
          </Button>
          <Button variant="outlined" fullWidth onClick={() => setAddStudentsModal("ADD_SINGLE")}>
            Create New Student Account
          </Button>
          <Button variant="outlined" fullWidth onClick={() => setAddStudentsModal("ADD_MULTIPLE")}>
            Create New Student Accounts via bulk upload
          </Button>
        </Box>
      </CustomModal>
      <CustomModal open={addStudentsModal === "INVITE"} onClose={() => setAddStudentsModal(null)}>
        <InviteForm
          classroom={classroom}
          backHandler={() => setAddStudentsModal("OPTIONS")}
          onClose={(value) => {
            if (value) {
              setUserEmail(value)
              setAddStudentsModal("ADD_SINGLE")
            } else {
              handleClose()
            }
          }}
          onFinish={() => {
            if (onInvitationGenerated) {
              onInvitationGenerated()
            }
            setAddStudentsModal(null)
          }}
        />
      </CustomModal>
      <CustomModal open={addStudentsModal === "ADD_SINGLE"} onClose={handleClose}>
        <AddSingleStudentForm
          userEmail={userEmail}
          classroom={classroom}
          onAfterSubmit={() => handleStudentsAdded()}
          backHandler={() => setAddStudentsModal("OPTIONS")}
        />
      </CustomModal>
      <CustomModal open={addStudentsModal === "ADD_MULTIPLE"} onClose={handleClose}>
        <AddMultipleStudentsForm
          classroom={classroom}
          onAfterSubmit={() => handleStudentsAdded()}
          backHandler={() => setAddStudentsModal("OPTIONS")}
        />
      </CustomModal>
    </>
  )
}
