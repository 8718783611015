import {Box, Divider, Link, Typography} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import {useTheme} from "@mui/material"
import {Link as RouterLink} from "react-router-dom"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined"
import KPFullLogo from "assets/icons/KPFullLogo"
import {kpInfoEmail, kpPhoneNumber} from "consts"
import React from "react"

const textStyles = {
  fontWeight: {xs: 400, md: 500},
  fontSize: {xs: "14px", md: "16px"}
}

export default function Footer() {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down(760))

  return (
    <Box
      sx={{
        py: 4,
        px: {xs: 2, md: 4},
        bgcolor: "primary.dark",
        display: "flex",
        justifyContent: isMobile ? "center" : "space-between",
        textAlign: "center",
        flexDirection: isMobile ? "column" : "row",
        rowGap: 4
      }}>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        alignItems={isMobile ? "center" : undefined}
        order={isMobile ? 2 : 1}>
        <Box display="flex" alignItems="center" gap={1.5}>
          <PhoneOutlinedIcon sx={{color: "white.main"}}/>
          <a href={`tel:${kpPhoneNumber.replace(/[\s()-]/g, "")}`} style={{textDecoration: "none"}}>
            <Typography color="white.main" sx={textStyles}>
              {kpPhoneNumber}
            </Typography>
          </a>
        </Box>
        <Box display="flex" alignItems="center" gap={1.5}>
          <AlternateEmailIcon sx={{color: "white.main"}}/>
          <a href={`mailto:${kpInfoEmail}`} style={{textDecoration: "none"}}>
            <Typography color="white.main" sx={textStyles}>
              {kpInfoEmail}
            </Typography>
          </a>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" order={isMobile ? 1 : 2}>
        <Box sx={{width: "100%", height: {xs: "24px", md:"32px"}}}>
          <KPFullLogo variant="secondary"/>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1.5} alignItems={isMobile ? "center" : undefined} order={3}>
        <Box display="flex" alignItems="center" justifyContent="right">
          <Link to="/privacy-policy" component={RouterLink} sx={{textDecoration: "underline"}} color="white.main">
            <Typography color="white.main" sx={textStyles}>
              Privacy Policy
            </Typography>
          </Link>
          <Divider orientation="vertical" flexItem color="white" sx={{mx: 2, my: "6px"}}/>
          <Link to="/terms-and-conditions" component={RouterLink} sx={{textDecoration: "underline"}} color="white.main">
            <Typography color="white.main" sx={textStyles}>
              Terms of Use
            </Typography>
          </Link>
        </Box>
        <Typography color="white.main" sx={textStyles}>
          © {new Date().getFullYear()} Knowledge Pillars. All rights reserved
        </Typography>
      </Box>
    </Box>
  )
}
