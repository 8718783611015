import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {SchoolService} from "services/school.service"
import {customErrorCase, customPendingCase, errorCase, pendingCase} from "store/storeHelpers"
import {IClassroomItem, ILicenseItem, ISchoolItem, IStudentItem, ITeacherItem, ITestingGroupItem} from "types/common"
import {IFetchClassroomStudentsInput} from "types/services/school.service.t"
import CustomErrorClass from "../notifier/customErrorClass"
import {customErrors} from "../notifier/errorObject"
import {customNotifications} from "../notifier/notificationObject"
import {handleError, notifyUser} from "../notifier/notifier"

interface ISchoolSliceInitialState {
  loading: boolean
  hasErrors: boolean
  schoolInfoLoading: boolean
  logo: string
  name: string
  districtId?: string
  districtName?: string
  districtLogo?: string
  errorMessage: string
  allSchools: Array<ISchoolItem>
  currentSchool: string
  testingGroups: Array<ITestingGroupItem>
  currentTestingGroup: ITestingGroupItem
  students: Array<IStudentItem>
  currentStudent: IStudentItem
  teachers: Array<ITeacherItem>
  staff: Array<ITeacherItem>
  licenses: Array<ILicenseItem>
  licensesByExamCode: Array<ILicenseItem>
  availableClassrooms: Array<IClassroomItem>
  selectedClassStudents: Array<IStudentItem>
}

const initialState: ISchoolSliceInitialState = {
  loading: false,
  hasErrors: false,
  schoolInfoLoading: false,
  logo: null,
  name: null,
  districtId: null,
  districtName: null,
  districtLogo: null,
  errorMessage: "",
  allSchools: [],
  currentSchool: null,
  testingGroups: null,
  currentTestingGroup: null,
  students: null,
  currentStudent: null,
  teachers: null,
  staff: null,
  licenses: null,
  licensesByExamCode: null,
  availableClassrooms: [],
  selectedClassStudents: []
}

export const getSchoolInfoThunk = createAsyncThunk(
  "schoolSlice/fetchSchoolInfoThunk",
  async (schoolId: string, thunkAPI) => {
    try {
      return await SchoolService.fetchSchoolInfo(schoolId)
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const fetchStudentsForClassThunk = createAsyncThunk(
  "schoolSlice/fetchStudentsForClassThunk",
  async (input: IFetchClassroomStudentsInput, thunkAPI) => {
    try {
      return await SchoolService.fetchClassroomStudents(input)
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const fetchTestingGroupsThunk = createAsyncThunk(
  "schoolSlice/fetchTestingGroupsThunk",
  async ({schoolId, archived}: {schoolId: string, archived?: boolean}, thunkAPI) => {
    try {
      const showArchived = archived || false

      const res = await SchoolService.fetchTestingGroups({
        schoolId,
        showArchived
      })

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const fetchSchoolStaffThunk = createAsyncThunk(
  "schoolSlice/fetchSchoolStaffThunkThunk",
  async (schoolId: string, thunkAPI) => {
    try {
      return await SchoolService.fetchSchoolStaff(schoolId)
    } catch (err) {
      thunkAPI.dispatch(
        handleError(new CustomErrorClass(customErrors.CAN_NOT_FETCH_TEACHER))
      )
      throw err?.data?.error || err
    }
  }
)

export const fetchLicensesThunk = createAsyncThunk(
  "schoolSlice/fetchLicensesThunk",
  async (schoolId: string, thunkAPI) => {
    try {
      return await SchoolService.fetchLicenses(schoolId)
    } catch (err) {
      thunkAPI.dispatch(
        handleError(new CustomErrorClass(customErrors.CAN_NOT_FETCH_LICENSES))
      )
      throw err?.data?.error || err
    }
  }
)

export const fetchSchoolStudentsThunk = createAsyncThunk(
  "schoolSlice/fetchSchoolStudentsThunk",
  async (schoolId: string, thunkAPI) => {
    try {
      const res = await SchoolService.fetchSchoolStudents(schoolId)
      return res.data
    } catch (err) {
      thunkAPI.dispatch(
        handleError(new CustomErrorClass(customErrors.CAN_NOT_FETCH_STUDENTS))
      )
      throw err?.data?.error || err
    }
  }
)

export const fetchTestingGroupByIdThunk = createAsyncThunk(
  "schoolSlice/fetchTestingGroupByIdThunk",
  async (groupId: string, thunkAPI) => {
    try {
      return await SchoolService.fetchTestingGroupById(groupId)
    } catch (err) {
      thunkAPI.dispatch(
        handleError(
          new CustomErrorClass(customErrors.CAN_NOT_FETCH_TESTING_GROUP)
        )
      )
      throw err?.data?.error || err
    }
  }
)

export const deleteTestingGroupThunk = createAsyncThunk(
  "schoolSlice/deleteTestingGroup",
  async (groupId: string, thunkAPI) => {
    try {
      const res = await SchoolService.deleteTestingGroup(groupId)
      thunkAPI.dispatch(removeGroupById(groupId))
      thunkAPI.dispatch(notifyUser({message: "GROUP_DELETE_SUCCESS"}))
      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(customErrors.CAN_NOT_DELETE_GROUP))
      throw err?.data?.error || err
    }
  }
)

export const deleteStudentThunk = createAsyncThunk(
  "schoolSlice/deleteStudentThunk",
  async (studentId: string, thunkAPI) => {
    try {
      const res = await SchoolService.deleteStudent(studentId)
      thunkAPI.dispatch(removeStudentById(studentId))
      thunkAPI.dispatch(
        notifyUser({message: customNotifications.STUDENT_DELETE_SUCCESS})
      )
      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(customErrors.CAN_NOT_DELETE_STUDENT))
      throw err?.data?.error || err
    }
  }
)

export const createTestingGroupThunk = createAsyncThunk(
  "schoolSlice/createTestingGroupThunk",
  async (testingGroup: any, thunkAPI) => {
    try {
      const res = await SchoolService.createTestingGroup(testingGroup)

      thunkAPI.dispatch(notifyUser({message: "GROUP_ADD_SUCCESS"}))

      return res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))

      throw err?.data?.error || err
    }
  }
)

export const updateStudentPassThunk = createAsyncThunk(
  "schoolSlice/updateStudentPassThunk",
  async (params: { password: string, studentId: string }, thunkAPI) => {
    try {
      const res = await SchoolService.updateStudentPassword(params)

      thunkAPI.dispatch(notifyUser({message: "PASSWORD_UPDATE_SUCCESS", variant: "success"}))

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const updateTestingGroupThunk = createAsyncThunk(
  "schoolSlice/updateTestingGroupThunk",
  async (group: any, thunkAPI) => {
    try {
      const res = await SchoolService.updateTestingGroup(group)

      thunkAPI.dispatch(notifyUser({
        message: "GROUP_UPDATE_SUCCESS",
        variant: "success"
      }))

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))

      throw err?.data?.error || err
    }
  }
)

export const updateTestingGroupStatusThunk = createAsyncThunk(
  "schoolSlice/updateTestingGroupStatusThunk",
  async (args: { id: string, status: string }, thunkAPI) => {
    try {
      const res = await SchoolService.updateTestingGroupStatus(args)

      // thunkAPI.dispatch(
      //   notifyUser({message: "GROUP_UPDATE_SUCCESS", variant: "success"})
      // )

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))

      throw err?.data?.error || err
    }
  }
)

export const updateTestingGroupArchiveThunk = createAsyncThunk(
  "schoolSlice/archiveTestingGroup",
  async (
    {groupId, archive}: {groupId: string, archive: boolean},
    thunkAPI
  ) => {
    try {
      const res = await SchoolService.updateTestingGroupArchive({
        groupId,
        archive
      })

      thunkAPI.dispatch(notifyUser({
        message: `GROUP_${archive ? "ARCHIVED" : "UNARCHIVED"}_SUCCESS`
      }))

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(customErrors.CAN_NOT_DELETE_GROUP))
      throw err?.data?.error || err
    }
  }
)

export const updateStudentInClassThunk = createAsyncThunk(
  "schoolSlice/updateStudentInClassThunk",
  async (args: {
    studentId: string
    classroomId: string
    action: "ADD" | "REMOVE"
  }, thunkAPI) => {
    try {
      const res = await SchoolService.updateStudentInClass(args)

      thunkAPI.dispatch(notifyUser({message: "STUDENT_UPDATE_SUCCESS", variant: "success"}))

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

export const updateTeacherInClassThunk = createAsyncThunk(
  "schoolSlice/updateTeacherInClassThunk",
  async (
    args: { teacherId: string, classroomId: string, action: "ADD" | "REMOVE" },
    thunkAPI
  ) => {
    try {
      const res = await SchoolService.updateTeacherInClass(args)

      thunkAPI.dispatch(notifyUser({message: "TEACHER_UPDATE_SUCCESS", variant: "success"}))

      return res?.data || res
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

const schoolSlice = createSlice({
  name: "schoolSlice",
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    setCurrentSchool(state, action) {
      state.currentSchool = action.payload
    },
    cleanSpecificSchoolStateField(state, action) {
      if (!action?.payload?.length) return

      action.payload.forEach((fieldName) => {
        state[fieldName] = null
      })
    },
    setCurrentTestingGroup(state, action) {
      state.currentTestingGroup = action.payload
    },
    removeGroupById(state, action) {
      if (!state.testingGroups?.length) return

      state.testingGroups = state.testingGroups.filter(
        (group) => group.id !== action.payload
      )
    },
    removeStudentById(state, action) {
      if (!state.students?.length) return

      state.students = state.students.filter(
        (student) => student.id !== action.payload
      )
    },
    removeClassroomById(state, action) {
      state.availableClassrooms = state.availableClassrooms.filter(
        (classItem) => classItem._id !== action.payload
      )
    },
    setCurrentStudent(state, {payload}) {
      state.currentStudent = payload
    },
    updateSchool(state, {payload}) {
      state.allSchools = state.allSchools.map(i => {
        if (i._id === payload.id) {
          return {
            ...i,
            ...payload
          }
        }
        return i
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteStudentThunk.pending, pendingCase)
      .addCase(fetchLicensesThunk.pending, pendingCase)
      .addCase(updateStudentPassThunk.pending, pendingCase)
      .addCase(fetchTestingGroupsThunk.pending, pendingCase)
      .addCase(deleteTestingGroupThunk.pending, pendingCase)
      .addCase(updateTestingGroupThunk.pending, pendingCase)
      .addCase(updateTestingGroupArchiveThunk.pending, pendingCase)
      .addCase(createTestingGroupThunk.pending, pendingCase)
      .addCase(fetchSchoolStudentsThunk.pending, pendingCase)
      .addCase(fetchSchoolStaffThunk.pending, pendingCase)
      .addCase(getSchoolInfoThunk.pending, (state) => customPendingCase(state, "schoolInfoLoading"))
      .addCase(fetchStudentsForClassThunk.pending, pendingCase)
      .addCase(updateTestingGroupStatusThunk.pending, pendingCase)
      .addCase(updateStudentInClassThunk.pending, errorCase)
      .addCase(fetchTestingGroupByIdThunk.pending, pendingCase)

    builder
      .addCase(fetchLicensesThunk.rejected, errorCase)
      .addCase(deleteStudentThunk.rejected, errorCase)
      .addCase(updateStudentPassThunk.rejected, errorCase)
      .addCase(fetchTestingGroupsThunk.rejected, errorCase)
      .addCase(deleteTestingGroupThunk.rejected, errorCase)
      .addCase(updateTestingGroupThunk.rejected, errorCase)
      .addCase(updateTestingGroupArchiveThunk.rejected, errorCase)
      .addCase(updateStudentInClassThunk.rejected, errorCase)
      .addCase(createTestingGroupThunk.rejected, errorCase)
      .addCase(fetchSchoolStaffThunk.rejected, errorCase)
      .addCase(fetchSchoolStudentsThunk.rejected, errorCase)
      .addCase(getSchoolInfoThunk.rejected, (state, action) => customErrorCase(state, action, "schoolInfoLoading"))
      .addCase(fetchStudentsForClassThunk.rejected, errorCase)
      .addCase(updateTestingGroupStatusThunk.rejected, errorCase)
      .addCase(fetchTestingGroupByIdThunk.rejected, errorCase)

    builder
      .addCase(getSchoolInfoThunk.fulfilled, (state, {payload}) => {
        state.loading = false
        state.schoolInfoLoading = false

        const {name, logo, districtId, district, _id} = payload

        state.currentSchool = _id
        state.name = name
        state.logo = logo
        state.districtId = districtId
        state.districtName = district?.name
        state.districtLogo = district?.logo
      })
      .addCase(fetchStudentsForClassThunk.fulfilled, (state, action) => {
        state.loading = false
        state.selectedClassStudents = action.payload
      })
      .addCase(fetchTestingGroupsThunk.fulfilled, (state, action) => {
        state.loading = false
        state.testingGroups = action.payload?.length ? action.payload : null
      })
      .addCase(fetchSchoolStaffThunk.fulfilled, (state, action) => {
        state.loading = false
        state.staff = action.payload?.length ? action.payload : null
      })
      .addCase(fetchSchoolStudentsThunk.fulfilled, (state, action) => {
        state.students = (action.payload || []).map((st) => {
          let fname = st.firstName || ""
          if (st.lastName) fname += ` ${st.lastName}`
          return {
            ...st,
            fullName: fname
          }
        })
        state.loading = false
      })
      .addCase(fetchTestingGroupByIdThunk.fulfilled, (state, action) => {
        state.loading = false
        state.currentTestingGroup = action.payload
      })
      .addCase(fetchLicensesThunk.fulfilled, (state, action) => {
        state.loading = false
        state.licenses = action.payload
      })

      .addCase(deleteTestingGroupThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(deleteStudentThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateTestingGroupThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateTestingGroupArchiveThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateStudentInClassThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateStudentPassThunk.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(updateTestingGroupStatusThunk.fulfilled, (state, action) => {
        state.loading = false
        state.currentTestingGroup = action.payload
      })
      .addCase(createTestingGroupThunk.fulfilled, (state) => {
        state.loading = false
      })
  }
})

export const {
  updateSchool,
  cleanSpecificSchoolStateField,
  setCurrentTestingGroup,
  removeGroupById,
  removeStudentById
} = schoolSlice.actions

export default schoolSlice.reducer
