import {signInWithRedirect} from "aws-amplify/auth"
import {Link as RouterLink} from "react-router-dom"
import {Helmet} from "react-helmet-async"
import {Box, Link, Grid, Typography, Divider, IconButton, Tooltip} from "@mui/material"
import LoginAmplify from "./components/LoginAmplify"
import useAuth from "hooks/useAuth"
import {useTheme} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Google from "assets/icons/Google"
import MasteryCoding from "assets/pictures/masteryCoding.jpeg"
import Clever from "assets/pictures/clever.png"
import ClassLink from "assets/pictures/classlink.png"
import LoginPic from "assets/pictures/loginPic.png"
import BottomCurvedLine from "assets/icons/BottomCurvedLine"
import KPLogo from "assets/icons/KPLogo"
import {useSearchParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import LoginBottomCurvedLineMobile from "assets/icons/LoginBottomCurvedLineMobile"

export default function Login() {
  const {platform} = useAuth() as any
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [searchParams] = useSearchParams()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const providerParam = searchParams.get("provider")

  useEffect(() => {
    if (!providerParam) return

    setIsRedirecting(true)
    signInWithRedirect({provider: providerParam === "Google" ? "Google" : {custom: providerParam}})
  }, [searchParams])

  if (isRedirecting) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        flexDirection="column"
        gap={2}>
        <Typography variant="h4">Redirecting to login...</Typography>
        <Typography color="textSecondary">
          You will be redirected to {providerParam} login page
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Helmet>
        <title>Login | KP Platform</title>
      </Helmet>
      <Grid
        container
        spacing={{xs: 5, md: 4}}
        sx={{
          height: "100%",
          px: {xs: 2, md: 4},
          pt: {xs: 2, md: 4},
          pb: 6
        }}
      >
        <Grid
          item
          sx={{width: {xs: "100%", md: "50%"}, pl: 0}}
        >
          <Box
            position="relative"
            sx={{overflow: "hidden", borderRadius: "20px"}}
            height="100%">
            <Typography
              className="MontserratFont"
              color="white.main"
              sx={{
                whiteSpace: "nowrap",
                textAlign: "center",
                position: "absolute",
                top: {xs: "-2px", md: "4.1vw"},
                left: "50%",
                transform: "translateX(-50%)",
                fontWeight: 900,
                fontSize: {xs: "8vw", md: "5vw"},
                lineHeight: "normal"
              }}>
              Learn and <br/> Practice
            </Typography>
            <img
              src={LoginPic}
              alt="Learn and Practice"
              className="object-cover"
              style={{aspectRatio: isMobile ? "1.715" : undefined}}
            />
            <BottomCurvedLine
              sx={{
                position: "absolute",
                width: "100%",
                height: "auto",
                bottom: 0,
                display: {xs: "none", md: "block"}
              }}
            />
            <LoginBottomCurvedLineMobile
              sx={{
                position: "absolute",
                width: "32%",
                height: "auto",
                bottom: 0,
                right: 0,
                display: {xs: "block", md: "none"}
              }}
            />
            <LoginBottomCurvedLineMobile
              sx={{
                position: "absolute",
                width: "32%",
                height: "auto",
                bottom: 0,
                left: 0,
                transform: "scaleX(-1)",
                display: {xs: "block", md: "none"}
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: {xs: "auto", md: "765px"}
          }}>
          <Box width="100%" maxWidth={460} margin="auto">
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="primary" variant="h2">
                  Log in
                </Typography>
                <KPLogo sx={{width: "2.45rem", height: "auto"}}/>
              </Box>
              <Typography mt={1.5} variant="h6">
                Log in to KnowledgePillars platform.
              </Typography>
            </Box>
            <Box flexGrow={1} mt={{xs: 3, md: 5}}>
              {platform === "Amplify" && <LoginAmplify/>}
            </Box>
            <Box mt={3} textAlign="center">
              <Typography>
                Don't have an account? {" "}
                <Link
                  component={RouterLink}
                  to="/authentication/register"
                  variant="body1"
                  color="secondary">
                  Sign Up
                </Link>
              </Typography>
            </Box>
            <Box mt={{xs: 3, md: 5}}>
              <Divider>
                <Typography color="textSecondary">
                  or sign in with
                </Typography>
              </Divider>
            </Box>
            <Box mt={{xs: 4, md: 5}} display="flex" justifyContent="center" alignItems="center" gap={2}>
              <Tooltip title="Google">
                <IconButton
                  size="large"
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() => signInWithRedirect({provider: "Google"})}>
                  <Google/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Clever">
                <IconButton
                  size="large"
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() => signInWithRedirect({provider: {custom: "Clever"}})}>
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "100%",
                      overflow: "hidden"
                    }}>
                    <img src={Clever} alt="clever" className="object-contain" fetchpriority="high"/>
                  </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="ClassLink">
                <IconButton
                  size="large"
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() => signInWithRedirect({provider: {custom: "ClassLink"}})}>
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "100%",
                      overflow: "hidden"
                    }}>
                    <img src={ClassLink} alt="classlink" className="object-contain" fetchpriority="high"/>
                  </Box>
                </IconButton>
              </Tooltip>
              <Tooltip title="Mastery Coding">
                <IconButton
                  size="large"
                  color="primary"
                  sx={{backgroundColor: theme.palette.kpNeutralColors.light}}
                  onClick={() => signInWithRedirect({provider: {custom: "MasteryCoding"}})}>
                  <Box
                    sx={{
                      width: "1em",
                      height: "1em",
                      borderRadius: "100%",
                      overflow: "hidden"
                    }}>
                    <img src={MasteryCoding} alt="mastery coding" className="object-contain" fetchpriority="high"/>
                  </Box>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
