import {Box, Divider, Button, Typography} from "@mui/material"
import React, {useState} from "react"
import {ButtonOwnProps} from "@mui/material/Button"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

type ConfirmationAlertButtonColor = "secondary" | "success" | "inherit" | "warning" | "error" | "primary" | "info"

export interface ConfirmationAlertContentProps {
  disableBackdrop?: boolean
  dialogTitle: string
  dialogContent?: React.ReactNode
  dialogContentDynamic?: boolean
  dialogContentText?: string
  confirmButton?: string | {
    variant?: ButtonOwnProps["variant"]
    color: ConfirmationAlertButtonColor
    text: string
  }
  cancelButton?: string | {
    variant?: ButtonOwnProps["variant"]
    color: ConfirmationAlertButtonColor
    text: string
  }
  handleConfirm: () => void
  handleCancel: () => void
}

export default function Content({
  dialogTitle,
  dialogContent,
  dialogContentDynamic,
  dialogContentText,
  confirmButton,
  cancelButton,
  handleConfirm,
  handleCancel
}: ConfirmationAlertContentProps) {
  const [info] = useState({
    title: dialogTitle,
    content: dialogContent,
    contentText: dialogContentText
  })

  return (
    <>
      <DialogTitle
        id="alert-dialog-title"
        variant="h5"
        className="PoppinsFont"
        sx={{p: 4, pb: 2}}>
        {info.title}
      </DialogTitle>
      {((dialogContentDynamic && dialogContent) || !!info.content || !!info.contentText) && (
        <DialogContent sx={{pt: 4, px: 4, pb: 1}}>
          {!!info.contentText && (
            <DialogContentText variant="body1" sx={{whiteSpace: "pre-wrap"}}>
              {info.contentText}
            </DialogContentText>
          )}
          {dialogContentDynamic ? dialogContent : info.content}
        </DialogContent>
      )}
      <Box minWidth="30vw" px={4}>
        <Divider sx={{my: 2}}/>
      </Box>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          pb: 4,
          px: 4
        }}>
        {cancelButton && (
          <Button
            fullWidth={!confirmButton}
            onClick={handleCancel}
            variant={typeof cancelButton === "string" ? undefined : cancelButton?.variant || undefined}
            color={typeof cancelButton === "string" ? "primary" : cancelButton?.color || "primary"}>
            {(typeof cancelButton === "string" ? cancelButton : cancelButton?.text) || "Disagree"}
          </Button>
        )}
        {confirmButton && (
          <Button
            autoFocus
            fullWidth={!cancelButton}
            onClick={handleConfirm}
            variant={typeof confirmButton === "string" ? undefined : confirmButton?.variant || undefined}
            color={typeof confirmButton === "string" ? "primary" : confirmButton?.color || "primary"}>
            {(typeof confirmButton === "string" ? confirmButton : confirmButton?.text) || "Agree"}
          </Button>
        )}
      </DialogActions>
    </>
  )
}
