import {SvgIcon, SvgIconProps} from "@mui/material"

interface Props extends SvgIconProps {
  strokeColor?: string
}

export default function BottomCurvedLine({strokeColor = "#2092C0", ...props}: Props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 750 133"
      component="svg">
      <path
        d="M839.8 167.048C771.323 -22.176 562.28 7.59043 474.656 46.1951C414.482 72.706 344.279 114.13 347.695 188.54C351.529 272.073 496.18 325.64 525.21 209.976C554.241 94.3119 81.9998 -138.388 -247 188.54"
        stroke={strokeColor}
        strokeWidth="35"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  )
}
