import React, {useMemo} from "react"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import {Box, Breadcrumbs, Link, Typography} from "@mui/material"
import {Link as RouterLink} from "react-router-dom"

export interface CustomBreadcrumbsProps {
  title?: string
  breadcrumbs: Array<{
    text: string
    path?: string
  }>
}

export default React.memo(function CustomBreadcrumbs({
  breadcrumbs,
  title
}: CustomBreadcrumbsProps) {
  const crumbsToRender = useMemo(() => {
    if (!breadcrumbs || !breadcrumbs?.length) return null

    return breadcrumbs.map((i, num) => {
      if (!i.path) {
        return (
          <Typography
            key={`${i.text}-${num}`}
            color="textPrimary"
            variant="subtitle2">
            {i.text}
          </Typography>
        )
      }

      return (
        <Link
          key={`${i.text}-${num}`}
          color="text.lightBlue"
          component={RouterLink}
          to={i.path}
          variant="subtitle2">
          {i.text}
        </Link>
      )
    })
  }, [breadcrumbs])

  return (
    <Box>
      <Typography
        color="textPrimary"
        className="MontserratFont"
        fontWeight={900}
        variant="h4">
        {title}
      </Typography>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ChevronRightIcon fontSize="small" color="primary"/>
        }
        sx={{mt: 1}}>
        {crumbsToRender}
      </Breadcrumbs>
    </Box>
  )
})
