import {gql} from "@apollo/client"

export default gql`
  mutation AssignLab(
    $licenseId: GraphQLObjectId! 
    $studentIds: [GraphQLObjectId!]! 
    $override: Boolean! = false
  ) {
    assignLab(
      licenseId: $licenseId 
      studentIds: $studentIds
      override: $override
    ) {
      _id
    }
  }
`
