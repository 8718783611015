import React from "react"
import formatDate from "utils/formatDate"
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material"
import {makeStyles} from "@mui/styles"

type ExamsListCardItem = {
  _id: string
  title: string
  subtitle: string
  examCode: string
  expired: boolean
  expirationDate: string
}

interface Props {
  data: ExamsListCardItem
  onActivate: (examName: string) => void
  onStart: (_id: string, examCode: string) => void
}

const useStyles = makeStyles({
  logoImage: {
    width: "60%",
    height: "100%",
    aspectRatio: 1,
    objectFit: "contain"
  }
})

export default function ExamItem({
  data,
  onStart,
  onActivate
}: Props) {
  const s = useStyles()

  const handleStartOrActivateExamHandler = () => {
    return data.expired ? onActivate(data.subtitle) : onStart(data._id, data.examCode)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          height: "100%",
          bgcolor: (theme) => theme.palette.white.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: "20px",
          pt: 3,
          overflow: "hidden"
        }}
      >
        <Box px={3}>
          <Typography variant="body1" fontWeight={900}>
            {data.title}
          </Typography>
          <Typography variant="body1" fontWeight={900} mt="2px">
            {data.subtitle}
          </Typography>
        </Box>
        <Box>
          <Box
            px={3}
            mb={3}
            mt={1.5}
          >
            <Divider/>
            <Box
              my={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative"
              }}>
              <img
                src={`/examIcons/${data.examCode}.png`}
                alt={data.subtitle}
                className={s.logoImage}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto"
              }}
            >
              <Button
                size="large"
                variant="contained"
                fullWidth
                color={data.expired ? "secondary" : "primary"}
                onClick={handleStartOrActivateExamHandler}
              >
                {data.expired ? "Activate new key" : "START TEST"}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              bgcolor: (theme) => data.expired ? theme.palette.error.main : theme.palette.success.main,
              py: 1.25
            }}
          >
            <Typography variant="body1" color="white.main">
              {data.expired ? "Expired" : "Valid until"} {formatDate(data.expirationDate)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
