
interface Props {
  variant?: "primary" | "secondary"
  fullWidth?: boolean
}

const KPFullLogo = ({
  variant = "primary",
  fullWidth = true
}: Props) => {
  return (
    <svg width={fullWidth ? "100%" : "auto"} height="100%" viewBox="0 0 284 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        {variant === "primary" ? (
          <>
            <path
              d="M48.5956 11.3813V29.3022C48.5949 29.6968 48.5166 30.0874 48.365 30.4517C48.2135 30.816 47.9916 31.1469 47.7121 31.4255C47.4327 31.7041 47.1011 31.9249 46.7362 32.0753C46.3714 32.2257 45.9806 32.3029 45.5859 32.3022H34.6499C33.8558 32.2998 33.0951 31.9826 32.5344 31.4203C31.9738 30.8579 31.6589 30.0963 31.6589 29.3022V21.7255C31.7086 20.8219 31.9646 19.9417 32.4073 19.1524C32.8499 18.3631 33.4675 17.6856 34.2127 17.172L46.0511 9.94875C47.4466 9.09294 48.5956 9.7348 48.5956 11.3813Z"
              fill="#FFC42A"/>
            <path
              d="M40.1575 3.46052V32.6559C40.1575 35.2001 38.7201 36.228 36.9665 36.228H25.2955C23.5418 36.228 22.1045 35.0047 22.1045 32.4605V13.6094C22.1045 11.0652 23.1232 10.0187 24.6582 8.7954L37.1665 1.15354C39.0225 -0.0929697 40.1575 0.893077 40.1575 3.46052Z"
              fill="#2092C0"/>
            <path
              d="M29.305 3.32567V34.5768C29.3013 35.9598 28.7502 37.285 27.7723 38.2629C26.7943 39.2408 25.4689 39.7918 24.0858 39.7954H5.01413C3.62703 39.7979 2.29569 39.2496 1.31269 38.2711C0.329678 37.2925 -0.224575 35.9638 -0.228271 34.5768V21.3582C-0.228271 18.4838 1.77193 14.9117 4.22335 13.4187L24.8441 0.823346C27.2955 -0.669677 29.305 0.465206 29.305 3.32567Z"
              fill="#002855"/>
          </>
        ) : (
          <>
            <path
              d="M48.637 11.3801V29.301C48.6364 29.6956 48.558 30.0862 48.4063 30.4505C48.2546 30.8148 48.0325 31.1457 47.7528 31.4243C47.4731 31.7028 47.1412 31.9237 46.7761 32.0741C46.4109 32.2245 46.0197 32.3016 45.6248 32.301H34.6791C33.8843 32.2986 33.1229 31.9814 32.5617 31.4191C32.0006 30.8567 31.6855 30.0951 31.6855 29.301V21.7243C31.7352 20.8207 31.9914 19.9405 32.4345 19.1512C32.8775 18.3619 33.4957 17.6844 34.2415 17.1708L46.0903 9.94753C47.4871 9.09172 48.637 9.73358 48.637 11.3801Z"
              fill="white"/>
            <mask id="path-2-outside-1_0_1" maskUnits="userSpaceOnUse" x="18.1238" y="-3.38281" width="26" height="43"
                  fill="black">
              <rect fill="white" x="18.1238" y="-3.38281" width="26" height="43"/>
              <path
                d="M40.1928 3.46125V32.6566C40.1928 35.2008 38.7541 36.2287 36.9989 36.2287H25.3177C23.5625 36.2287 22.1238 35.0054 22.1238 32.4612V13.6101C22.1238 11.0659 23.1434 10.0194 24.6798 8.79613L37.1991 1.15427C39.0568 -0.0922372 40.1928 0.89381 40.1928 3.46125Z"/>
            </mask>
            <path
              d="M40.1928 3.46125V32.6566C40.1928 35.2008 38.7541 36.2287 36.9989 36.2287H25.3177C23.5625 36.2287 22.1238 35.0054 22.1238 32.4612V13.6101C22.1238 11.0659 23.1434 10.0194 24.6798 8.79613L37.1991 1.15427C39.0568 -0.0922372 40.1928 0.89381 40.1928 3.46125Z"
              fill="white"/>
            <path
              d="M40.1928 3.46125V32.6566C40.1928 35.2008 38.7541 36.2287 36.9989 36.2287H25.3177C23.5625 36.2287 22.1238 35.0054 22.1238 32.4612V13.6101C22.1238 11.0659 23.1434 10.0194 24.6798 8.79613L37.1991 1.15427C39.0568 -0.0922372 40.1928 0.89381 40.1928 3.46125Z"
              stroke="#002855" strokeWidth="6.25" mask="url(#path-2-outside-1_0_1)"/>
            <mask id="path-19-outside-2_0_1" maskUnits="userSpaceOnUse" x="-4.22949" y="-3.83594" width="37" height="47"
                  fill="black">
              <rect fill="white" x="-4.22949" y="-3.83594" width="37" height="47"/>
              <path
                d="M29.3298 3.32494V34.5761C29.3261 35.959 28.7746 37.2843 27.7957 38.2621C26.8169 39.24 25.4903 39.791 24.106 39.7947H5.01752C3.6292 39.7972 2.2967 39.2489 1.31282 38.2704C0.328949 37.2918 -0.225792 35.9631 -0.229492 34.5761V21.3575C-0.229492 18.4831 1.77248 14.911 4.22605 13.418L24.8649 0.822613C27.3185 -0.67041 29.3298 0.464473 29.3298 3.32494Z"/>
            </mask>
            <path
              d="M29.3298 3.32494V34.5761C29.3261 35.959 28.7746 37.2843 27.7957 38.2621C26.8169 39.24 25.4903 39.791 24.106 39.7947H5.01752C3.6292 39.7972 2.2967 39.2489 1.31282 38.2704C0.328949 37.2918 -0.225792 35.9631 -0.229492 34.5761V21.3575C-0.229492 18.4831 1.77248 14.911 4.22605 13.418L24.8649 0.822613C27.3185 -0.67041 29.3298 0.464473 29.3298 3.32494Z"
              fill="white"/>
            <path
              d="M29.3298 3.32494V34.5761C29.3261 35.959 28.7746 37.2843 27.7957 38.2621C26.8169 39.24 25.4903 39.791 24.106 39.7947H5.01752C3.6292 39.7972 2.2967 39.2489 1.31282 38.2704C0.328949 37.2918 -0.225792 35.9631 -0.229492 34.5761V21.3575C-0.229492 18.4831 1.77248 14.911 4.22605 13.418L24.8649 0.822613C27.3185 -0.67041 29.3298 0.464473 29.3298 3.32494Z"
              stroke="#002855" strokeWidth="6.25" mask="url(#path-19-outside-2_0_1)"/>
          </>
        )}
        <path
          d="M77.2172 29.7673H75.11L63.6856 19.2232V29.7115H62.0156V8.3999H63.6856V18.1394L74.2029 8.41386H76.2171V8.56734L65.3229 18.6046L77.2172 29.6232V29.7673Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M78.9379 14.6836V17.3627C80.1148 15.2929 82.2499 14.4371 84.385 14.4092C88.4692 14.4092 91.2834 16.8789 91.2834 21.0557V29.7673H89.7763V21.0836C89.7763 17.6696 87.6412 15.8417 84.3292 15.8696C81.1754 15.9022 78.9798 18.251 78.9798 21.358V29.7673H77.4634V14.6836H78.9379Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M93.4985 22.242C93.4985 17.4559 96.978 14.4187 101.276 14.4187C105.574 14.4187 109.044 17.4652 109.044 22.2513C109.044 27.0373 105.593 29.9582 101.276 29.9582C96.9594 29.9582 93.4985 27.028 93.4985 22.242ZM107.542 22.242C107.542 18.3396 104.728 15.8094 101.262 15.8094C97.7967 15.8094 94.9824 18.3396 94.9824 22.242C94.9824 26.1443 97.7967 28.4931 101.262 28.4931C104.728 28.4931 107.556 26.1443 107.556 22.242H107.542Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M126.646 29.7675H124.976L122.501 23.1814L120.64 17.3256L118.719 23.2094L116.249 29.7675H114.579L109.137 14.707H110.807L115.416 28.028L120.068 14.707H121.152L125.804 28.028L130.381 14.707H132.051L126.646 29.7675Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path d="M135.032 8.3999V29.7673H133.502V8.3999H135.032Z" fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M137.381 22.2141C137.358 21.1881 137.543 20.1681 137.924 19.2151C138.305 18.2622 138.874 17.3959 139.597 16.6681C140.321 15.9402 141.184 15.3659 142.135 14.9794C143.085 14.5929 144.104 14.4022 145.131 14.4187C149.429 14.4187 153.266 17.0094 152.68 22.8931H138.911C139.218 26.4327 141.972 28.4745 145.131 28.4745C147.14 28.4745 149.489 27.6838 150.638 26.1862L151.717 27.042C150.233 28.9629 147.573 29.9396 145.131 29.9396C140.846 29.9582 137.381 27.0001 137.381 22.2141ZM151.336 21.6048C151.303 17.8838 148.852 15.8141 145.131 15.8141C141.972 15.8141 139.251 17.9164 138.911 21.6094L151.336 21.6048Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M170.25 8.3999V29.7673H168.766V26.4185C168.117 27.5263 167.185 28.441 166.065 29.0681C164.944 29.6952 163.677 30.0121 162.393 29.9859C158.123 29.9859 154.751 27.1953 154.751 22.2139C154.751 17.2325 158.156 14.4185 162.445 14.4185C165.073 14.4185 167.561 15.5162 168.785 17.958V8.3999H170.25ZM156.235 22.2139C156.235 26.3999 159.054 28.5255 162.486 28.5255C166.073 28.5255 168.734 25.9301 168.734 22.1813C168.734 18.4325 166.012 15.8697 162.486 15.8697C159.086 15.8697 156.253 17.9766 156.253 22.2139H156.235Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M185.894 14.0745L186.884 14.9256L185.4 16.6326C186.484 17.7907 187.289 19.3163 187.289 21.7489C187.279 23.0965 186.887 24.4138 186.159 25.5475C185.43 26.6812 184.394 27.5849 183.172 28.1535C185.866 29.0838 187.359 30.9442 187.359 33.5489C187.359 37.6047 184.14 39.5582 180.088 39.5582C176.037 39.5582 172.846 37.3628 172.846 33.5489H174.362C174.362 36.628 177.023 38.0931 180.088 38.0931C183.154 38.0931 185.875 36.7535 185.875 33.5489C185.875 30.3442 182.935 29.0373 180.088 29.0373C175.665 29.0373 172.911 25.7814 172.911 21.7489C172.911 17.0559 176.125 14.4047 180.088 14.4047C181.638 14.4317 183.145 14.9172 184.419 15.8L185.894 14.0745ZM174.437 21.7535C174.437 24.8652 176.6 27.5489 180.098 27.5489C181.62 27.5233 183.072 26.9022 184.141 25.8188C185.211 24.7354 185.813 23.2759 185.819 21.7535C185.819 17.9442 183.251 15.9024 180.098 15.9024C176.944 15.9024 174.437 17.8233 174.437 21.7535Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M188.526 22.214C188.504 21.186 188.689 20.1641 189.071 19.2095C189.453 18.255 190.025 17.3876 190.751 16.6595C191.477 15.9313 192.343 15.3575 193.296 14.9724C194.25 14.5873 195.271 14.3989 196.299 14.4186C200.602 14.4186 204.435 17.0093 203.849 22.8931H190.08C190.392 26.4326 193.145 28.4744 196.299 28.4744C198.309 28.4744 200.662 27.6838 201.807 26.1861L202.891 27.0419C201.407 28.9628 198.746 29.9396 196.299 29.9396C191.992 29.9582 188.526 27 188.526 22.214ZM202.481 21.6047C202.449 17.8837 199.974 15.814 196.299 15.814C193.145 15.814 190.419 17.9163 190.08 21.6093L202.481 21.6047Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M216.301 23.7209H210.408V29.7675H206.496V9.30233C209.752 9.30233 213.008 9.26978 216.264 9.26978C226.409 9.26978 226.442 23.7209 216.301 23.7209ZM210.431 20.1907H216.301C221.306 20.1907 221.279 12.9442 216.301 12.9442H210.408L210.431 20.1907Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M229.196 11.2838C229.196 14.0745 224.93 14.0745 224.93 11.2838C224.93 8.49311 229.196 8.51171 229.196 11.2838ZM225.256 15.2885V29.7675H228.861V15.2885H225.256Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path d="M234.708 9.33032V29.7675H231.122V9.33032H234.708Z" fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path d="M240.457 9.33032V29.7675H236.871V9.33032H240.457Z" fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M254.203 15.3488H257.673V29.7674H254.263L254.086 27.665C253.258 29.3581 250.974 30.1767 249.346 30.2046C245.02 30.2325 241.82 27.6046 241.82 22.5534C241.82 17.586 245.165 14.9906 249.435 15.0185C251.388 15.0185 253.258 15.9255 254.086 17.3441L254.203 15.3488ZM245.434 22.5302C245.434 25.2743 247.36 26.9116 249.76 26.9116C255.449 26.9116 255.449 18.1767 249.76 18.1767C247.36 18.1767 245.434 19.786 245.434 22.5302Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M263.064 15.3489L263.33 17.014C264.455 15.2326 265.967 14.9721 267.45 14.9721C268.834 14.9546 270.175 15.4519 271.213 16.3675L269.581 19.4605C268.868 18.8209 267.933 18.4868 266.976 18.5303C265.078 18.5303 263.33 19.5256 263.33 22.1814V29.7675H259.716V15.3489H263.064Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
        <path
          d="M281.108 19.1721C280.621 18.7293 280.051 18.3877 279.43 18.1674C278.81 17.947 278.152 17.8521 277.494 17.8883C275.778 17.8883 274.829 18.4139 274.829 19.3162C274.829 20.2186 275.689 20.7767 277.555 20.893C280.308 21.0697 283.806 21.6837 283.806 25.5069C283.806 28.0511 281.704 30.2418 277.527 30.2418C275.201 30.2418 272.903 29.8604 270.768 27.6697L272.545 25.1162C273.582 26.2558 275.954 27.1023 277.582 27.1302C278.945 27.1581 280.22 26.4604 280.22 25.4093C280.22 24.3581 279.392 24.0139 277.317 23.8883C274.564 23.6837 271.27 22.693 271.27 19.4186C271.27 16.1442 274.768 14.9209 277.434 14.9209C279.718 14.9209 281.434 15.386 283.127 16.8232L281.108 19.1721Z"
          fill={variant === "primary" ? "#002855" : "#FFF"}/>
      </g>
    </svg>
  )
}

export default KPFullLogo
