import {useQuery} from "@apollo/client"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import Button from "@mui/material/Button"
import {useTheme} from "@mui/styles"
import GET_ACTIVE_PRACTICE_SESSION from "api/apollo/queries/GET_ACTIVE_PRACTICE_SESSION"
import ActivePracticeSessionForm from "components/ActivePracticeSessionForm"
import {useFormik} from "formik"
import {GetActivePracticeSessionQuery, GetActivePracticeSessionQueryVariables} from "generated/graphql"
import ObjectivesModal from "./components/ObjectivesModal"
import React, {useEffect, useMemo, useState} from "react"
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material"
import BackdropLoad from "components/BackdropLoad"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {LINKS} from "consts/links"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import useExamFullName from "hooks/useExamFullName"
import createBreadCrumbsQuery, {
  PathQueryType
} from "utils/createBreadCrumbsQuery"
import {useDispatch, useSelector} from "store"
import {
  createNewSession,
  getObjectives,
  resetSessionInfo
} from "store/slices/practiceSession/practiceSession"
import {unwrapResult} from "@reduxjs/toolkit"
import {fetchVoucherById} from "store/slices/practiceVouchers/practiceVouchers"
import {
  NewSessionOptions
} from "store/slices/practiceSession/practiceSession.t"

const questionTypesOptions = [
  {
    id: "single",
    name: "1. One right answer",
    selected: false
  },
  {
    id: "multiple",
    name: "2. Multiple right answers",
    selected: false
  },
  {
    id: "practical",
    name: "3. Practical tasks",
    selected: false
  },
  {
    id: "parsons",
    name: "4. Drag and drop",
    selected: false
  },
  {
    id: "match",
    name: "5. Match the answers",
    selected: false
  }
]

export default function PracticeModeOptions() {
  const theme = useTheme()
  const params = useParams()
  const history = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const examFullName = useExamFullName(params)

  const [modal, setModal] = useState<"objectives" | "questionTypes" | null>(null)
  const [objectives, setObjectives] = useState(null)
  const [questionTypes, setQuestionTypes] = useState(null)
  const [questionsModalTouched, setQuestionsModalTouched] = useState(false)
  const [questionsTypesModalTouched, setQuestionsTypesModalTouched] = useState(false)

  const loading = useSelector((state) => state.practiceSession.loading)
  const objectivesRedux = useSelector(state => state.practiceSession.objectives)
  const voucherExamCategory = useSelector(state => state.practiceVouchers.currentVoucher?.examCat)
  const voucherExamCode = useSelector(state => state.practiceVouchers.currentVoucher?.examCode)

  const {data, loading: activePracticeSessionLoading, refetch} = useQuery<
    GetActivePracticeSessionQuery,
    GetActivePracticeSessionQueryVariables
  >(GET_ACTIVE_PRACTICE_SESSION, {
    fetchPolicy: "network-only"
  })

  const activeSession = useMemo(() => {
    return data?.me?.activePracticeSession
  }, [data])

  const breadcrumbs = useMemo(() => {
    const query: Array<string | PathQueryType> = ["/dashboard"]
    let path = "/select-exam-mode"

    if (location.pathname.includes("/practice-tests")) {
      query.push("/practice-tests")
      path = `/practice-tests${path}`
    }

    query.push({path, prepend: params.examName})

    return createBreadCrumbsQuery(query, "Select Test Options", examFullName)
  }, [])

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      duration: "unlimited",
      durationMinutes: 45,
      questions: "all",
      questionsList: [],
      questionTypes: "all",
      questionTypesList: [],
      displayAnswers: false,
      aiHelper: false
    },
    onSubmit: () => {}
  })

  useEffect(() => {
    dispatch(resetSessionInfo())
  }, [dispatch])

  useEffect(() => {
    if (!objectivesRedux) return

    const result = objectivesRedux
      .slice()
      .sort((a, b) => {
        const firstNum = Number.parseInt(a.title)
        const secondNum = Number.parseInt(b.title)

        if (firstNum && secondNum) {
          return firstNum - secondNum
        }

        return a.title - b.title
      })
      .reduce((acc, i) => {
        acc.push({
          id: i.objectiveCode,
          name: i.title,
          checked: false
        })

        return acc
      }, [])

    setObjectives(result)
    setQuestionTypes(questionTypesOptions)
  }, [objectivesRedux, setObjectives])

  useEffect(() => {
    if (voucherExamCategory || !params?.examName) return

    const examId = params?.examName?.split("-")[0]

    dispatch(fetchVoucherById(examId))
  }, [params.examName, voucherExamCategory])

  useEffect(() => {
    if (!params?.examName && !voucherExamCode) return

    let examId = null
    const splitted = params?.examName?.split("-")

    if (splitted?.length) examId = splitted[1]
    if (examId) examId = examId.toLowerCase()

    if (!examId && !voucherExamCode) return

    if (voucherExamCode && voucherExamCode.toLowerCase() === "wce") {
      dispatch(getObjectives("wce"))
      return
    }

    dispatch(getObjectives(examId || voucherExamCode.toLowerCase()))
  }, [voucherExamCode, params?.examName])

  const handlePrev = () => {
    history(`${LINKS.selectExamMode}/${params.examName}`)
  }

  const handleSave = (
    type: NonNullable<typeof modal>,
    list: string[]
  ) => {
    if (type === "objectives") {
      setQuestionsModalTouched(true)
    } else {
      setQuestionsTypesModalTouched(true)
    }

    form.setFieldValue(type === "objectives" ? "questionsList" : "questionTypesList", list)

    setModal(null)
  }

  const handleNext = async () => {
    if (!params.examName) return

    const {
      duration,
      durationMinutes,
      questions,
      questionsList,
      questionTypes,
      questionTypesList,
      displayAnswers,
      aiHelper
    } = form.values

    if (questions === "custom" && questionsList.length < 1) {
      setQuestionsModalTouched(true)

      return
    }

    if (questionTypes === "custom" && questionTypesList.length < 1) {
      setQuestionsTypesModalTouched(true)

      return
    }

    if (duration === "custom" && durationMinutes < 10) return

    const [voucherId, eCode] = params.examName.split("-")
    const type = "practice"
    const options: Partial<NewSessionOptions> = {
      showResult: displayAnswers ? "task" : "end",
      disableAi: !aiHelper
    }

    options["duration"] = duration === "custom" ? durationMinutes : 45

    if (questions === "custom") {
      options["objectives"] = questionsList
    } else {
      options["objectives"] = objectives.map(i => i.id)
    }

    const sessionResult = await dispatch(createNewSession({
      type,
      voucherId,
      examCode: eCode,
      options
    }))
    const normalizedSessionAnswer = unwrapResult(sessionResult)

    if (!normalizedSessionAnswer?.tasks?.length) return

    const currentSessionId = normalizedSessionAnswer?.id
    const examCode = normalizedSessionAnswer?.examCode
    const firstTaskId = normalizedSessionAnswer.tasks[0]?.id

    history(`/exam-window/${examCode}/${currentSessionId}/${firstTaskId}`)
  }

  return (
    <>
      <BackdropLoad open={loading || activePracticeSessionLoading}/>
      <LayoutWithBreadcrumbs
        helmetTitle={"Practice Test Options"}
        title={`${examFullName ? `${examFullName} -` : ""} Practice Test Options`}
        breadcrumbs={breadcrumbs}>
        {activeSession ? (
          <ActivePracticeSessionForm
            session={activeSession}
            type="practiceTest"
            onClose={() => refetch()}
          />
        ) : (
          <>
            <Box pb={4}>
              <Typography variant="h5" color="primary">
                Practice Test Mode Configuration
              </Typography>
              <Typography variant="subtitle2" mt={1}>
                Customize your Practice Test Mode by setting test duration, selecting specific questions,<br/>
                and choosing when to display answers. Changes cannot be made after submitting configurations.
              </Typography>
            </Box>
            <Divider sx={{mb: 4}}/>
            <Box>
              <Box>
                <FormControl>
                  <FormLabel id="radio-group-duration-label" sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="primary" fontWeight={700}>
                      Select duration:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="duration"
                    aria-labelledby="radio-group-duration-label"
                    value={form.values.duration}
                    onChange={form.handleChange}>
                    <FormControlLabel value="unlimited" control={<Radio/>} label="Default (45 minutes)"/>
                    <FormControlLabel value="custom" control={<Radio/>} label="Set custom duration"/>
                  </RadioGroup>
                </FormControl>
                {form.values.duration === "custom" && (
                  <Box mt={1} display="flex" alignItems="center" gap={1.5}>
                    <TextField
                      name="durationMinutes"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 360,
                          style: {
                            paddingLeft: "36px",
                            paddingRight: "24px",
                            textAlign: "center"
                          }
                        }
                      }}
                      value={form.values.durationMinutes}
                      onChange={form.handleChange}
                      onBlur={(e) => {
                        const value = parseInt(e.target.value)

                        if (value < 1) {
                          form.setFieldValue("durationMinutes", 0)
                        } else if (value > 360) {
                          form.setFieldValue("durationMinutes", 360)
                        }
                      }}
                    />
                    <Typography variant="body1">
                      minutes
                    </Typography>
                    {form.values.duration === "custom" && form.values.durationMinutes < 10 && (
                      <Box pl={2} borderLeft={`solid 1px ${theme.palette.lightGrey.main}`}>
                        <Typography variant="body1" color="error">
                          The test should last at least 10 minutes
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <FormControl>
                  <FormLabel id="radio-group-questions-label" sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="primary" fontWeight={700}>
                      Select questions:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="questions"
                    aria-labelledby="radio-group-questions-label"
                    value={form.values.questions}
                    onChange={form.handleChange}>
                    <FormControlLabel value="all" control={<Radio/>} label="Show all questions"/>
                    <FormControlLabel
                      value="custom"
                      control={<Radio/>}
                      label="Select to only show questions from..."
                    />
                  </RadioGroup>
                </FormControl>
                {form.values.questions === "custom" && (
                  <Box mt={1} display="flex" alignItems="center" gap={1.5}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setModal("objectives")}>
                      Open list of questions to select
                    </Button>
                    <Typography variant="body1" color="textSecondary">
                      {form.values.questionsList.length} options selected
                    </Typography>
                    {questionsModalTouched && form.values.questions === "custom" && form.values.questionsList.length < 1 && (
                      <Box pl={2} borderLeft={`solid 1px ${theme.palette.lightGrey.main}`}>
                        <Typography variant="body1" color="error">
                          Select at least 1 option
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <FormControl>
                  <FormLabel id="radio-group-question-types-label" sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="primary" fontWeight={700}>
                      Select type of questions:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="questionTypes"
                    aria-labelledby="radio-group-question-types-label"
                    value={form.values.questionTypes}
                    onChange={form.handleChange}>
                    <FormControlLabel value="all" control={<Radio/>} label="Show type of all questions"/>
                    <FormControlLabel
                      value="custom"
                      control={<Radio/>}
                      label="Select specific types of questions..."
                    />
                  </RadioGroup>
                </FormControl>
                {form.values.questionTypes === "custom" && (
                  <Box mt={1} display="flex" alignItems="center" gap={1.5}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setModal("questionTypes")}>
                      Open list of types to select
                    </Button>
                    <Typography variant="body1" color="textSecondary">
                      {form.values.questionTypesList.length} options selected
                    </Typography>
                    {questionsTypesModalTouched && form.values.questionTypes === "custom" && (
                      form.values.questionTypesList.length < 1 && (
                        <Box pl={2} borderLeft={`solid 1px ${theme.palette.lightGrey.main}`}>
                          <Typography variant="body1" color="error">
                            Select at least 1 option
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <FormControl>
                  <FormLabel id="radio-group-ai-helper-label" sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="primary" fontWeight={700}>
                      AI helper:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="aiHelper"
                    aria-labelledby="radio-group-ai-helper-label"
                    value={form.values.aiHelper}
                    onChange={e => {
                      form.setFieldValue("aiHelper", e.currentTarget.value === "true")
                    }}>
                    <FormControlLabel
                      value={false}
                      control={<Radio/>}
                      label="Disable AI Helper"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio/>}
                      label="Enable AI Helper to receive tips and explanations on the next steps"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mt={3}>
                <FormControl>
                  <FormLabel id="radio-group-display-asnwers-label" sx={{mb: 1}}>
                    <Typography variant="subtitle1" color="primary" fontWeight={700}>
                      Answers’ displaying:
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      The option specifies how you want to request answers and explanations during the test.
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="displayAnswers"
                    aria-labelledby="radio-group-display-answers-label"
                    value={form.values.displayAnswers}
                    onChange={e => {
                      form.setFieldValue("displayAnswers", e.currentTarget.value === "true")
                    }}>
                    <FormControlLabel
                      value={false}
                      control={<Radio/>}
                      label="Do not display any answers until the test is complete"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio/>}
                      label="Autodisplay answers to any response"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Divider sx={{mt: 4}}/>
            <Box display="flex" justifyContent="space-between" pt={4}>
              <ObjectivesModal
                type={modal}
                list={modal === "questionTypes" ? questionTypes : objectives}
                onClose={() => setModal(null)}
                onSubmit={handleSave}
              />
              <Button
                variant="outlined"
                startIcon={<ArrowBackIcon/>}
                disabled={loading}
                onClick={handlePrev}>
                BACK TO PRACTICE MODE
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon/>}
                disabled={loading}
                onClick={handleNext}>
                START PRACTICE TEST
              </Button>
            </Box>
          </>
        )}
      </LayoutWithBreadcrumbs>
    </>
  )
}
