import {gql} from "@apollo/client"

export default gql`
  mutation UpdateClassroomById(
    $classroomPayload: UpdateClassroomPayload!
    $classroomId: GraphQLObjectId!
  ) {
    updateClassroomById(
      classroomPayload: $classroomPayload
      classroomId: $classroomId
    ) {
      _id
    }
  }
`
