import {useLazyQuery} from "@apollo/client"
import {Box, Button, Chip, Link} from "@mui/material"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_TESTING_GROUPS from "api/apollo/queries/GET_TESTING_GROUPS"
import Plus from "assets/icons/Plus"
import DataTable from "components/DataTable"
import {DataTableActionButtons, DataTableFilters, DataTableQueryUpdate} from "components/DataTable/types.t"
import LayoutWithBreadcrumbs from "components/LayoutWithBreadcrumbs"
import {DataTableSchema} from "components/DataTable/types.t"
import {LINKS} from "consts/links"
import {
  Exam,
  GetExamCodesQuery,
  GetExamCodesQueryVariables,
  GetTestingGroupsQuery,
  GetTestingGroupsQueryVariables,
  TestingGroup,
  TestingGroupStatus
} from "generated/graphql"
import useMainPageLink from "hooks/useMainPageLink"
import React, {useMemo, useState} from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import {useSelector} from "store"
import {QueryDataType} from "types/typeUtils"
import getExamCodeName from "utils/getExamCodeName"
import getProctoringTypeName from "utils/getProctoringTypeName"
import getTestingGroupStatusName, {testingGroupStatusNames} from "utils/getTestingGroupStatusName"

export default function TestingGroups() {
  const navigate = useNavigate()
  const {mainPageLink} = useMainPageLink()

  const schoolId = useSelector((store) => store.schoolSlice.currentSchool)

  const [examCodesData, setExamCodesData] = useState<QueryDataType<DeepPartial<Exam>>>(null)
  const [testingGroupsData, setTestingGroupsData] = useState<QueryDataType<DeepPartial<TestingGroup>>>(null)

  const [examCodesQueryFetch, examCodesQuery] = useLazyQuery<
    GetExamCodesQuery,
    GetExamCodesQueryVariables
  >(GET_EXAM_CODES)

  const [testingGroupsQueryFetch, testingGroupsQuery] = useLazyQuery<
    GetTestingGroupsQuery,
    GetTestingGroupsQueryVariables
  >(GET_TESTING_GROUPS)

  const testingGroupsError = useMemo(() => {
    return !testingGroupsData && (testingGroupsQuery.error || null)
  }, [testingGroupsData, testingGroupsQuery.error])

  const tableSchema: DataTableSchema<TestingGroup> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Group Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "name",
        sort: "name"
      },
      {
        type: "custom",
        headerText: "Exam Code",
        contentWrap: "nowrap",
        content: data => {
          return (
            <Box pr={2}>
              {`${(data?.examCode || "").toUpperCase()} - ${getExamCodeName(data?.examCode)}`}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Available Seats",
        headerNoWrap: true,
        content: data => {
         return (
           <Box>
             {data?.license?.examVouchersRemain || 0}
           </Box>
         )
        }
      },
      {
        type: "custom",
        headerText: "Proctoring",
        contentWrap: "nowrap",
        content: data => {
          return (
            <Box pr={2}>
              {getProctoringTypeName(data?.proctoring)}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Teacher",
        contentWrap: "nowrap",
        content: data => {
          return (
            <Box pr={2}>
              {`${data?.teacher?.lastName} ${data?.teacher?.firstName}`}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Proctor Name",
        contentWrap: "nowrap",
        headerNoWrap: true,
        content: data => {
          return (
            <Box pr={2}>
              {data.proctor?.fullName}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Status",
        contentWrap: "nowrap",
        content: data => {
          let status: keyof typeof testingGroupStatusNames = data?.status || TestingGroupStatus.Inprogress

          if (data?.isArchived) {
            status = "archived"
          }

          return (
            <Chip
              variant="outlined"
              size="small"
              label={getTestingGroupStatusName(status)}
              color={
                status === TestingGroupStatus.Inprogress ? (
                  "success"
                ) : status === "archived" ? (
                  "warning"
                ) : "error"
              }
            />
          )
        }
      },
      {
        type: "custom",
        headerText: "Actions",
        contentWrap: "nowrap",
        content: data => {
          return (
            <Link to={`${LINKS.testingGroup}/${data?._id}`} component={RouterLink} underline="none">
              <Button
                variant="outlined"
                color="secondary"
                size="small">
                View more
              </Button>
            </Link>
          )
        }
      }
    ]
  }, [testingGroupsData])

  const tableFilters: DataTableFilters = useMemo(() => {
    return {
      main: [
        {
          id: "examCode",
          type: "select-single",
          label: "Exam Code",
          loading: examCodesQuery.loading,
          loadOnce: true,
          onUpdateOptions: handleQueryExamCodes,
          options: [
            {label: "All exams", value: ""},
            ...(examCodesData?.items || []).filter(i => Boolean(i.code)).map(i => ({
              label: `${i.displayCode} - ${i.displayName.value}`,
              value: i.code
            }))
          ]
        }
      ],
      secondary: [
        {
          id: "includeArchived",
          type: "toggle",
          label: "View Archived Testing Groups",
          initialValue: false
        }
      ]
    }
  }, [examCodesQuery, examCodesData])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "createTestingGroup",
        label: "Add New Testing Group",
        icon: <Plus/>,
        variant: "outlined",
        color: "success",
        onClick: handleAdd
      }
    ]
  }, [])

  function handleQueryExamCodes() {
    examCodesQueryFetch().then(res => {
      setExamCodesData(res.data?.exams || null)
    })
  }

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, filters, searchQuery} = state

    testingGroupsQueryFetch({
      fetchPolicy,
      variables: {
        schoolId,
        examCode: filters?.find(i => i.id === "examCode")?.value,
        includeArchived: filters?.find(i => i.id === "includeArchived")?.value || false,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order,
        search: searchQuery
      }
    }).then(res => {
      setTestingGroupsData(res.data?.testingGroups)
    })
  }

  function handleAdd() {
    navigate(LINKS.addTestingGroup)
  }

  return (
    <LayoutWithBreadcrumbs
      helmetTitle="Testing Groups"
      title="Testing Groups"
      breadcrumbs={[
        {
          path: mainPageLink,
          text: "Dashboard"
        },
        {text: "Testing Groups"}
      ]}>
      <Box minHeight="40vh">
        <DataTable
          schema={tableSchema}
          data={testingGroupsData?.items}
          loading={testingGroupsQuery.loading}
          error={!!testingGroupsError}
          emptyDataMessage="No Results Available"
          itemsTotalCount={testingGroupsData?.total}
          onQueryUpdate={state => handleQuery(state, {refetch: true})}
          search="Search Groups"
          filters={tableFilters}
          actionButtons={tableActionButtons}
          lastPage={!testingGroupsData?.hasMore}
        />
      </Box>
    </LayoutWithBreadcrumbs>
  )
}
