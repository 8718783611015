import {gql} from "@apollo/client"

export default gql`
  mutation MoveUserToAnotherClassroom(
    $fromClassroom: GraphQLObjectId!
    $toClassroom: GraphQLObjectId!
    $userId: GraphQLObjectId!
  ) {
    moveUserToAnotherClassroom(
      fromClassroom: $fromClassroom
      toClassroom: $toClassroom
      userId: $userId
    ) {
      _id
    }
  }
`
