import {gql} from "@apollo/client"

export default gql`
  mutation UpdateUserById(
    $userPayload: UpdateUserPayload!
    $userId: GraphQLObjectId!
  ) {
    updateUserById(
      userPayload: $userPayload
      userId: $userId
    ) {
      _id
    }
  }
`
