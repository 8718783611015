import CustomModal from "components/CustomModal"
import React, {useEffect, useState} from "react"
import {Box, Checkbox, Divider, FormControlLabel, Typography} from "@mui/material"
import Button from "@mui/material/Button"

interface Props {
  type: "objectives" | "questionTypes" | null
  list: Array<{
    id: string
    name: string
    selected: boolean
  }>
  onClose: () => void
  onSubmit: (
    type: "objectives" | "questionTypes",
    list: string[]
  ) => void
}

export default function ObjectivesModal({
  type,
  list,
  onClose,
  onSubmit
}: Props) {
  const [currentType, setCurrentType] = useState<typeof type>(type)
  const [currentList, setCurrentList] = useState<typeof list>(list || [])

  useEffect(() => {
    if (type) {
      setCurrentType(type)
    }

    if (list) {
      setCurrentList(list)
    }
  }, [type, list])

  const handleSelect = (id: string) => {
    setCurrentList(current => {
      return current.map(i => ({
        ...i,
        selected: i.id === id ? !i.selected : i.selected
      }))
    })
  }

  const handleSubmit = () => {
    onSubmit(type, currentList.filter(i => i.selected).map(i => i.id))
  }

  return (
    <CustomModal
      open={!!type}
      onClose={onClose}>
      <Box py={5}>
        <Box px={5}>
          <Typography variant="h4" color="primary" mb={.5}>
            {currentType === "objectives" ? "Choose Objectives" : "Choose Type of Questions"}
          </Typography>
          <Typography variant="body1" color="primary">
            {currentType === "objectives" ? (
              "Choose the topics of the questions that will be in the test."
            ) : (
              "Choose type of the questions that will be in the test."
            )}
          </Typography>
          <Divider sx={{mt: 4}}/>
        </Box>
        <Box px={3} py={4}>
          <Box minHeight="30vh" maxHeight="40vh" px={2} sx={{overflowY: "auto"}}>
            {currentList.map(i => (
              <Box key={i.id} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={i.selected}
                      onChange={() => handleSelect(i.id)}
                    />
                  }
                  label={i.name}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box px={5}>
          <Divider sx={{mb: 4}}/>
          <Box display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={onClose}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit}>
              SAVE
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  )
}
