import apiCall from "api/rest"
import {ENDPOINT} from "consts/endpoints"
import {CreateSessionBodyI} from "store/slices/practiceSession/practiceSession.t"
import {IPracticeSessionService} from "types/services/practiceSession.service.t"

const replaceUrl = (url, isExam) => {
  return !isExam ? url : url.replaceAll("testing", "exam")
}

const PracticeSessionService: IPracticeSessionService = {
  getObjectives: (examCategory) => {
    return apiCall({
      url: `${ENDPOINT.getObjectives}${examCategory ? `/${examCategory}` : ""}`,
      method: "GET"
    })
  },
  finishSessionExam: (sessionId, isExam) => {
    return apiCall({
      url: `${isExam ? "/exam-session" : ENDPOINT.finishSessionExam}/${sessionId}/${isExam ? "force-finish" : "finish"}`,
      method: isExam ? "PATCH" : "POST"
    })
  },
  startSessionExam: (sessionId) => {
    return apiCall({
      url: `${ENDPOINT.finishSessionExam}/${sessionId}/start`,
      method: "POST"
    })
  },
  getTasksAnswers: (sessionId) => {
    return apiCall({
      url: `/answers/${sessionId}`,
      method: "GET"
    })
  },
  getSessionTasksStatus: (sessionId, isExam) => {
    return apiCall({
      url: replaceUrl(`/session-tasks-status/testing/${sessionId}`, isExam),
      method: "GET"
    })
  },
  fetchTask: async ({taskId, sessionId, isExam}) => {
    return apiCall({
      url: replaceUrl(`${ENDPOINT.getTask}/${sessionId}/select-task/${taskId}`, isExam),
      method: "GET"
    })
  },
  fetchSessionExamDetails: (sessionId, isExam) => {
    return apiCall({
      url: replaceUrl(`${ENDPOINT.finishSessionExam}/${sessionId}`, isExam),
      method: "GET"
    })
  },
  addFlaggedTask: ({sessionId, taskId}) => {
    return apiCall({
      url: `/testing/sessions/${sessionId}/flag/${taskId}`,
      method: "POST"
    })
  },
  removeFlaggedTask: ({sessionId, taskId}) => {
    return apiCall({
      url: `/testing/sessions/${sessionId}/flag/${taskId}`,
      method: "DELETE"
    })
  },
  createNewSession: (options: CreateSessionBodyI) => {
    return apiCall({
      url: `${ENDPOINT.createNewSession}`,
      method: "POST",
      data: options
    })
  },
  submitPracticeTask: ({sessionId, taskId, body, isExam}) => {
    return apiCall({
      url: replaceUrl(`${isExam ? "exam-session" : ENDPOINT.getTask}/${sessionId}/${isExam ? "task" : "answer"}/${taskId}`, isExam),
      method: "POST",
      data: body || null
    })
  }
}

export default PracticeSessionService
