import {gql} from "@apollo/client"

export default gql`
  query GetClassroomDefaultExams(
    $classroomId: GraphQLObjectId!
  ) {
    getClassroom(classroomId: $classroomId) {
      defaultExams {
        _id
        displayCode
        displayName {
          en
          value
        }
        code
      }
    }
  }
`
