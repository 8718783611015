import {gql} from "@apollo/client"

export default gql`
  query GetExams(
    $sortBy: String
    $order: SortOrder
    $offset: Int = 0
    $take: Int = 100
    $search: String
  ) {
    exams(
      search: $search,
      sortBy: $sortBy,
      order: $order,
      offset: $offset,
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        displayCode
        displayName {
          value
        }
        language
        tasksAmount
        code
      }
    }
  }
`
