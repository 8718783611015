import React, {useMemo} from "react"
import {Grid} from "@mui/material"
import ExamItem from "./components/ExamItem"
import AddExamItem from "./components/AddExamItem"
import BackdropLoad from "components/BackdropLoad"
import {useSelector} from "store"
import {LINKS} from "consts/links"
import {useNavigate} from "react-router-dom"
import PracticeModeCard from "components/PracticeModeCard"
import {Voucher} from "generated/graphql"

interface Props {
  vouchers: DeepPartial<Voucher>[]
  onActivate: (examName: string) => void
  onStart: (_id: string, examCode: string) => void
  loading: boolean
}

export default function ExamsList({
  loading,
  vouchers,
  onActivate,
  onStart
}: Props) {
  const user = useSelector((store) => store.userSlice)

  const navigate = useNavigate()

  const list = useMemo(() => {
    return vouchers.map(i => (
      {
        _id: i._id,
        title: i?.exam?.code ? i.exam.code.toUpperCase() : "Exam",
        subtitle: i?.exam?.displayName?.value,
        examCode: i?.exam?.code,
        expired: i.expired,
        expirationDate: i.expirationDate
      }
    ))
  }, [vouchers])

  return (
    <Grid container item spacing={3} xs={12} mt={0}>
      <BackdropLoad open={loading}/>
      {user.isLabLicenseActive && (
        <PracticeModeCard
          type="lab"
          expired={!user.isLabLicenseActive}
          expirationDate={user?.labsLicense?.expirationDate}
          onClick={() => navigate(LINKS.wpLab)}
        />
      )}
      {list?.length ? (
        list.map(i => (
          <ExamItem
            key={i._id}
            data={i}
            onActivate={onActivate}
            onStart={onStart}
          />
        ))
      ) : (
        <AddExamItem/>
      )}
    </Grid>
  )
}
