import {Box, Button, Card, CardContent, CardHeader, Typography} from "@mui/material"
import PasswordInput from "components/PasswordInput"
import {Formik, FormikHelpers} from "formik"
import CrossIcon from "@mui/icons-material/Clear"
import CheckmarkIcon from "@mui/icons-material/Done"
import React, {useMemo, useState} from "react"
import * as Yup from "yup"
import {useMutation} from "@apollo/client"
import {ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables} from "generated/graphql"
import CHANGE_USER_PASSWORD from "api/apollo/mutations/CHANGE_USER_PASSWORD"
import {notifyUser} from "store/slices/notifier/notifier"
import {useDispatch} from "store"

interface Props {
  userId: string
}

const passwordRequirements = [
  "Must contain at least 8 characters",
  "Must contain at least one number",
  "Must contain at least one lowercase letter",
  "Must contain at least one uppercase letter"
]

export default function UpdateUserPassword({userId}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [updatePassword] = useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(CHANGE_USER_PASSWORD)
  const dispatch = useDispatch()

  const formInitialValues = useMemo(() => {
    return {
      newPassword: "",
      confirmPassword: ""
    }
  }, [])

  const handleSubmit = (data: typeof formInitialValues, {resetForm}: FormikHelpers<typeof formInitialValues>) => {
    if (!data || !Object.values(data)?.length) return

    setIsSubmitting(true)
    updatePassword({variables: {userId, newPassword: data.newPassword}})
    .then(() => {
      dispatch(notifyUser({message: "Password updated"}))
    })
    .finally(() => {
      setIsSubmitting(false)
      resetForm()
    })
  }

  const fullValidatorForSchema = (schema) => (values) => schema.validate(values, {
    abortEarly: false
  }).then(() => ({})).catch(({inner}) => inner.reduce((memo, {path, message}) => ({
    ...memo,
    [path]: (memo[path] || []).concat(message)
  }), {}))

  return (
    <Box width={{md: "50%", xs: "100%"}}>
      <Formik
        validate={fullValidatorForSchema(Yup.object().shape({
          newPassword: Yup.string()
          .min(8, "Must contain at least 8 characters")
          .matches(/[0-9]/, "Must contain at least one number")
          .matches(/[a-z]/, "Must contain at least one lowercase letter")
          .matches(/[A-Z]/, "Must contain at least one uppercase letter")
          .max(255)
          .required("Required"),
          confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Required")
        }))}
        initialValues={formInitialValues}
        options
        onSubmit={handleSubmit}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title="Change Password"/>
              <CardContent>
                <Box>
                  <Box mb={2}>
                    <PasswordInput
                      name="newPassword"
                      value={values["newPassword"]}
                      error={!!(touched["newPassword"] && errors["newPassword"])}
                      helperText={touched["newPassword"] && errors["newPassword"] && errors["newPassword"][0]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Password"
                      autoComplete="no"
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={2}>
                    <PasswordInput
                      name="confirmPassword"
                      value={values["confirmPassword"]}
                      error={!!(touched["confirmPassword"] && errors["confirmPassword"])}
                      helperText={touched["confirmPassword"] && errors["confirmPassword"] && errors["confirmPassword"][0]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Password Confirmation"
                      fullWidth
                      variant="outlined"
                    />
                    {errors["newPassword"] instanceof Array && errors["newPassword"]?.length && (
                      <Box mt={2}>
                        {passwordRequirements.map(i =>
                          <Box
                            key={i}
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              mb: 1
                            }}>
                            {errors["newPassword"] instanceof Array && errors["newPassword"].includes(i as any) ? (
                              <CrossIcon color="error"/>
                            ) : (
                              <CheckmarkIcon color="success"/>
                            )}
                            <Typography color="textPrimary" variant="body1" ml={1}>
                              {i}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}

                  </Box>
                </Box>
              </CardContent>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained">
                  Change Password
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    </Box>
  )
}
