import {useCallback, useEffect, useState} from "react"
import Cookies from "js-cookie"
import jsonParse from "utils/jsonParse"
import _ from "lodash"

export default function useCookies<T>(key: string): [T | null, (value: T) => void, boolean]
export default function useCookies(): boolean
export default function useCookies<T>(key?: string): [T | null, (value: T) => void, boolean] | boolean {
  const [value, setValue] = useState<T | null>(() => (key ? jsonParse(Cookies.get(key) || "") : null))
  const [hasToken, setHasToken] = useState(() => checkTokenExists())

  useEffect(() => {
    const handleCookieChange = () => {
      if (key) {
        const currentValue = jsonParse(Cookies.get(key) || "") as T
        if (!_.isEqual(currentValue, value)) {
          setValue(currentValue)
        }
      }
      setHasToken(checkTokenExists())
    }

    const listener = () => handleCookieChange()

    window.addEventListener("cookieChange", listener)

    const interval = setInterval(handleCookieChange, 1000)

    return () => {
      window.removeEventListener("cookieChange", listener)
      clearInterval(interval)
    }
  }, [key, value, hasToken])

  const setCookie = useCallback(
    (newValue: T) => {
      if (key) {
        const currentCookie = Cookies.get(key)
        const parsedCookie = currentCookie ? jsonParse(currentCookie) : null
        if (!_.isEqual(parsedCookie, newValue)) {
          Cookies.set(key, JSON.stringify(newValue), {expires: 365, secure: true, sameSite: "strict"})
          setValue(newValue)

          window.dispatchEvent(new Event("cookieChange"))
        }
      }
    },
    [key]
  )

  function checkTokenExists() {
    return Object.keys(Cookies.get()).some(cookieKey => cookieKey.includes("idToken"))
  }

  return key === undefined ? hasToken : [value, setCookie, hasToken]
}
