import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {ExamService} from "services/exam.service"
import sort from "utils/sort"
import {handleError} from "./notifier/notifier"
import {errorCase, pendingCase} from "store/storeHelpers"

export const initialState = {
  loading: false,
  cancelingSession: false,
  loadingHasAccess: false,
  hasErrors: false,
  hasAccessErrors: false,
  allExams: null,
  examsByProgramId: [],
  exam: {},
  userExamsScore: null,
  doesStudentHaveAccess: "",
  currentExamDetails: null,
  availableExamCodes: null,
  examCodesHaveSessions: null,
  examCodesHavePracticeSessions: null
}

export const getAvailableExamCodes = createAsyncThunk(
  "exams/getAvailableExamCodes",
  async () => {
    return await ExamService.getAvailableExamCodes()
  }
)

export const fetchExamDetailsByIdThunk = createAsyncThunk(
  "exams/fetchExamDetailsByIdThunk",
  async (examId: string, thunkAPI) => {
    try {
      const response = await ExamService.fetchExamDetailsById(examId)
      return response?.data || response
    } catch (err) {
      thunkAPI.dispatch(handleError(err))
      throw err?.data?.error || err
    }
  }
)

const examsSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
    getExams: (state) => {
      state.loading = true
    },
    getExamsSuccess: (state, {payload}) => {
      state.allExams = payload || []
      state.loading = false
      state.hasErrors = false
    },
    getExamsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
    },
    getExamByProgramIdSuccess: (state, {payload}) => {
      state.examsByProgramId = payload
      state.loading = false
      state.hasErrors = false
    },
    getExam: (state, {payload}) => {
      state.exam = payload
    },
    clearHaveAccess: (state) => {
      state.doesStudentHaveAccess = ""
      state.loadingHasAccess = false
      state.hasAccessErrors = false
    },
    clearCurrentExamDetails: (state) => {
      state.currentExamDetails = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableExamCodes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchExamDetailsByIdThunk.pending, pendingCase)
    builder.addCase(getAvailableExamCodes.fulfilled, (state, {payload}) => {
      state.availableExamCodes = sort(payload, "displayCode")
      state.loading = false
      state.hasErrors = false
    })
    builder.addCase(fetchExamDetailsByIdThunk.fulfilled, (state, action) => {
      state.loading = false
      state.currentExamDetails = action.payload?.data || action.payload
    })
    builder.addCase(fetchExamDetailsByIdThunk.rejected, errorCase)
    builder.addCase(getAvailableExamCodes.rejected, errorCase)
  }
})

export const {clearCurrentExamDetails} = examsSlice.actions

export default examsSlice.reducer
