import createSvgIcon from "@mui/material/utils/createSvgIcon"

const KPLogo = createSvgIcon(
  <svg width="100%" height="100%" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_31_4699)">
      <path
        d="M38.8764 9.10564V23.4424C38.876 23.758 38.8133 24.0705 38.692 24.362C38.5708 24.6534 38.3933 24.9181 38.1697 25.141C37.9461 25.3638 37.6808 25.5405 37.389 25.6608C37.0971 25.7812 36.7844 25.8429 36.4688 25.8424H27.72C27.0847 25.8404 26.4761 25.5867 26.0275 25.1368C25.579 24.6869 25.3271 24.0776 25.3271 23.4424V17.381C25.3669 16.6581 25.5717 15.9539 25.9258 15.3225C26.28 14.691 26.774 14.1491 27.3702 13.7382L36.8409 7.95959C37.9573 7.27494 38.8764 7.78843 38.8764 9.10564Z"
        fill="#FFC42A"
      />
      <path
        d="M32.126 2.76744V26.1237C32.126 28.1591 30.9761 28.9814 29.5732 28.9814H20.2364C18.8335 28.9814 17.6836 28.0028 17.6836 25.9674V10.8865C17.6836 8.85116 18.4986 8.01395 19.7266 7.03535L29.7332 0.921857C31.218 -0.0753523 32.126 0.713485 32.126 2.76744Z"
        fill="#2092C0"
      />
      <path
        d="M23.444 2.65956V27.6605C23.441 28.7668 23.0002 29.827 22.2178 30.6093C21.4354 31.3916 20.3751 31.8324 19.2687 31.8354H4.0113C2.90162 31.8373 1.83655 31.3987 1.05015 30.6159C0.263742 29.8331 -0.17966 28.7701 -0.182617 27.6605V17.0856C-0.182617 14.7861 1.41755 11.9284 3.37868 10.734L19.8753 0.6577C21.8364 -0.536718 23.444 0.371188 23.444 2.65956Z"
        fill="#002855"
      />
    </g>
    <defs>
      <clipPath id="clip0_31_4699">
        <rect width="39" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>,
  "KPLogo"
)

export default KPLogo
