import {useLazyQuery, useMutation} from "@apollo/client"
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material"
import ADD_STUDENT_TO_CLASSROOM from "api/apollo/mutations/ADD_STUDENT_TO_CLASSROOM"
import GET_CLASSROOM_IDS from "api/apollo/queries/GET_CLASSROOM_IDS"
import GET_CLASSROOM_INFO_BY_JOIN_CODE from "api/apollo/queries/GET_CLASSROOM_INFO_BY_JOIN_CODE"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import SearchIcon from "assets/icons/Search"
import SelectAsync from "components/SelectAsync"
import {Formik, useFormik} from "formik"
import {
  AddStudentToClassroomMutation,
  AddStudentToClassroomMutationVariables,
  GetClassroomIdsQuery,
  GetClassroomIdsQueryVariables,
  GetClassroomInfoByJoinCodeQuery,
  GetClassroomInfoByJoinCodeQueryVariables,
  GetSchoolIdsQuery,
  GetSchoolIdsQueryVariables,
  School,
  User
} from "generated/graphql"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import * as Yup from "yup"

interface Props {
  onAfterSubmit: () => void
  user: Partial<User>
  school?: DeepPartial<School>
  classroomIds?: string[]
}

const tabs: Array<{
  label: string
  value: string
  disabled?: boolean
}> = [
  {label: "Default", value: "default"},
  {label: "Join code", value: "joinCode"}
]

export default function EnrollUserForm({
  onAfterSubmit,
  user,
  school,
  classroomIds
}: Props) {
  const dispatch = useDispatch()

  const [selectedSchool, setSelectedSchool] = useState(school?._id || "")
  const [currentTab, setCurrentTab] = useState("default")
  const [classroomByJoinCodeError, setClassroomByJoinCodeError] = useState(null)

  const formInitialValues = useMemo(() => {
    return {
      schoolId: school?._id || "",
      classroomId: ""
    }
  }, [school])

  const [addStudentToClassroom] = useMutation<
    AddStudentToClassroomMutation,
    AddStudentToClassroomMutationVariables
  >(ADD_STUDENT_TO_CLASSROOM)

  const [schoolIdsQueryFetch, schoolIdsQuery] = useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  const [classroomIdsQueryFetch, classroomIdsQuery] = useLazyQuery<
    GetClassroomIdsQuery,
    GetClassroomIdsQueryVariables
  >(GET_CLASSROOM_IDS)

  const [classroomInfoByJoinCodeQueryFetch, classroomInfoByJoinCodeQuery] = useLazyQuery<
    GetClassroomInfoByJoinCodeQuery,
    GetClassroomInfoByJoinCodeQueryVariables
  >(GET_CLASSROOM_INFO_BY_JOIN_CODE)

  const joinCodeForm = useFormik({
    validateOnBlur: true,
    initialValues: {
      joinCode: ""
    },
    validationSchema: Yup.object().shape({
      joinCode: Yup.string().required("Classroom code is required").min(4, "Enter a valid classroom code")
    }),
    onSubmit: () => {
      if (classroomInfo?.classroomId) {
        handleSubmit({
          schoolId: school._id,
          classroomId: classroomInfo.classroomId
        })
      }
    }
  })

  const classroomInfoError = useMemo(() => {
    return classroomByJoinCodeError || joinCodeForm.errors.joinCode
  }, [classroomByJoinCodeError, joinCodeForm.errors.joinCode])

  const classroomInfo = useMemo(() => {
    return classroomInfoByJoinCodeQuery.data?.getClassroomInfoByJoinCode || null
  }, [classroomInfoByJoinCodeQuery.data])

  const schools = useMemo(() => {
    return school ? (
      [{
        label: school.name,
        value: school._id
      }]
    ) : (
      schoolIdsQuery.data?.schools.items.map(i => ({
        label: i.name,
        value: i._id})
      ) || []
    )
  }, [schoolIdsQuery.data, school])

  const classrooms = useMemo(() => {
    return (selectedSchool && classroomIdsQuery.data?.classrooms.items.filter((i) => {
      if (classroomIds?.length) {
        return !classroomIds.includes(i._id)
      }

      return true
    }).map(i => ({
      label: i.name,
      value: i._id})
    )) || []
  }, [selectedSchool, classroomIdsQuery.data])

  useEffect(() => {
    if (joinCodeForm.values.joinCode?.length > 4) {
      classroomInfoByJoinCodeQueryFetch({
        variables: {
          joinCode: joinCodeForm.values.joinCode
        }
      }).then(res => {
        if (res.error || !res.data?.getClassroomInfoByJoinCode) {
          setClassroomByJoinCodeError("Classroom not found")
        } else {
          setClassroomByJoinCodeError(null)
        }
      }).catch(() => {
        setClassroomByJoinCodeError("Error while trying to get classroom")
      })
    } else {
      setClassroomByJoinCodeError(null)
    }
  }, [joinCodeForm.values.joinCode])

  const handleQuerySchoolIds = (searchQuery: string) => {
    !school && schoolIdsQueryFetch({
      variables: {
        search: searchQuery
      }
    })
  }

  const handleQueryClassroomIds = (searchQuery: string) => {
    selectedSchool && (
      classroomIdsQueryFetch({
        variables: {
          schoolId: selectedSchool,
          search: searchQuery
        }
      })
    )
  }

  const handleSubmit = (data: typeof formInitialValues) => {
    if (!data || !Object.values(data)?.length) return

    addStudentToClassroom({
      variables: {
        classroomId: data.classroomId,
        userId: user._id
      }
    }).then(() => {
      onAfterSubmit()
    }).catch(err => {
      dispatch(handleError(err))
    })
  }

  return (
    <Box p={4} minWidth="40vw">
      <Typography variant="h5" textAlign="center" px={2}>
        Enroll a user in a classroom
      </Typography>
      <Box mt={2}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Tabs
            indicatorColor="primary"
            onChange={(_e, value) => setCurrentTab(value)}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}>
            {tabs.map(i => (
              <Tab key={i.value} label={i.label} value={i.value} disabled={i.disabled}/>
            ))}
          </Tabs>
        </Box>
        <Box>
          {currentTab === "joinCode" ? (
            <form onSubmit={joinCodeForm.handleSubmit}>
              <Box maxWidth="860px" mt={4}>
                <Box mb={3}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Classroom code"
                    InputProps={{
                      endAdornment: classroomInfoByJoinCodeQuery.loading ? (
                        <InputAdornment position="start" sx={{marginRight: "14px"}}>
                          <CircularProgress size={24}/>
                        </InputAdornment>
                      ) : undefined
                    }}
                    value={joinCodeForm.values.joinCode}
                    onChange={(e) => {
                      joinCodeForm.setFieldValue("joinCode", e.target.value)
                    }}
                    onBlur={() => joinCodeForm.handleBlur({
                      target: {
                        name: "joinCode"
                      }
                    })}
                    error={!!classroomInfoError}
                    helperText={classroomInfoError}
                  />
                </Box>
                {classroomInfo && (
                  <Box mb={3}>
                    <Paper variant="outlined" sx={{p: 2, pb: 2.5}}>
                      <Box mb={3}>
                        <Typography variant="h6" mb={0.5} fontWeight="bold">
                          District
                        </Typography>
                        <Typography variant="body1">
                          {classroomInfo.districtName || "No district"}
                        </Typography>
                      </Box>
                      <Box mb={3}>
                        <Typography variant="h6" mb={0.5} fontWeight="bold">
                          School
                        </Typography>
                        <Typography variant="body1">
                          {classroomInfo.schoolName}
                        </Typography>
                      </Box>
                      <Box mb={3}>
                        <Typography variant="h6" mb={0.5} fontWeight="bold">
                          Classroom
                        </Typography>
                        <Typography variant="body1">
                          {classroomInfo.classroomName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" fontWeight="bold">
                          Teachers
                        </Typography>
                        <Typography variant="body1">
                          {classroomInfo.teacherNames.join(", ")}
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="end" mt={4}>
                <Button type="submit" variant="contained" disabled={!classroomInfo}>
                  Enroll
                </Button>
              </Box>
            </form>
          ) : (
            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={Yup.object().shape({
                schoolId: Yup.string().required("Required"),
                classroomId: Yup.string().required("Required")
              })}
              onSubmit={handleSubmit}>
              {({
                errors,
                setValues,
                handleSubmit,
                setFieldValue,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box maxWidth="860px" mt={4}>
                    <Box mb={3}>
                      <SelectAsync
                        id="school"
                        label="School"
                        disabled={!!school}
                        icon={<SearchIcon/>}
                        error={touched.schoolId && errors.schoolId}
                        touched={touched.schoolId}
                        value={values.schoolId}
                        onChange={(value) => {
                          setSelectedSchool(value)
                          setValues({schoolId: value, classroomId: ""})
                        }}
                        loading={schoolIdsQuery.loading}
                        options={schools}
                        onUpdateOptions={handleQuerySchoolIds}
                      />
                    </Box>
                    <Box>
                      <SelectAsync
                        id="classroom"
                        label="Classroom"
                        icon={<SearchIcon/>}
                        error={touched.classroomId && errors.classroomId}
                        touched={touched.classroomId}
                        value={values.classroomId}
                        onChange={(value) => {
                          setFieldValue("classroomId", value)
                        }}
                        loading={classroomIdsQuery.loading}
                        options={classrooms}
                        onUpdateOptions={handleQueryClassroomIds}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="end" mt={4}>
                    <Button type="submit" variant="contained">
                      Enroll
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
    </Box>
  )
}
