import {useLazyQuery} from "@apollo/client"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {useTheme} from "@mui/styles"
import GET_ADMINISTRATED_DISTRICTS from "api/apollo/queries/GET_ADMINISTRATED_DISTRICTS"
import GET_DISTRICT_SCHOOL_IDS from "api/apollo/queries/GET_DISTRICT_SCHOOL_IDS"
import GET_MANAGED_SCHOOLS from "api/apollo/queries/GET_MANAGED_SCHOOLS"
import {LINKS} from "consts/links"
import {
  GetAdministratedDistrictsQuery,
  GetAdministratedDistrictsQueryVariables, GetDistrictSchoolIdsQuery, GetDistrictSchoolIdsQueryVariables,
  GetManagedSchoolsQuery,
  GetManagedSchoolsQueryVariables,
  SortOrder
} from "generated/graphql"
import {sortBy, startCase, throttle, uniqBy} from "lodash"
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Autocomplete, Box, Collapse, Divider, TextField, Typography} from "@mui/material"
import {useNavigate} from "react-router-dom"
import {useSelector} from "store"
import useCookies from "hooks/useCookies"
import {Roles} from "types/access"

enum AccountGroups {
  Student = "1. student",
  Districts = "2. districts",
  DistrictSchools = "3. districtSchools",
  Schools = "3. schools"
}

type Option = {
  _id: string
  name: string
  group: AccountGroups
  districtId?: string
  totalSchools?: number
}

const studentOption = {
  _id: "",
  group: AccountGroups.Student,
  name: "Personal Account"
}

const defaultQuantity = 50

export default function AccountSelect() {
  const navigate = useNavigate()
  const theme = useTheme()

  const [, setSelectedAccount] = useCookies<SelectedAccount>("selectedAccount")
  const {currentSchool, name: schoolName, districtName: schoolDistrictName} = useSelector((state) => state.schoolSlice)
  const {currentDistrict, name: districtName, totalSchools} = useSelector((state) => state.districtSlice)
  const {adminInDistrictIds} = useSelector((state) => state.userSlice)
  const {roles} = useSelector((state) => state.userSlice)

  const [open, setOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(AccountGroups.Student)
  const [searchValue, setSearchValue] = useState("")
  const [searchedOptions, setSearchedOptions] = useState<Option[]>([])
  const [options, setOptions] = useState<Option[]>(currentDistrict && currentSchool ? (
    [studentOption, {
      _id: currentDistrict,
      group: AccountGroups.Districts,
      name: schoolDistrictName,
      totalSchools: totalSchools
    }]
  ) : [studentOption])
  const [value, setValue] = useState<Option>(currentDistrict || currentSchool ? (
    {
      _id: currentSchool || currentDistrict,
      group: currentSchool && currentDistrict ? AccountGroups.DistrictSchools : currentDistrict ? AccountGroups.Districts : AccountGroups.Schools,
      name: schoolName || districtName,
      districtId: (currentSchool && currentDistrict) || "",
      totalSchools: currentDistrict ? totalSchools : null
    }) : options[0])

  const [districtsQueryFetch, districtsQuery] = useLazyQuery<
    GetAdministratedDistrictsQuery,
    GetAdministratedDistrictsQueryVariables
  >(GET_ADMINISTRATED_DISTRICTS)

  const [districtSchoolsQueryFetch, districtSchoolsQuery] = useLazyQuery<
    GetDistrictSchoolIdsQuery,
    GetDistrictSchoolIdsQueryVariables
  >(GET_DISTRICT_SCHOOL_IDS)

  const [schoolsQueryFetch, schoolsQuery] = useLazyQuery<
    GetManagedSchoolsQuery,
    GetManagedSchoolsQueryVariables
  >(GET_MANAGED_SCHOOLS)

  const [numberOfDistricts, numberOfDistrictSchools, numberOfSchools] = useMemo(() => {
    const getGroupLength = (group: AccountGroups) => options.filter((i) => i.group === group).length

    return [
      getGroupLength(AccountGroups.Districts),
      getGroupLength(AccountGroups.DistrictSchools),
      getGroupLength(AccountGroups.Schools)
    ]
  }, [options])

  const loading = useMemo(() => {
    return districtsQuery.loading || districtSchoolsQuery.loading || schoolsQuery.loading || false
  }, [districtsQuery.loading, districtSchoolsQuery.loading, schoolsQuery.loading])

  const isReadOnly = useMemo(() => {
    const {Student, Districts, Schools, DistrictSchools} = AccountGroups

    const singleSchool = numberOfSchools <= 1
    const singleDistrictSchool = numberOfDistrictSchools <= 1
    const singleDistrict = numberOfDistricts <= 1

    return (
      selectedGroup === Student ||
      (singleSchool && singleDistrictSchool && singleDistrict) ||
      (selectedGroup === Districts && singleDistrict) ||
      (selectedGroup === Schools && singleSchool) ||
      (selectedGroup === DistrictSchools && singleDistrictSchool)
    )
  }, [selectedGroup, numberOfSchools, numberOfDistrictSchools, numberOfDistricts])

  const handleQueryDistricts = (search?: string) => {
    districtsQueryFetch({
      variables: {
        search,
        sortBy: "name",
        order: SortOrder.Asc,
        take: defaultQuantity
      },
      fetchPolicy: "network-only"
    }).then(res => {
      const items = res.data?.getAdministratedDistricts?.items?.map(i => ({
        _id: i._id,
        name: i.name || "",
        group: AccountGroups.Districts,
        totalSchools: i.schools.total
      })) || []
      handleChangeOption(items, !!search)
    })
  }

  const handleQueryDistrictSchools = (search?: string) => {
    const districtId = value.districtId || value._id
    districtSchoolsQueryFetch({
      variables: {
        districtId,
        search,
        sortBy: "name",
        order: SortOrder.Asc,
        take: defaultQuantity
      },
      fetchPolicy: "network-only"
    }).then(res => {
      const items = res.data?.getDistrict?.schools?.items?.map(i => ({
        districtId,
        _id: i._id,
        name: i.name || "",
        group: AccountGroups.DistrictSchools
      })) || []
      handleChangeOption(items, !!search)
    })
  }

  const handleQuerySchools = (search?: string) => {
    schoolsQueryFetch({
      variables: {
        search,
        sortBy: "name",
        order: SortOrder.Asc,
        take: defaultQuantity
      },
      fetchPolicy: "network-only"
    }).then(res => {
      const items = res.data?.getManagedSchools?.items?.map(i => ({
        _id: i._id,
        name: i.name,
        group: AccountGroups.Schools,
        districtId: i.districtId || ""
      })) || []
      handleChangeOption(items, !!search)
    })
  }

  const handleChangeOption = (items: Option[], isSearchItems?: boolean) => {
    if (isSearchItems) {
      const groupOptions = options.filter((i) => i.group === selectedGroup)
      setSearchedOptions(uniqBy([...groupOptions, ...items], "_id"))
    } else {
      setOptions((prev) => uniqBy(sortBy([value, ...prev, ...items], ["group"]), "_id"))
    }
  }

  const handleSelectOption = (option: Option) => {
    let currentGroup = option.group
    if (currentGroup === AccountGroups.Schools && option?.districtId) {
      if (adminInDistrictIds.includes(option.districtId) || roles.includes(Roles.Admin)) {
        currentGroup = AccountGroups.DistrictSchools
      }
    }

    setOpen(false)
    setValue(option)
    handleChangeOption([option])

    setSelectedAccount({
      districtId: currentGroup === AccountGroups.Districts ? option._id : currentGroup === AccountGroups.DistrictSchools ? option.districtId : "",
      schoolId: [AccountGroups.Schools, AccountGroups.DistrictSchools].includes(currentGroup) ? option._id : ""
    })

    navigate([AccountGroups.Schools, AccountGroups.DistrictSchools].includes(currentGroup) ?
        LINKS.classrooms
      :
        AccountGroups.Student === currentGroup
      ?
        LINKS.dashboard
      :
        LINKS.districtAdmin
    )
  }

  const handleUpdateOption = useCallback(throttle((value: string) => {
    const isSearchForOptions = options.filter((i) => i.group === selectedGroup).length >= defaultQuantity
    if (isSearchForOptions) {
      if (selectedGroup === AccountGroups.Districts) {
        handleQueryDistricts(value)
      }
      if (selectedGroup === AccountGroups.Schools) {
        handleQuerySchools(value)
      }
    } else {
      handleChangeOption([], true)
    }
  }, 900), [selectedGroup, options])

  const handleSelectGroup = (group: AccountGroups) => {
    setSearchValue("")
    setSelectedGroup(selectedGroup === group ? AccountGroups.Student : group)
  }

  useEffect(() => {
    handleQuerySchools()
    handleQueryDistricts()
  }, [])

  useEffect(() => {
    if (open) {
      setSearchValue("")
      setSelectedGroup(value.group)
    }
  }, [open])

  useEffect(() => {
    if (numberOfDistricts <= 1) {
      if (numberOfDistrictSchools === 0) {
        setSelectedGroup(numberOfSchools > 1 ? AccountGroups.Schools : AccountGroups.Student)
      } else if (numberOfSchools === 0) {
        setSelectedGroup(AccountGroups.DistrictSchools)
      }
    } else if (numberOfDistricts > 1 && numberOfDistrictSchools === 0 && numberOfSchools <= 1) {
      setSelectedGroup(AccountGroups.Districts)
    }
  }, [numberOfDistricts, numberOfDistrictSchools, numberOfSchools, selectedGroup])

  useEffect(() => {
    setSelectedGroup(value.group)
    if ((value.group === AccountGroups.Districts && value?.totalSchools) || value.group === AccountGroups.DistrictSchools) {
      handleQueryDistrictSchools()
    }
  }, [value])

  useEffect(() => {
    handleUpdateOption(searchValue)
  }, [searchValue])

  return (
    <Autocomplete
      ListboxProps={{
        sx: {
          maxHeight: "70vh"
        }
      }}
      disabledItemsFocusable={false}
      renderOption={(props, option) => (
        <Box
          key={option._id + option.name}
          sx={{
            "& .MuiAutocomplete-option.Mui-focused": {
              backgroundColor: "transparent"
            },
            "& .MuiAutocomplete-option[aria-selected=\"true\"]": {
              backgroundColor: `${theme.palette.primary.main} !important`,
              color: `${theme.palette.white.main} !important`
            },
            "& .MuiAutocomplete-option:hover": {
              backgroundColor: theme.palette.kpNeutralColors.light
            }
          }}
        >
          <li
            {...props}
            style={numberOfDistricts > 1 && option._id && option._id === value?.districtId ? {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main
              } : undefined
            }
          >
            {option.name}
          </li>
        </Box>
      )}
      sx={{minWidth: 300}}
      size="small"
      id="accountSelect"
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={(_e, value) => handleSelectOption(value)}
      onInputChange={(e, value) => e?.type === "change" && setSearchValue(value)}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      inputValue={open ? (isReadOnly ? "" : searchValue) : value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            ".MuiInputBase-input::selection": {
              backgroundColor: "transparent"
            }
          }}
          placeholder={`Search for ${startCase(selectedGroup.slice(2))}`}
          label="Select Account"
          inputProps={{
            ...params.inputProps,
            readOnly: isReadOnly,
            value: isReadOnly ? value.name : params.inputProps.value
          }}
        />
      )}
      disableCloseOnSelect
      disableClearable
      groupBy={option => option.group}
      renderGroup={({key, group, children}) => {
        const expand = selectedGroup === group
        const optionLength = options?.filter(i => i.group === group)?.length || 0
        const isDistrictSchools = group === AccountGroups.DistrictSchools
        const title = startCase(group.slice(2))
        const hideGroup = (group === AccountGroups.Student)
          || (group === AccountGroups.Districts && optionLength === 1)
          || (group === AccountGroups.Schools && numberOfDistrictSchools === 0 && numberOfDistricts <= 1)

        return (((searchValue && expand) || (!searchValue))) && (
          <Box
            key={key}
            sx={{
              overflowX: "hidden",
              mt: hideGroup ? 0.5 : 1,
              "&:first-of-type": {
                mt: 0
              }
            }}
          >
            {(!hideGroup) && (
              <Box
                bgcolor="kpNeutralColors.light"
                onClick={() => handleSelectGroup(group as AccountGroups)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{cursor: "pointer"}}
                px={2}
                py={1}
              >
                <Typography>
                  {title}
                </Typography>
                <ExpandMoreIcon
                  sx={{
                    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
                    transition: 150
                  }}
                />
              </Box>
            )}
            <Collapse in={expand || hideGroup}>
              <Box mt={hideGroup ? 0 : 1} display="flex" flexDirection="column" gap={.5}>
                {children}
              </Box>
              {optionLength >= defaultQuantity && !searchValue && (
                <Box textAlign="center" py={1} px={2}>
                  <Typography variant="caption">
                    First {defaultQuantity} {title}. Use Search for viewing more
                  </Typography>
                </Box>
              )}
              {(hideGroup && !isDistrictSchools) && !searchValue && group !== AccountGroups.Schools && <Divider sx={{mt: .5}}/>}
            </Collapse>
          </Box>
        )
      }}
      options={open && searchValue ? searchedOptions : options}
      getOptionLabel={option => option.name}
    />
  )
}
