import {gql} from "@apollo/client"

export default gql`
  query GetClassroomInfoByJoinCode($joinCode: String!) {
    getClassroomInfoByJoinCode(joinCode: $joinCode) {
      classroomId
      classroomName
      schoolName
      districtName
      teacherNames
    }
  }
`
