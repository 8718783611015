import React, {SyntheticEvent} from "react"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Typography from "@mui/material/Typography"
import AccordionDetails from "@mui/material/AccordionDetails"
import Accordion from "@mui/material/Accordion"
import {makeStyles} from "@mui/styles"
import {IQuestionItem} from "../../index"
import MultipleAccordionDetails from "./components/MultipleAccordionDetails"
import {Box, Theme} from "@mui/material"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    "&.success": {
      color: theme.palette.success.main
    },
    "&.failed": {
      color: theme.palette.error.main
    }
  },
  accHeaderLine: ({
    backgroundColorProp
  }: {
    backgroundColorProp: string
  }) => ({
    backgroundColor: backgroundColorProp,
    color: "#172b4d"
  })
}))

export interface ICustomAccordionItemProps
  extends Omit<IQuestionItem, "correctAnswer"> {
  expanded: boolean
  handleChange: (panel: string) => (event: SyntheticEvent<Element, Event>, expanded: boolean) => void
  answerToRender: React.ReactNode
  backgroundColorProp?: string
}

export default function CustomAccordionItem({
  id,
  submitted,
  validated,
  expanded,
  handleChange,
  displayName,
  description,
  answerToRender,
  codeSnippet,
  type,
  backgroundColorProp = "#fff"
}: ICustomAccordionItemProps) {
  const {accHeaderLine, heading, secondaryHeading} = useStyles({
    backgroundColorProp
  })

  return (
    <Accordion key={id} expanded={expanded} onChange={handleChange(id)} sx={{overflow: "hidden"}}>
      <AccordionSummary
        sx={{
          ".MuiAccordionSummary-content": {
            minWidth: 0
          }
        }}
        className={accHeaderLine}
        expandIcon={
          <ExpandMoreIcon/>
        }>
        <Box display="flex" justifyContent="space-between" width="100%" minWidth={0} gap={2} pr={3}>
          <Typography className={heading} noWrap>
            {displayName}
          </Typography>
          <Typography
            minWidth={100}
            textAlign="right"
            className={`${secondaryHeading} ${!submitted ? "" : (validated ? "success" : "failed")}`}>
            {!submitted ? "Not Answered" : (validated ? "Passed" : "Failed")}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {type !== "practical" ? (
          <MultipleAccordionDetails
            description={description}
            codeSnippet={codeSnippet}
            answerToRender={answerToRender}
          />
        ) : (
          <Typography variant="overline">
            For this moment we can`t provide answer for practical questions.
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
