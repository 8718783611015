import {useLazyQuery, useMutation} from "@apollo/client"
import {FileDownload} from "@mui/icons-material"
import ADD_STAFF_TO_SCHOOL from "api/apollo/mutations/ADD_STAFF_TO_SCHOOL"
import DELETE_ADMIN_FROM_SCHOOL from "api/apollo/mutations/DELETE_ADMIN_FROM_SCHOOL"
import DELETE_STAFF_FROM_SCHOOL from "api/apollo/mutations/DELETE_STAFF_FROM_SCHOOL"
import EXPORT_STAFF_LIST from "api/apollo/queries/EXPORT_STAFF_LIST"
import GET_STAFF from "api/apollo/queries/GET_STAFF"
import {getApiCallHeaders} from "api/rest"
import Plus from "assets/icons/Plus"
import ConfirmationAlert from "components/ConfirmationAlert"
import DataTable from "components/DataTable"
import {DataTableActionButtons, DataTableQueryUpdate, DataTableState} from "components/DataTable/types.t"
import {
  AddStaffToSchoolMutation,
  AddStaffToSchoolMutationVariables,
  DeleteAdminFromSchoolMutation,
  DeleteAdminFromSchoolMutationVariables,
  DeleteStaffFromSchoolMutation,
  DeleteStaffFromSchoolMutationVariables,
  ExportStaffListQuery,
  ExportStaffListQueryVariables,
  GetStaffQuery,
  GetStaffQueryVariables,
  User
} from "generated/graphql"
import JsFileDownloader from "js-file-downloader"
import React, {useMemo, useState} from "react"
import {Box, Button, CircularProgress, Link, Switch, Typography} from "@mui/material"
import CustomModal from "components/CustomModal"
import {Link as RouterLink} from "react-router-dom"
import {useDispatch, useSelector} from "store"
import {customNotifications} from "store/slices/notifier/notificationObject"
import {handleError, notifyUser} from "store/slices/notifier/notifier"
import AddPersonnelForm, {AddStaffFormProps} from "components/AddPersonnelForm"
import {Roles} from "types/access"
import {DataTableSchema} from "components/DataTable/types.t"
import {QueryDataType} from "types/typeUtils"

type ModalTypes = "ADD" | "CREATE_CONFIRMATION" | "CREATE" | "DELETE" | "EXPORT_LOADING"

interface Props {
  districtId?: string
  schoolId?: string
  isAdminView?: boolean
}

export default function PersonnelTable({districtId, schoolId, isAdminView}: Props) {
  const dispatch = useDispatch()

  const user = useSelector(store => store.userSlice)

  const [staffData, setStaffData] = useState<QueryDataType<Partial<User>>>(null)
  const [query, setQuery] = useState<DataTableState | null>(null)
  const [modal, setModal] = useState<ModalTypes | null>(null)
  const [selectedItem, setSelectedItem] = useState<User | null>(null)
  const [adminAccessToConfirm, setAdminAccessToConfirm] = useState<Partial<User> | null>(null)
  const [revokeAdminAccessToConfirm, setRevokeAdminAccessToConfirm] = useState<Partial<User> | null>(null)

  const [staffQueryFetch, staffQuery] = useLazyQuery<
    GetStaffQuery,
    GetStaffQueryVariables
  >(GET_STAFF)

  const [addStaffToSchool] = useMutation<
    AddStaffToSchoolMutation,
    AddStaffToSchoolMutationVariables
  >(ADD_STAFF_TO_SCHOOL)

  const [deleteAdminFromSchool] = useMutation<
    DeleteAdminFromSchoolMutation,
    DeleteAdminFromSchoolMutationVariables
  >(DELETE_ADMIN_FROM_SCHOOL)

  const [deleteStaffFromSchool] = useMutation<
    DeleteStaffFromSchoolMutation,
    DeleteStaffFromSchoolMutationVariables
  >(DELETE_STAFF_FROM_SCHOOL)

  const [exportStaffList] = useLazyQuery<
    ExportStaffListQuery,
    ExportStaffListQueryVariables
  >(EXPORT_STAFF_LIST, {
    fetchPolicy: "network-only"
  })

  const isUserSchoolAdmin = useMemo(() => {
    return (user?.adminInSchoolIds || []).includes(schoolId) || user.roles.includes(Roles.Admin)
  }, [schoolId, user])

  const staffDataLoading = useMemo(() => {
    return staffQuery.loading || false
  }, [staffQuery.loading])

  const staffDataError = useMemo(() => {
    return !staffData && (staffQuery.error || null)
  }, [staffData, staffQuery.error])

  const handleSelectItemWith = (
    teacher: User,
    callback: (teacher: User) => void
  ) => {
    setSelectedItem(teacher)
    callback(teacher)
  }

  const handleCancelModal = () => {
    setModal(null)
    setSelectedItem(null)
  }

  const handleAddStaff: AddStaffFormProps["onSubmit"] = async (
    type,
    isAdmin,
    user
  ) => {
    if (type === "ADD") {
      handleCancelModal()

      if (query) {
        handleRefreshData()
      }
    } else if (type === "CREATE_CONFIRMATION") {
      setModal("CREATE_CONFIRMATION")
    } else {
      if (isAdmin) {
        await handleSetAdminRights(user, true, true)
      }

      dispatch(notifyUser({
        message: customNotifications.STAFF_ADD_SUCCESS
      }))

      handleCancelModal()

      if (query) {
        handleRefreshData()
      }
    }
  }

  const handleConfirmCreation = () => {
    setModal("CREATE")
  }

  const handleSetAdminRights = async (
    user: Partial<User>,
    value: boolean,
    confirmed: boolean = false
  ) => {
    if (value) {
      if (!confirmed) {
        setAdminAccessToConfirm(user)
      } else {
        addStaffToSchool({
          variables: {
            schoolId,
            userEmail: user.email,
            isTeacher: true,
            isAdmin: true
          }
        }).then(() => {
          if (query) {
            handleRefreshData()
          }
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    } else {
      if (!confirmed) {
        setRevokeAdminAccessToConfirm(user)
      } else {
        deleteAdminFromSchool({
          variables: {
            schoolId,
            userId: user._id
          }
        }).then(() => {
          if (query) {
            handleRefreshData()
          }
        }).catch(err => {
          dispatch(handleError(err))
        })
      }
    }
  }

  const handleDelete = () => {
    handleCancelModal()

    deleteStaffFromSchool({
      variables: {
        schoolId,
        userId: selectedItem._id
      }
    }).then(() => {
      if (query) {
        handleRefreshData()
      }
    }).catch((err) => {
      dispatch(handleError(err))
    })
  }

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "text",
        headerText: "Last Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "lastName",
        sort: "lastName"
      },
      {
        type: "text",
        headerText: "First Name",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "firstName",
        sort: "firstName"
      },
      {
        type: "text",
        headerText: "Email",
        headerNoWrap: true,
        contentWrap: "nowrap",
        fieldName: "email",
        sort: "email"
      },
      (districtId || isAdminView) && {
        type: "custom",
        headerText: isAdminView ? "Admin in districts" : "District Admin",
        headerNoWrap: true,
        content: data => {
          const isDistrictAdmin = data.adminInDistrictIds.includes(districtId)

          return isAdminView ? (
            `${data.adminInDistrictIds.length}`
          ) : (isDistrictAdmin ? "Yes" : "No")
        }
      },
      (districtId || isAdminView) && {
        type: "custom",
        headerText: "Admin in schools",
        headerNoWrap: true,
        content: data => {
          return `${data.adminInSchoolsCount}`
        }
      },
      (districtId || isAdminView) && {
        type: "custom",
        headerText: "Teacher in schools",
        headerNoWrap: true,
        content: data => {
          return `${data.teacherInSchoolsCount}`
        }
      },
      schoolId && {
        type: "custom",
        headerText: "School Admin",
        content: data => {
          const isAdmin = (data.adminInSchoolIds || []).includes(schoolId)

          return (
            <Box display="flex" alignItems="center" gap={1.5}>
              {isAdmin ? "Yes" : "No"}
              {user.roles.includes(Roles.Admin) && (
                <Switch
                  size="small"
                  edge="start"
                  color={isAdmin ? "success" : "error"}
                  checked={isAdmin}
                  onChange={e => handleSetAdminRights(data, e.target.checked)}
                  inputProps={{"aria-label": "controlled"}}
                />
              )}
            </Box>
          )
        }
      },
      {
        type: "custom",
        headerText: "Proctor",
        headerNoWrap: true,
        contentWrap: "pre",
        content: data => {
          return data.isProctor ? "Yes" : "No"
        }
      },
      {
        type: "custom",
        headerText: "",
        content: data => {
          return (
            <Box display="flex" gap={1}>
              {isUserSchoolAdmin && !districtId && data.teacherInSchoolIds.includes(schoolId) &&
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => {
                    handleSelectItemWith(data, () => {
                      setModal("DELETE")
                    })
                  }}>
                  Remove
                </Button>
              }
              <Link to={data._id} component={RouterLink} underline="none">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small">
                  View More
                </Button>
              </Link>
            </Box>
          )
        }
      }
    ]
  }, [staffData, isUserSchoolAdmin])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "addNewPersonnel",
        label: "Add New Personnel",
        variant: "outlined",
        color: "success",
        icon: <Plus/>,
        onClick: () => setModal("ADD")
      },
      isAdminView && {
        key: "exportPersonnel",
        label: "Export Personnel",
        icon: <FileDownload />,
        variant: "contained",
        onClick: handleExportStaffList
      }
    ]
  }, [isAdminView])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setQuery(state)

    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {itemsPerPage, page, sort, searchQuery} = state

    staffQueryFetch({
      fetchPolicy,
      variables: {
        isStaffInSchool: schoolId,
        isStaffInDistrict: districtId,
        take: itemsPerPage,
        offset: itemsPerPage * page,
        sortBy: sort?.key,
        order: sort?.order,
        search: searchQuery
      }
    }).then(res => {
      setStaffData(res.data?.users || null)
    })

  }

  async function handleExportStaffList() {
    const headers = await getApiCallHeaders()

    setModal("EXPORT_LOADING")

    exportStaffList().then(res => {
      const url = res.data?.exportStaffList?.url

      return new JsFileDownloader({
        url,
        headers: Object.entries(headers).map(([name, value]) => ({
          name,
          value
        })),
        filename: url.split("?")[0].split("/").pop() || "personnel.csv"
      })
    }).finally(() => {
      setModal(null)
    })
  }

  function handleRefreshData() {
    handleQuery(query, {
      refetch: true
    })
  }

  return (
    <Box>
      <DataTable
        schema={tableSchema}
        data={staffData?.items}
        loading={staffDataLoading}
        error={!!staffDataError}
        itemsTotalCount={staffData?.total}
        onQueryUpdate={handleQuery}
        search="Search"
        actionButtons={tableActionButtons}
        lastPage={!staffData?.hasMore}
      />
      <CustomModal
        open={["ADD", "CREATE_CONFIRMATION", "CREATE"].includes(modal)}
        onClose={handleCancelModal}>
        <AddPersonnelForm
          type={modal === "CREATE" ? "CREATE" : "ADD"}
          onCancel={handleCancelModal}
          onSubmit={handleAddStaff}
          schoolId={schoolId}
          districtId={districtId}
        />
      </CustomModal>
      <ConfirmationAlert
        isOpen={modal === "CREATE_CONFIRMATION"}
        setOpen={handleCancelModal}
        handleConfirm={handleConfirmCreation}
        handleCancel={handleCancelModal}
        noCloseOnSubmit
        dialogTitle="Account doesn't exist. Do you want to create a new staff account?"
        cancelButton="Cancel"
        confirmButton={{color: "success", text: "Yes"}}
      />
      <ConfirmationAlert
        isOpen={!!adminAccessToConfirm}
        setOpen={() => setAdminAccessToConfirm(null)}
        handleConfirm={() => handleSetAdminRights(adminAccessToConfirm, true, true)}
        handleCancel={() => setAdminAccessToConfirm(null)}
        dialogTitle="Assign Administrator Access?"
        dialogContentText={`Do you want to assign administrator access to ${adminAccessToConfirm?.fullName}?`}
        cancelButton="No"
        confirmButton={{color: "success", text: "Yes"}}
      />
      <ConfirmationAlert
        isOpen={!!revokeAdminAccessToConfirm}
        setOpen={() => setRevokeAdminAccessToConfirm(null)}
        handleConfirm={() => handleSetAdminRights(revokeAdminAccessToConfirm, false, true)}
        handleCancel={() => setRevokeAdminAccessToConfirm(null)}
        dialogTitle="Revoke Administrator Access?"
        dialogContentText={`Do you want to revoke the administrator access for ${revokeAdminAccessToConfirm?.fullName}?`}
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes"}}
      />
      <ConfirmationAlert
        isOpen={modal === "DELETE"}
        setOpen={handleCancelModal}
        handleConfirm={handleDelete}
        handleCancel={handleCancelModal}
        dialogTitle={
          `Are you sure you want to remove ${selectedItem?.firstName} ${selectedItem?.lastName} from your school account?`
        }
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes"}}
      />
      <CustomModal open={modal === "EXPORT_LOADING"}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mx={16} my={8}>
          <CircularProgress />
          <Typography variant="h6" align="center" mt={2}>
            Collecting data...
          </Typography>
        </Box>
      </CustomModal>
    </Box>
  )
}
