import useCookies from "hooks/useCookies"
import useMainPageLink from "hooks/useMainPageLink"
import React, {useMemo, useRef, useState} from "react"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from "@mui/material"
import useAuth from "hooks/useAuth"
import UserIcon from "assets/icons/User"
import {useDispatch, useSelector} from "store"
import {logoutThunk, setFakeGroup} from "store/slices/userSlice/userSlice"

export default function AccountPopover() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isStudent} = useMainPageLink()
  const [, setSelectedAccount] = useCookies("selectedAccount")

  const {user, logout} = useAuth()

  const allSchools = useSelector((state) => state.schoolSlice.allSchools)
  const currentSchool = useSelector((state) => state.schoolSlice.currentSchool)

  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement | null>(null)

  const currSchoolInfo = useMemo(() => {
    if (!currentSchool || !allSchools) return

    return allSchools.find((el) => el.id === currentSchool)
  }, [currentSchool, allSchools])

  const [firstName, lastName, email] = useMemo(() => {
    return [
      user?.firstName,
      user?.lastName,
      user?.email
    ]
  }, [user])

  const avatar = useMemo(() => {
    if (isStudent) {
      return user?.photoUrl || ""
    } else {
      return currSchoolInfo?.logo || ""
    }
  }, [user, isStudent, currSchoolInfo])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      setSelectedAccount(null)
      dispatch(logoutThunk())
      dispatch(setFakeGroup(null))
      await logout()
      navigate("/authentication/login")
    } catch (err) {
      navigate("/authentication/login")
    }
  }

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex"
        }}>
        <Avatar
          src={avatar}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom"
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {width: 240}
        }}>
        <Box sx={{p: 2}}>
          <Typography color="textPrimary" variant="subtitle2">
            {[firstName, lastName].filter((_) => _).join(" ")}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {email}
          </Typography>
        </Box>
        <Box>
          <MenuItem component={RouterLink} to="/account">
            <ListItemIcon>
              <UserIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Account
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box p={2}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  )
}
