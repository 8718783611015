import {AppBar, AppBarProps} from "@mui/material"
import {Box, IconButton, Toolbar, useTheme} from "@mui/material"
import MenuIcon from "assets/icons/Menu"
import AccountPopover from "components/AccountPopover"
import AccountSelect from "components/Header/components/AccountSelect"
import useAuth from "hooks/useAuth"
import useMainPageLink from "hooks/useMainPageLink"
import React, {Fragment, useEffect, useState} from "react"
import {Link as RouterLink} from "react-router-dom"
import {useSelector} from "store"
import KPFullLogo from "assets/icons/KPFullLogo"

interface Props extends AppBarProps {
  onSidebarMobileOpen?: () => void
}

export default function Header({
  onSidebarMobileOpen
}: Props) {
  const theme = useTheme()
  const {mainPageLink} = useMainPageLink()
  const {isAuthenticated} = useAuth()
  const {id} = useSelector((state) => state.userSlice)
  const [hasScrolled, setHasScrolled] = useState(false)

  const handleScroll = () => {
    setHasScrolled(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.white.main,
        boxShadow: hasScrolled ? "0 1px 7px rgba(57, 63, 72, 0.2)" : "none",
        transition: "box-shadow 0.3s ease"
      }}
    >
      <Toolbar
        sx={{
          height: {xs: 64, md: 80},
          width: {xs: "100%"},
          px: {xs: 2, md: 4}
        }}
      >
        <Box sx={{
          display: {xs: isAuthenticated ? "none" : "flex", lg: "flex"}
        }}>
          <RouterLink to={mainPageLink}>
            <Box sx={{width: "100%", height: {xs: "24px", md:"32px"}}}>
              <KPFullLogo fullWidth={false}/>
            </Box>
          </RouterLink>
        </Box>
        {isAuthenticated && (
          <Fragment key={`user - ${id}`}>
            <IconButton
              sx={{
                display: {lg: "none"}
              }}
              color="primary"
              onClick={onSidebarMobileOpen}
              size="large">
              <MenuIcon fontSize="small"/>
            </IconButton>
            <Box flexGrow={1} ml={2}/>
            <Box display="flex" alignItems="center" gap={3} ml={2}>
              <AccountSelect/>
              <AccountPopover/>
            </Box>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  )
}

