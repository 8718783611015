import {gql} from "@apollo/client"

export default gql`
  mutation CreateDisabilityMultiplierRequests(
    $requests: [DisabilityMultiplierRequestInput!]!
    $proofFileUpload: Upload
  ) {
    createDisabilityMultiplierRequests(
      requests: $requests
      proofFileUpload: $proofFileUpload
    ) {
      _id
    }
  }
`
