import createSvgIcon from "@mui/material/utils/createSvgIcon"

const LoginBottomCurvedLineMobile = createSvgIcon(
  <svg width="100%" height="100%" viewBox="0 0 108 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M56.8554 30.6414C38.9566 34.8864 20.1692 46.3974 8.30858 69.0717C10.7997 71.1128 11.7773 73.525 11.9546 74.4759C22.9212 51.4523 41.0955 40.2132 58.1654 36.1648C63.4193 34.9187 68.5644 34.3557 73.3611 34.3419C72.6749 35.8919 72.081 37.4821 71.6222 39.0975C69.9715 44.9089 70.0245 51.2483 73.9721 57.1484C78.644 64.1311 88.169 65.8208 95.3857 63.4688C99.0964 62.2594 102.518 59.903 104.562 56.2963C106.633 52.6403 107.088 48.0515 105.481 42.8163C104.517 39.6772 102.162 37.0839 99.1842 35.0653C96.1843 33.0319 92.35 31.4375 88.0125 30.3502C86.3054 29.9223 84.5059 29.5693 82.6296 29.2989C83.1851 28.4475 83.7553 27.6104 84.3307 26.7906C88.5484 20.7819 97.0048 12.5905 107.315 8.39502C117.449 4.27114 129.292 4.0017 141.044 13.5002C142.263 14.4856 144.05 14.2962 145.035 13.0771C146.021 11.858 145.831 10.0709 144.612 9.08553C130.981 -1.93262 116.875 -1.62398 105.175 3.13713C93.6514 7.82659 84.3889 16.8272 79.6845 23.5293C78.553 25.1412 77.3933 26.8825 76.2972 28.7212C70.2174 28.472 63.5963 29.0427 56.8554 30.6414ZM77.0827 40.6485C77.6559 38.6306 78.4982 36.6144 79.5068 34.6352C82.0455 34.8904 84.4356 35.3058 86.6323 35.8565C90.505 36.8272 93.6838 38.1946 95.9991 39.7641C98.3363 41.3483 99.5989 42.9991 100.054 44.4823C101.285 48.4917 100.798 51.4244 99.6228 53.4981C98.4199 55.6212 96.2919 57.203 93.6267 58.0716C88.0913 59.8757 81.5598 58.2811 78.69 53.9918C75.9196 49.851 75.7474 45.3497 77.0827 40.6485Z"
          fill="#2092C0"/>
    <path
      d="M0.08522 91.0731C-0.294632 92.5939 0.630295 94.1347 2.15111 94.5146C3.67192 94.8945 5.21272 93.9695 5.59259 92.4487C6.44308 89.0436 7.42187 85.8189 8.51511 82.7669L2.09712 84.0123C1.36623 86.2825 0.694107 88.6354 0.08522 91.0731Z"
      fill="#2092C0"/>
    <path
      d="M11.3411 75.793C11.543 75.3497 11.7476 74.9107 11.9546 74.4759C11.7773 73.525 10.7997 71.1128 8.30858 69.0717C5.94148 73.5969 3.85028 78.5668 2.09712 84.0123L8.51511 82.7669C9.3867 80.3337 10.331 78.0104 11.3411 75.793Z"
      fill="#2092C0"/>
  </svg>,
  "CurvedLineMobile"
)

export default LoginBottomCurvedLineMobile
