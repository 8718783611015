import {gql} from "@apollo/client"

export default gql`
  query GetExamCodes(
    $sortBy: String = "displayCode"
    $order: SortOrder = asc
    $offset: Int = 0
    $take: Int = 100
    $search: String
  ) {
    exams(
      search: $search,
      sortBy: $sortBy,
      order: $order,
      offset: $offset,
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        code
        displayCode
        displayName {
          en
          value
        }
      }
    }
  }
`
