import {useApolloClient, useLazyQuery, useQuery} from "@apollo/client"
import {Box, Divider, Typography, Grid, CircularProgress} from "@mui/material"
import {createStyles, makeStyles} from "@mui/styles"
import GET_DISTRICT_SCHOOL_IDS from "api/apollo/queries/GET_DISTRICT_SCHOOL_IDS"
import GET_EXAM_CODES from "api/apollo/queries/GET_EXAM_CODES"
import GET_EXAM_PASSING_RATE from "api/apollo/queries/GET_EXAM_PASSING_RATE"
import GET_SCHOOL_IDS from "api/apollo/queries/GET_SCHOOL_IDS"
import DateFilter from "components/DateFilter"
import SelectSingleFilter from "components/SelectSingleFilter"
import {
  AveragePassingRateResponse, GetDistrictSchoolIdsQuery, GetDistrictSchoolIdsQueryVariables,
  GetExamCodesQuery,
  GetExamPassingRateQuery,
  GetExamPassingRateQueryVariables, GetSchoolIdsQuery, GetSchoolIdsQueryVariables, School
} from "generated/graphql"
import React, {useEffect, useState} from "react"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"

const useStyles = makeStyles(() => createStyles({
  filterContainer: {
    "& > div > div": {
      width: "fit-content",
      minWidth: "58.075%"
    }
  }
}))

type ExamPassingRate = AveragePassingRateResponse & {
  name: string
}

interface ExamCode {
  code: string
  name: string
}

type DateRange = [number | null, number | null] | null

interface Filters {
  dateStart?: string
  dateEnd?: string
  schoolId?: string
}

interface Props {
  districtId?: string
  type?: "admin" | "district"
}

export default function ExamPassingRateTable({
  districtId,
  type
}: Props) {
  const client = useApolloClient()
  const dispatch = useDispatch()
  const s = useStyles()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ExamPassingRate[]>([])
  const [examCodes, setExamCodes] = useState<ExamCode[] | null>(null)
  const [filters, setFilters] = useState<Filters | null>(null)
  const [schoolIdsData, setSchoolIdsData] = useState<
    Partial<School>[] | null
  >(null)

  const examCodesQuery = useQuery<
    GetExamCodesQuery,
    GetExamCodesQuery
  >(GET_EXAM_CODES)

  const [districtSchoolIdsQueryFetch, districtSchoolIdsQuery] = useLazyQuery<
    GetDistrictSchoolIdsQuery,
    GetDistrictSchoolIdsQueryVariables
  >(GET_DISTRICT_SCHOOL_IDS)

  const [schoolIdsQueryFetch, schoolIdsQuery] =  useLazyQuery<
    GetSchoolIdsQuery,
    GetSchoolIdsQueryVariables
  >(GET_SCHOOL_IDS)

  useEffect(() => {
    if (!examCodesQuery.loading) {
      if (examCodesQuery.data?.exams) {
        const currentExamCodes = examCodesQuery.data.exams.items.map(i => ({
          code: i.code,
          name: `${i.displayCode} - ${i.displayName.value}`
        }))

        setExamCodes(currentExamCodes)
        type !== "district" && getExamPassingRate(currentExamCodes)

      } else {
        setLoading(false)
      }
    }
  }, [examCodesQuery.data])

  const getExamPassingRate = (examCodes: ExamCode[], filters?: Filters) => {
    if ((type === "district" && filters?.schoolId) || type !== "district") {
      Promise.allSettled(examCodes.map(i => {
        return client.query<
          GetExamPassingRateQuery,
          GetExamPassingRateQueryVariables
        >({
          query: GET_EXAM_PASSING_RATE,
          variables: {
            examCode: i.code,
            dateStart: filters?.dateStart,
            dateEnd: filters?.dateEnd,
            schoolId: filters?.schoolId || undefined
          }
        })
      })).then(res => {
        const examStatistics = [] as ExamPassingRate[]
        res.map((i, ind) => {
          if (i.status === "fulfilled") {
            const getExamPassingRate = i.value.data.getExamPassingRate
            if (getExamPassingRate) {
              examStatistics.push({
                ...getExamPassingRate,
                name: examCodes[ind].name
              })
            }
          }
          return i
        })
        setData(examStatistics)
      }).catch(err => {
        dispatch(handleError(err))
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  const roundNumber = (value: number) => {
    return Math.round(value * 100)
  }

  const handleChangeFilter = (
    value: string | DateRange, id: "date" | "schoolId"
  ) => {
    let currentFilters = filters

    if (id === "date") {
      currentFilters = {
        ...currentFilters,
        dateStart: value?.[0] ? new Date(value[0]).toISOString() : undefined,
        dateEnd: value?.[1] ? new Date(value[1]).toISOString() : undefined
      }
    }
    if (id === "schoolId") {
      currentFilters = {
        ...currentFilters,
        schoolId: value as string
      }
    }

    setFilters(currentFilters)
    getExamPassingRate(examCodes, currentFilters)
  }

  const handleQuerySchoolIds = (initUpdate?: boolean) => {
    if (districtId) {
      districtSchoolIdsQueryFetch({
        variables: {
          districtId
        }
      }).then(res => {
        setSchoolIdsData(res.data?.getDistrict?.schools?.items || null)
        if (initUpdate && res.data?.getDistrict?.schools?.items?.length) {
          handleChangeFilter(res.data?.getDistrict?.schools?.items[0]._id, "schoolId")
        }
      })
    } else {
      schoolIdsQueryFetch().then(res => {
        setSchoolIdsData(res.data?.schools?.items || null)
      })
    }
  }

  useEffect(() => {
    if (type === "district" && districtId && examCodes) {
      handleQuerySchoolIds(true)
    }
  }, [type, districtId, examCodes])

  return (
    <Box minHeight="40vh">
      {!loading && examCodes && (
        <Box mb={3}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item xs={6}>
              <Box className={s.filterContainer}>
                <DateFilter
                  onChange={(value) => handleChangeFilter(value, "date")}
                  label="Date"
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={s.filterContainer}>
                <SelectSingleFilter
                  options={
                    type === "district" ? (
                      [
                        ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
                          label: i.name,
                          value: i._id
                        }))
                      ]
                    ) : (
                      [
                        {label: "All schools", value: ""},
                        ...(schoolIdsData || []).filter(i => Boolean(i._id)).map(i => ({
                          label: i.name,
                          value: i._id
                        }))
                      ]
                    )}
                  id="schoolId"
                  label="School"
                  onChange={(value) => handleChangeFilter(value, "schoolId")}
                  onUpdateOptions={() => handleQuerySchoolIds()}
                  loadOnce={true}
                  loading={districtSchoolIdsQuery.loading || schoolIdsQuery.loading}
                  initialValue={type === "district" && schoolIdsData?.length && schoolIdsData[0]._id}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {loading || (type === "district" && districtSchoolIdsQuery.loading) ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="40vh">
          <CircularProgress/>
        </Box>
      ) : !data?.length ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="40vh">
          <Typography variant="h5">
            No Results Available
          </Typography>
        </Box>
      ) : (
        <Box>
          {data.map((i, num, {length}) => (
            <Box key={i.name} mb={3} p={1}>
              <Box mb={3}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  mb={2}>
                  {i.name}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Total Exam Sessions: {i.totalSessions}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Average Passing Rate: {roundNumber(i.averagePassingRate)}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Total Students: {i.totalUsers}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      First Attempt: {roundNumber(i.usersPassedAfterAttempt1 / i.totalUsers)}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Passed: {i.totalPassedUsers}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Second Attempt: {roundNumber(i.usersPassedAfterAttempt2 / i.totalUsers)}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Failed: {i.totalUsers - i.totalPassedUsers}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      Third Attempt: {roundNumber(i.usersPassedAfterAttempt3 / i.totalUsers)}%
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {length - 1 !== num && (
                <Divider/>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
