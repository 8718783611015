import * as Sentry from "@sentry/react"
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux"
import type {TypedUseSelectorHook} from "react-redux"
import {configureStore} from "@reduxjs/toolkit"
import thunk from "redux-thunk"

import rootReducer from "./rootReducer"

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(thunk),
  enhancers: [Sentry.createReduxEnhancer()]
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const useDispatch = () => useReduxDispatch<AppDispatch>()

export default store
