import {useLazyQuery} from "@apollo/client"
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1"
import PersonRemoveIcon from "@mui/icons-material/PersonRemove"
import {Box, IconButton, Tooltip} from "@mui/material"
import GET_CLASSROOM_TEACHERS from "api/apollo/queries/GET_CLASSROOM_TEACHERS"
import ConfirmationAlert from "components/ConfirmationAlert"
import CustomModal from "components/CustomModal"
import DataTable from "components/DataTable"
import {
  DataTableActionButtons,
  DataTableQueryUpdate,
  DataTableSchema,
  DataTableState
} from "components/DataTable/types.t"

import {Classroom, GetClassroomTeachersQuery, GetClassroomTeachersQueryVariables, User} from "generated/graphql"
import React, {useMemo, useState} from "react"
import {useDispatch} from "store"
import {updateTeacherInClassThunk} from "store/slices/schoolSlice/schoolSlice"
import AddTeacherForm from "./components/AddTeacherForm"

interface Props {
  classroom?: DeepPartial<Classroom>
  onClassroomUpdate: () => void
  loading: boolean
}

export default function TeachersTable({
  classroom,
  onClassroomUpdate,
  loading
}: Props) {
  const dispatch = useDispatch()

  const [query, setQuery] = useState<DataTableState | null>(null)
  const [showModal, setShowModal] = useState(false)

  const [teachersData, setTeachersData] = useState<Partial<User>[] | null>(null)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [teacherToRemove, setTeacherToRemove] = useState<User | null>(null)

  const [teachersQueryFetch, teachersQuery] = useLazyQuery<
    GetClassroomTeachersQuery,
    GetClassroomTeachersQueryVariables
  >(GET_CLASSROOM_TEACHERS)

  const filteredTeachers = useMemo(() => {
    if (!teachersData) {
      return []
    } else {
      return teachersData.filter(i => {
        if (!searchQuery) {
          return true
        } else {
          return i.firstName.toLowerCase().includes(searchQuery.toLowerCase()) || (
            i.lastName.toLowerCase().includes(searchQuery.toLowerCase())
          )
        }
      })
    }
  }, [teachersData, searchQuery])

  const tableSchema: DataTableSchema<User> = useMemo(() => {
    return [
      {
        type: "custom",
        headerText: "Name",
        content: data => {
          return `${data.lastName} ${data.firstName}`
        }
      },
      {
        type: "text",
        headerText: "Email",
        fieldName: "email"
      },
      {
        type: "custom",
        headerText: "Actions",
        content: data => (
          <Tooltip title="Remove teacher from classroom">
            <span>
              <IconButton size="small" onClick={() => setTeacherToRemove(data)}>
                <PersonRemoveIcon fontSize="small"/>
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    ]
  }, [])

  const tableActionButtons: DataTableActionButtons = useMemo(() => {
    return [
      {
        key: "createClassroom",
        label: "Add Teachers",
        icon: <PersonAddAlt1Icon/>,
        variant: "outlined",
        color: "success",
        onClick: () => setShowModal(true)
      }
    ]
  }, [])

  const handleQuery: DataTableQueryUpdate<{
    refetch?: boolean
  }> = (state, options) => {
    setQuery(state)
    const fetchPolicy = options?.refetch ? "network-only" : undefined
    const {searchQuery} = state

    teachersData && setSearchQuery(searchQuery)

    if (classroom) {
      teachersQueryFetch({
        fetchPolicy,
        variables: {
          classroomId: classroom._id
        }
      }).then(res => {
        const data = [...res.data?.getClassroom?.teachers || []]

        setTeachersData(data.sort((a, b) => {
          return (`${a.lastName} ${a.firstName}`).localeCompare(`${b.lastName} ${b.firstName}`)
        }))
      })
    }
  }

  const handleManageTeacher = (action: "ADD" | "REMOVE", id: string) => {
    dispatch(updateTeacherInClassThunk({
      teacherId: id,
      classroomId: classroom._id,
      action
    })).then(() => {
      handleQuery(query, {refetch: true})
      onClassroomUpdate()
    })
  }

  return (
    <Box minHeight="20vh">
      <DataTable
        schema={tableSchema}
        data={filteredTeachers}
        loading={teachersQuery.loading}
        error={!!teachersQuery.error}
        emptyDataMessage="No teachers found"
        onQueryUpdate={handleQuery}
        search="Search Teachers"
        actionButtons={tableActionButtons}
      />
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <AddTeacherForm
          classroom={classroom}
          onManageTeacher={handleManageTeacher}
          loading={loading || teachersQuery.loading}
        />
      </CustomModal>
      <ConfirmationAlert
        isOpen={!!teacherToRemove}
        setOpen={() => setTeacherToRemove(null)}
        handleConfirm={() => handleManageTeacher("REMOVE", teacherToRemove?._id)}
        handleCancel={() => setTeacherToRemove(null)}
        dialogTitle={
          `Are you sure you want to remove ${teacherToRemove?.firstName} ${teacherToRemove?.lastName} from the classroom?`
        }
        cancelButton="No"
        confirmButton={{color: "error", text: "Yes, remove"}}
      />
    </Box>
  )
}
