import {gql} from "@apollo/client"

export default gql`
  query ExportExamSessions(
    $districtId: GraphQLObjectId 
    $schoolId: GraphQLObjectId
    $examCodes: [String!]
    $userId: GraphQLObjectId
    $dateStart: DateTimeISO
    $testingGroupId: GraphQLObjectId
    $dateEnd: DateTimeISO
    $showCanceled: Boolean
    $isPassed: Boolean
    $voucherUsed: Boolean
    $classroomId: GraphQLObjectId
  ) {
    exportExamSessions(
      districtId: $districtId
      schoolId: $schoolId
      examCodes: $examCodes
      userId: $userId
      dateStart: $dateStart
      testingGroupId: $testingGroupId
      dateEnd: $dateEnd
      showCanceled: $showCanceled
      isPassed: $isPassed
      voucherUsed: $voucherUsed
      classroomId: $classroomId
    ) {
      url
    }
  }
`
