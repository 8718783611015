import {gql} from "@apollo/client"

export default gql`
  query GetInvitations(
    $status: InvitationStatus
    $classroomId: GraphQLObjectId
    $schoolId: GraphQLObjectId
    $userId: GraphQLObjectId
    $targetEntity: InvitationTarget
    $createdByUserId: GraphQLObjectId
    $createdAfter: DateTimeISO
    $search: String
    $sortBy: String
    $order: SortOrder
    $offset: Int! = 0
    $take: Int! = 100
  ) {
    invitations(
      status: $status
      classroomId: $classroomId
      schoolId: $schoolId
      userId: $userId
      targetEntity: $targetEntity
      createdAfter: $createdAfter
      createdByUserId: $createdByUserId
      search: $search
      sortBy: $sortBy
      order: $order
      offset: $offset
      take: $take
    ) {
      total
      hasMore
      items {
        _id
        createdAt
        updatedAt
        status
        decidedAt
        user {
          _id
          cognitoUserId
          firstName
          lastName
          email
          username
        }
        createdByUserId
        comment
        classroomId
        classroom {
          _id
          name
          school {
            _id
            name
            remainingLicenseSeats {
              coursesRemainTotal
              examsRemainTotal
              labsRemainTotal
              practicesRemainTotal
            }
          }
        }
      }
    }
  }
`
