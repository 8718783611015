export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE"
}

export const kpPrimaryColors = {
  lightBlue: "#2092C0",
  blue: "#002855",
  darkBlue: "#031830",
  kpYellow: "#FFC42A",
  oldBlue: "#10355F"
}

export const kpNeutralsColors = {
  lightGray: "#777777",
  darkGray: "#151515",
  contrastGray: "#EBEBEB"
}

export const kpSecondaryColors = {
  lightBlue: "#8093AA",
  lightGrey: "rgba(12, 39, 82, 0.2)",
  purple: "#ac82ba",
  green: "#93bb45",
  orange: "#db922f"
}

export const kpEmail = "support@knowledge-pillars.com"
export const kpInfoEmail = "info@knowledge-pillars.com"
export const kpPhoneNumber = "+1 (801) 610-9295"

export const headerHeight = 80
export const footerHeight = 124
export const mobileHeaderHeight = 64
export const sidebarWidth = 290

export const maxWidthPDF = 1200

export const classroomExams = [""]
