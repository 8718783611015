import {gql} from "@apollo/client"

export default gql`
  mutation RespondDisabilityMultiplierRequests(
    $approved: Boolean!
    $rejectionReason: String
    $disabilityMultiplierRequestIds: [GraphQLObjectId]
  ) {
    respondDisabilityMultiplierRequests(
      approved: $approved
      rejectionReason: $rejectionReason
      disabilityMultiplierRequestIds: $disabilityMultiplierRequestIds
    )
  }
`
