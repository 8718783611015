import {format} from "date-fns"
import {RootState} from "store"
import {
  ILicenseItem,
  ISelectOptionTeacher
} from "types/common"

interface ISelectorFunction<T> {
  (state: RootState): T
}

export const getLicensesSelector: ISelectorFunction<ILicenseItem[]> = (state) => {
  const licensesDraft = state.schoolSlice.licenses

  if (!licensesDraft?.length) return null

  return licensesDraft.map((license) => {
    let examCodesToSet = license?.examCodes || []

    return {
      ...license,
      examCodes: examCodesToSet,
      code: license?.code ? license.code.toString() : "",
      expirationDate: license.expirationDate
        ? format(new Date(license.expirationDate), "dd MMM yyyy")
        : ""
    }
  })
}

export const selectTeachersAndProctorsForSelectOptions: ISelectorFunction<{
  teachersOptions: ISelectOptionTeacher[]
  proctorOptions: ISelectOptionTeacher[]
}> = (state) => {
  const teachers = state.schoolSlice.staff

  if (!teachers?.length)
    return {
      proctorOptions: null,
      teachersOptions: null
    }

  const serializedProctors: ISelectOptionTeacher[] = []
  const serializedTeachers: ISelectOptionTeacher[] = []

  teachers.forEach((item) => {
    const teacherOptionObject: ISelectOptionTeacher = {
      label: item.email,
      value: item.cognitoUserId
    }

    if (item.isProctor) {
      serializedProctors.push(teacherOptionObject)
    }

    serializedTeachers.push(teacherOptionObject)
  })

  return {
    proctorOptions: serializedProctors,
    teachersOptions: serializedTeachers
  }
}
