import client from "api/apollo"
import GET_CLASSROOM_STUDENTS from "api/apollo/queries/GET_CLASSROOM_STUDENTS"
import GET_SCHOOL from "api/apollo/queries/GET_SCHOOL"
import GET_SCHOOL_STAFF from "api/apollo/queries/GET_SCHOOL_STAFF"
import GET_SCHOOL_STUDENTS from "api/apollo/queries/GET_SCHOOL_STUDENTS"
import GET_TESTING_GROUPS from "api/apollo/queries/GET_TESTING_GROUPS"
import apiCall from "api/rest"
import {ENDPOINT} from "consts/endpoints"
import {
  GetClassroomStudentsQuery,
  GetClassroomStudentsQueryVariables,
  GetSchoolQuery,
  GetSchoolQueryVariables,
  GetSchoolStaffQuery,
  GetSchoolStaffQueryVariables,
  GetSchoolStudentsQuery,
  GetSchoolStudentsQueryVariables,
  GetTestingGroupsQuery,
  GetTestingGroupsQueryVariables
} from "generated/graphql"
import {IStudentItem, ITeacherItem} from "types/common"
import {
  IAddSchoolServiceMethods,
  IDeleteSchoolServiceMethods,
  IFetchSchoolServiceMethods,
  ISchoolService,
  IUpdateSchoolServiceMethods
} from "types/services/school.service.t"

const FetchSchoolServiceMethods: IFetchSchoolServiceMethods = {
  fetchSchoolInfo: async (schoolId) => {
    const {data} = await client.query<
      GetSchoolQuery,
      GetSchoolQueryVariables
    >({
      query: GET_SCHOOL,
      fetchPolicy: "network-only",
      variables: {
        schoolId
      }
    })

    return data?.getSchool
  },
  fetchTestingGroups: async ({schoolId, showArchived}) => {
    const {data} = await client.query<
      GetTestingGroupsQuery,
      GetTestingGroupsQueryVariables
    >({
      query: GET_TESTING_GROUPS,
      fetchPolicy: "network-only",
      variables: {
        schoolId,
        includeArchived: showArchived
      }
    })

    return data?.testingGroups?.items
  },
  fetchTestingGroupById: (groupId) => {
    return apiCall({
      url: `${ENDPOINT.testingGroups}/${groupId}`,
      method: "GET"
    })
  },
  fetchSchoolStaff: async (schoolId) => {
    const {data} = await client.query<GetSchoolStaffQuery, GetSchoolStaffQueryVariables>({
      query: GET_SCHOOL_STAFF,
      fetchPolicy: "network-only",
      variables: {
        schoolId
      }
    })

    return data.getSchool.staff.items as unknown as ITeacherItem[]
  },
  fetchSchoolStudents: async (schoolId) => {
    const {data} = await client.query<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>({
      query: GET_SCHOOL_STUDENTS,
      fetchPolicy: "network-only",
      variables: {
        schoolId,
        take: 100,
        offset: 0
      }
    })

    return {
      data: data.users.items as unknown as IStudentItem[]
    }
  },
  fetchLicenses: (schoolId) => {
    return apiCall({
      url: `${ENDPOINT.license}?schoolId=${schoolId}`,
      method: "GET"
    })
  },
  fetchClassroomStudents: async ({classroomId}) => {
    const {data} = await client.query<GetClassroomStudentsQuery, GetClassroomStudentsQueryVariables>({
      query: GET_CLASSROOM_STUDENTS,
      fetchPolicy: "network-only",
      variables: {
        classroomId,
        offset: 0,
        take: 100
      }
    })

    return data.users.items as IStudentItem[]
  }
}

const AddSchoolServiceMethods: IAddSchoolServiceMethods = {
  createTestingGroup: (testingGroup) => {
    return apiCall({
      url: `${ENDPOINT.testingGroups}`,
      method: "POST",
      data: testingGroup
    })
  }
}

const DeleteSchoolServiceMethods: IDeleteSchoolServiceMethods = {
  deleteStudent: (studentId: string) => {
    return apiCall({
      url: `${ENDPOINT.students}/${studentId}`,
      method: "DELETE"
    })
  },

  deleteTestingGroup: (groupId: string) => {
    return apiCall({
      url: `${ENDPOINT.testingGroups}/${groupId}/delete`,
      method: "POST"
    })
  }
}

const UpdateSchoolServiceMethods: IUpdateSchoolServiceMethods = {
  updateStudentPassword: ({
    password,
    studentId
  }: {
    password: string
    studentId: string
  }) => {
    return apiCall({
      url: `${ENDPOINT.students}/${studentId}/password`,
      method: "PUT",
      data: {
        password
      }
    })
  },
  updateTestingGroup: ({updatedTestingGroup, id}) => {
    return apiCall({
      url: `${ENDPOINT.testingGroups}/${id}`,
      method: "PATCH",
      data: updatedTestingGroup
    })
  },
  updateStudentInClass: ({studentId, classroomId, action}) => {
    return apiCall({
      url: `${ENDPOINT.classrooms}/${classroomId}/students`,
      method: "PUT",
      data: {
        studentId,
        action
      }
    })
  },
  updateTeacherInClass: ({teacherId, classroomId, action}) => {
    return apiCall({
      url: `${ENDPOINT.classrooms}/${classroomId}/teachers`,
      method: "PUT",
      data: {
        teacherId,
        action
      }
    })
  },
  updateTestingGroupStatus: ({id, status}) => {
    return apiCall({
      url: `/testing-groups/${id}/${status}`,
      method: "PATCH"
    })
  },
  updateTestingGroupArchive: ({groupId, archive}) => {
    return apiCall({
      url: `${ENDPOINT.testingGroups}/${groupId}/archive?archived=${archive}`,
      method: "POST"
    })
  }
}

const SchoolService: ISchoolService = {
  ...FetchSchoolServiceMethods,
  ...AddSchoolServiceMethods,
  ...DeleteSchoolServiceMethods,
  ...UpdateSchoolServiceMethods
}

export default SchoolService
