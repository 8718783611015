import {gql} from "@apollo/client"

export default gql`
  mutation DeleteAdminFromSchool(
    $schoolId: GraphQLObjectId!
    $userId: GraphQLObjectId!
  ) {
    deleteAdminFromSchool(
      schoolId: $schoolId
      userId: $userId
    ) {
      _id
    }
  }
`
