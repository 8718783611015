import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
  Typography
} from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import {createStyles, makeStyles} from "@mui/styles"
import cn from "classnames"
import {SortOrder} from "generated/graphql"
import {uniqBy} from "lodash"
import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react"
import ActionsCell from "./components/ActionsCell"
import ActionsMenu from "./components/ActionsMenu"
import AvatarCell from "./components/AvatarCell"
import CheckboxCell from "./components/CheckboxCell"
import DefaultCell from "./components/DefaultCell"
import Search from "./components/Search"
import SelectSingleFilter from "./components/SelectSingleFilter"
import ToggleFilter from "./components/ToggleFilter"
import DateFilter from "./components/DateFilter"
import {
  DataTableActionCol,
  DataTableAvatarCol,
  DataTableColConfig,
  DataTableCustomCol,
  DataTableFilter,
  DataTableProps,
  DataTableSelectedList,
  DataTableState,
  DataTableTextCol,
  isInteractiveHeaderText
} from "./types.t"

const useStyles = makeStyles(() => createStyles({
  scrollableMarker: {
    position: "absolute",
    width: 80,
    height: "100%",
    top: 0,
    right: 0,
    background: "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
    pointerEvents: "none",
    opacity: 0,
    transition: ".3s"
  },
  visible: {
    opacity: 1,
    transition: ".3s"
  },
  paginationRow: {
    "& .MuiTablePagination-spacer": {
      display: "none"
    }
  },
  additionalFilterContainer: {
    "& > div > div": {
      width: "fit-content",
      minWidth: "58.075%"
    }
  }
}))

export default function DataTable({
  schema,
  data,
  loading,
  loadingMessage,
  error,
  rowsPerPageLimit,
  itemsTotalCount,
  lastPage,
  onQueryUpdate,
  onSelectionUpdate,
  fullWidth,
  noWrap,
  headerNoWrap,
  contentNoWrap,
  emptyCondition,
  emptyDataMessage = "No Results",
  defaultSort,
  search,
  filters,
  actionButtons,
  actions
}: DataTableProps) {
  const defaultState: DataTableState = useMemo(() => {
    return {
      itemsPerPage: rowsPerPageLimit < 50 ? rowsPerPageLimit : 50,
      page: 0,
      filters: null,
      sort: null,
      searchQuery: null
    }
  }, [rowsPerPageLimit])
  const defaultSelected: DataTableSelectedList = null

  const s = useStyles()

  const [state, setState] = useState<DataTableState>({
    ...defaultState,
    ...(!!defaultSort ? {sort: getSortOption(defaultSort)} : {})
  })
  const [selected, setSelected] = useState<DataTableSelectedList>(defaultSelected)
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
  const [filtersKey, setFiltersKey] = useState("INITIAL")
  const [list, setList] = useState<typeof data>(data)
  const [total, setTotal] = useState<typeof itemsTotalCount>(itemsTotalCount)
  const [scrollData, setScrollData] = useState<{
    position: number
    width: number
  } | null>(null)

  const rootTopGhostRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const showScrollableMarker = useMemo(() => {
    if (scrollData) {
      const offset = 20

      return (scrollData.width - offset) > scrollData.position
    } else {
      return false
    }
  }, [containerRef, scrollData])

  const rowsPerPageOptions = useMemo(() => {
    return [5, 10, 25, 50, 100].filter(i => i <= (rowsPerPageLimit || 100))
  }, [rowsPerPageLimit])

  const mainFilters = useMemo(() => {
    const target = (filters?.main || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const secondaryFilters = useMemo(() => {
    const target = (filters?.secondary || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const additionalFilters = useMemo(() => {
    const target = (filters?.additional || []).filter(Boolean)

    return target.length ? target : null
  }, [filters, state])

  const filtersResettable = useMemo(() => {
    return Boolean(filters?.resettable !== false && (mainFilters || secondaryFilters || additionalFilters))
  }, [filters, mainFilters, secondaryFilters, additionalFilters, state])

  const resetFiltersDisabled = useMemo(() => {
    return Boolean(state.filters === null)
  }, [filtersResettable, filters, mainFilters, secondaryFilters, additionalFilters, state])

  const actionButtonsList = useMemo(() => {
    const target = (actionButtons || []).filter(Boolean)

    return target.length ? target : null
  }, [actionButtons, selected])

  const actionsList = useMemo(() => {
    const target = (actions?.items || []).filter(Boolean)

    return target.length ? target : null
  }, [actions, selected])

  const isHeadExist = useMemo(() => {
    return !!search || (mainFilters || secondaryFilters || additionalFilters) || actionButtonsList || actionsList
  }, [search, mainFilters, secondaryFilters, additionalFilters, actionButtonsList, actionsList])

  const searchLabel = useMemo(() => {
    return (typeof search === "string" ? search : typeof search === "object" ? search.label : "")
  }, [search])

  useEffect(() => {
    if (!!data) {
      setList(data)
      setSelected(defaultSelected)
    }
  }, [data])

  useEffect(() => {
    if (!!data) {
      setTotal(itemsTotalCount)
    }
  }, [itemsTotalCount, data])

  useEffect(() => {
    if (!search) {
      setState(current => ({
        ...current,
        searchQuery: defaultState.searchQuery
      }))

      setSelected(defaultSelected)
    }
  }, [search])

  useEffect(() => {
    if (filtersKey !== "INITIAL") {
      setState(current => ({
        ...current,
        page: defaultState.page,
        filters: defaultState.filters
      }))
    }
  }, [filtersKey])

  useEffect(() => {
    if (!!onQueryUpdate) {
      onQueryUpdate(state)
    }
  }, [state])

  useEffect(() => {
    if (!!onSelectionUpdate) {
      onSelectionUpdate(selected)
    }
  }, [selected])

  useEffect(() => {
    setTimeout(() => {
      handleCalcScrollData()
    }, 0)
  }, [containerRef, schema])

  const handleCalcScrollData = () => {
    const el = containerRef?.current

    if (el && (el.scrollWidth - el.clientWidth) > 10) {
      setScrollData({
        position: el.scrollLeft,
        width: el.scrollWidth - el.clientWidth
      })
    } else {
      setScrollData(null)
    }
  }

  const handleScrollToTop = () => {
    const el = rootTopGhostRef?.current

    if (el) {
      el.scrollIntoView({
        behavior: "smooth"
      })
    }
  }

  const handleChangeRowsPerPage = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState(current => ({
      ...current,
      itemsPerPage: parseInt(e?.target?.value || String(defaultState.itemsPerPage), 10),
      page: defaultState.page
    }))

    setSelected(defaultSelected)

    handleScrollToTop()
  }

  const handleChangePage = (_e: React.MouseEvent<HTMLButtonElement> | null, value: number) => {
    setState(current => ({
      ...current,
      page: value
    }))

    handleScrollToTop()
  }

  function getSortOption(sortValue: DataTableColConfig["sort"], state?: DataTableState) {
    const currentState = state || null
    const key = typeof sortValue === "string" ? sortValue : sortValue[0]
    const defaultOrderDesc = typeof sortValue === "string" ? false : !!sortValue[1].defaultOrderDesc
    const isSelected = currentState?.sort?.key === key
    const orderDesc = (isSelected && currentState?.sort.order === SortOrder.Asc) || (!isSelected && defaultOrderDesc)

    return isSelected && ((defaultOrderDesc && orderDesc) || (!defaultOrderDesc && !orderDesc)) ? null : {
      key,
      order: orderDesc ? SortOrder.Desc : SortOrder.Asc
    }
  }

  const handleChangeSort = (sortValue: DataTableColConfig["sort"]) => {
    setState(current => {
      const sortOption = getSortOption(sortValue, current)

      return {
        ...current,
        page: defaultState.page,
        sort: sortOption
      }
    })

    setSelected(defaultSelected)
  }

  const handleChangeSearchQuery = useCallback((value: string) => {
    setState(current => ({
      ...current,
      page: defaultState.page,
      searchQuery: value || null
    }))

    setSelected(defaultSelected)
  }, [])

  function handleResetFilters() {
    setFiltersKey(Math.random().toString(36).slice(2, 12))
  }

  const handleChangeFilter = (type: DataTableFilter["type"], id: string, value: any) => {
    setState(current => {
      function getParsedValue() {
        if (value === "true") {
          return true
        } else if (value === "false") {
          return false
        } else {
          return value
        }
      }

      const newFilters = uniqBy([
        {
          type,
          id,
          value: getParsedValue()
        },
        ...(current.filters || [])
      ], "id").filter(i => {
        if (typeof i.value === "boolean") {
          return true
        } else {
          return !!i.value
        }
      })

      return {
        ...current,
        page: defaultState.page,
        filters: newFilters.length ? newFilters : defaultState.filters
      }
    })

    setSelected(defaultSelected)
  }

  const handleCheckbox = (id: string) => {
    setSelected(current => {
      const idFound = current?.includes(id)

      return idFound ? current.filter(i => i !== id) : [...(current || []), id]
    })
  }

  const handleSelectAll = (allSelected: boolean) => {
    setSelected(
      allSelected || !list?.length ? defaultSelected : list.map(i => i._id)
    )
  }

  const renderFilter = useCallback((input: DataTableFilter) => {
    const key = `${input?.key || input?.id}-${filtersKey}`

    if (input.type === "select-single") {
      return (
        <SelectSingleFilter
          {...input}
          key={key}
          tableState={state}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else if (input.type === "toggle") {
      return (
        <ToggleFilter
          {...input}
          key={key}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else if (input.type === "date") {
      return (
        <DateFilter
          {...input}
          key={key}
          onChange={value => handleChangeFilter(input.type, input.id, value)}
        />
      )
    } else {
      return null
    }
  }, [state, filtersKey])

  const clearSelected = () => {
    setSelected(defaultSelected)
  }

  return (
    <Box position="relative">
      <Box ref={rootTopGhostRef} position="absolute" top={-40} height="1px"/>
      {isHeadExist && (
        <Box position="relative" width="100%" overflow="auto" className="scrollbar-hidden">
          <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} pt={0.45}>
            <Box display="flex" alignItems="center" gap={1}>
              {!!search && (
                <Box
                  width={(mainFilters?.length && (actionButtonsList?.length || actionsList?.length)) ? 260 : 300}
                  maxWidth="100%">
                  <Search
                    label={searchLabel}
                    disabled={typeof search === "object" && search.disabled}
                    select={typeof search === "object" && search.select}
                    onChange={handleChangeSearchQuery}
                    value={state.searchQuery}
                  />
                </Box>
              )}
              {(mainFilters || []).map(i => (
                <Box key={i.id}>
                  {renderFilter(i)}
                </Box>
              ))}
            </Box>
            {(secondaryFilters?.length || additionalFilters?.length || actionButtonsList?.length || actionsList?.length) && (
              <Box display="flex" alignItems="center" gap={1}>
                {(secondaryFilters || []).map(i => (
                  <Box key={i.id}>
                    {renderFilter(i)}
                  </Box>
                ))}
                {additionalFilters?.length && (
                  <Box>
                    <Button
                      onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}>
                      {showAdditionalFilters ? (
                        <ExpandLessIcon/>
                      ) : (
                        <ExpandMoreIcon/>
                      )}
                      {showAdditionalFilters ? "Less" : "More"} filters
                    </Button>
                  </Box>
                )}
                {actionButtonsList?.length && (
                  <Box display="flex" alignItems="center" gap={1}>
                    {actionButtonsList.map(i => {
                      return !("type" in i) ? (
                        <Button
                          key={i.key}
                          variant={i.variant || "contained"}
                          color={i.color}
                          startIcon={i.icon}
                          onClick={() => i.onClick(state, selected, clearSelected)}
                          disabled={i.disabled}>
                          {i.label}
                        </Button>
                      ) : i.type === "custom" && (
                        <React.Fragment key={i.key}>
                          {i.content(selected, clearSelected, state)}
                        </React.Fragment>
                      )
                    })}
                  </Box>
                )}
                {(filtersResettable || actionsList?.length) && (
                  <Box display="flex" alignItems="center" gap={1} flexDirection={actions?.options?.isLeftPosition  ? "row-reverse" : "row"}>
                    {filtersResettable && (
                      <Box>
                        <Tooltip arrow title="Reset filters">
                          <Button
                            sx={{px: 1.5, py: 1.5, minWidth: "unset"}}
                            disabled={resetFiltersDisabled}
                            onClick={handleResetFilters}>
                            <FilterAltOffIcon/>
                          </Button>
                        </Tooltip>
                      </Box>
                    )}
                    {actionsList?.length && (
                      <Box>
                        <ActionsMenu
                          options={actions.options}
                          state={state}
                          selected={selected}
                          id={String((list?.length || 0) + total + 1)}
                          actions={actionsList}
                          clearSelected={clearSelected}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {additionalFilters?.length && (
        <Collapse in={showAdditionalFilters}>
          <Box py={2}>
            <Grid container rowSpacing={3} columnSpacing={4}>
              {additionalFilters.map(i => (
                <Grid key={i.id} item xs={6}>
                  <Box>
                    <Divider textAlign="left">
                      <Typography variant="overline">
                        {i.label}
                      </Typography>
                    </Divider>
                    <Box className={s.additionalFilterContainer}>
                      {renderFilter(i)}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
      )}
      <Box mt={isHeadExist ? 1 : 0}>
        <Box minHeight="4px">
          {(!!list?.length && loading) && (
            <Box>
              <LinearProgress color="primary"/>
            </Box>
          )}
        </Box>
        {(!list?.length && loading) ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={24}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
              <CircularProgress/>
              {loadingMessage && (
                <Typography variant="h5">
                  {loadingMessage}
                </Typography>
              )}
            </Box>
          </Box>
        ) : (!loading && !!error) ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={24}>
            <Typography variant="h5">
              {typeof error === "string" ? error : "Something went wrong"}
            </Typography>
          </Box>
        ) : ((emptyCondition && emptyCondition()) || !(schema?.filter(Boolean).length) || (!list?.length && !loading)) ? (
          <Box>
            {typeof emptyDataMessage === "string" ? (
              <Box display="flex" justifyContent="center" alignItems="center" py={24}>
                <Typography variant="h5">
                  {emptyDataMessage}
                </Typography>
              </Box>
            ) : (
              emptyDataMessage
            )}
          </Box>
        ) : (
          <Box position="relative" width="100%" minWidth={fullWidth ? "100%" : 700}>
            <TableContainer
              ref={containerRef}
              sx={{position: "relative", width: "100%"}}
              onScroll={handleCalcScrollData}>
              <Table style={{tableLayout: fullWidth ? "fixed" : "auto"}}>
                <TableHead>
                  <TableRow>
                    {schema.filter(Boolean).map(({
                      headerText = "",
                      ...i
                    }, num) => {
                      const id = `${headerText}-${num}`
                      const style: SxProps<Theme> = {
                        whiteSpace: (i.headerNoWrap || headerNoWrap || noWrap) ? "nowrap" : "normal"
                      }
                      const isInteractive = isInteractiveHeaderText(headerText)
                      const sortKey = !i.sort ? null : ((typeof i.sort === "object" ? i.sort[0] : i.sort) || null)
                      const activeSort = state.sort?.key === sortKey ? state.sort.order : null
                      const isSelectAll = i.type === "checkbox" && i.selectAll
                      const checked = selected?.length === list?.length

                      return (
                        isSelectAll ? (
                          <CheckboxCell
                            key={`${(id || num) + "row"}-CheckboxCell-${num}`}
                            id={id}
                            checked={checked}
                            onClick={() => {
                              handleSelectAll(checked)


                              // if (!!i.handler) {
                              //   i.handler(!checked, cellData)
                              // }
                            }}
                            sx={style}
                          />
                        ) : (
                          <TableCell
                            key={id}
                            padding={isInteractive ? "checkbox" : undefined}
                            align={i.type === "actions" ? "center" : "left"}
                            sx={style}>
                            <Box sx={!!i.sort ? {
                              display: "flex",
                              alignItems: "center",
                              gap: .5
                            } : undefined}>
                              {isInteractive ? headerText.element : (
                                <Typography
                                  variant="body2"
                                  fontWeight="medium"
                                  color={activeSort ? "info.main" : undefined}>
                                  {headerText}
                                </Typography>
                              )}
                              {i.sort && (
                                <IconButton size="small" sx={{mt: .25}} onClick={() => handleChangeSort(i.sort)}>
                                  {activeSort === SortOrder.Asc ? (
                                    <ExpandMoreIcon color="info"/>
                                  ) : activeSort === SortOrder.Desc ? (
                                    <ExpandLessIcon color="info"/>
                                  ) : (
                                    <UnfoldMoreIcon/>
                                  )}
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        )
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map((cellData, num) => {
                    const id = "id" in cellData ? cellData?.id : cellData?._id

                    return (
                      <TableRow key={id || num + "row"}>
                        {schema.filter(Boolean).map((i, num) => {
                          const style: SxProps<Theme> = {
                            whiteSpace: ((noWrap || contentNoWrap) ? "nowrap" : i.contentWrap) || undefined
                          }

                          switch (i.type) {
                            case "actions":
                              return (
                                <ActionsCell
                                  key={`${(id || num) + "row"}-ActionsCell-${num}`}
                                  input={cellData}
                                  actions={(i as DataTableActionCol).actions}
                                  sx={style}
                                />
                              )
                            case "avatar":
                              return (
                                <AvatarCell
                                  key={`${(id || num) + "row"}-AvatarCell-${num}`}
                                  input={cellData}
                                  schemaColRef={i as DataTableAvatarCol}
                                  sx={style}
                                />
                              )
                            case "checkbox": {
                              const id = i.key(cellData)
                              const checked = i.checked ? i.checked(cellData) : !!selected?.find(i => i === id)
                              const disabled = !!i.disabled ? i.disabled(cellData) : false

                              return (
                                <CheckboxCell
                                  key={`${(id || num) + "row"}-CheckboxCell-${num}`}
                                  id={id}
                                  checked={checked}
                                  disabled={disabled}
                                  onClick={id => {
                                    handleCheckbox(id)

                                    if (!!i.handler) {
                                      i.handler(!checked, cellData)
                                    }
                                  }}
                                  sx={style}
                                />
                              )
                            }
                            case "custom":
                              return (
                                <TableCell
                                  key={`${(id || num) + "row"}-CustomCell-${num}-${num}`}
                                  sx={{
                                    whiteSpace: "pre-line",
                                    ...style
                                  }}>
                                  {(i as DataTableCustomCol).content(cellData)}
                                </TableCell>
                              )
                            default:
                              return (
                                <DefaultCell
                                  key={`${(id || num) + "row"}-DefaultCell-${num}`}
                                  input={cellData}
                                  schemaColRef={i as DataTableTextCol}
                                  sx={style}
                                />
                              )
                          }
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={cn(s.scrollableMarker, {[s.visible]: showScrollableMarker})}/>
            {!!total && (
              <Box className={s.paginationRow}>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={total}
                  rowsPerPage={state.itemsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page"
                    },
                    native: true
                  }}
                  nextIconButtonProps={lastPage ? {disabled: true} : undefined}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    borderBottom: 0,
                    minWidth: "450px"
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
