import React, {useState} from "react"
import {trim} from "lodash"
import {Link as RouterLink, useNavigate} from "react-router-dom"
import * as Yup from "yup"
import {Formik} from "formik"
import {Box, Button, Checkbox, FormHelperText, InputLabel, Link, TextField, Typography} from "@mui/material"
import useAuth from "hooks/useAuth"
import useIsMountedRef from "hooks/useIsMountedRef"
import InputAdornment from "@mui/material/InputAdornment"
import {useDispatch} from "store"
import {handleError} from "store/slices/notifier/notifier"
import CustomErrorClass from "store/slices/notifier/customErrorClass"
import {customErrors} from "store/slices/notifier/errorObject"
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"

export default function LoginAmplify(props) {
  const isMountedRef = useIsMountedRef()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [checked, setChecked] = useState(true)

  const {login} = useAuth()

  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{
        emailOrUsername: "",
        password: "",
        submit: null
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        emailOrUsername: Yup.string().max(255).required("Username is required"),
        password: Yup.string().required("Password is required")
      })}
      onSubmit={async (
        values,
        {setErrors, setStatus, setSubmitting, setFieldValue}
      ): Promise<void> => {
        try {
          const password = trim(values.password)
          setFieldValue("password", password)
          await login(values.emailOrUsername, password)

          if (isMountedRef.current) {
            setStatus({success: true})
            setSubmitting(false)
          }
        } catch (err) {
          dispatch(handleError(new CustomErrorClass(customErrors.INVALID_CREDENTIALS)))

          if (err.code === "UserNotConfirmedException") {
            navigate("/authentication/verify-code", {
              state: {
                username: values.emailOrUsername
              }
            })
            return
          }

          if (isMountedRef.current) {
            setStatus({success: false})
            setErrors({submit: err.message})
            setSubmitting(false)
          }
        }
      }}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box>
            <InputLabel htmlFor="email">
              Email
            </InputLabel>
            <TextField
              id="email"
              error={Boolean(touched.emailOrUsername && errors.emailOrUsername)}
              fullWidth
              helperText={touched.emailOrUsername && errors.emailOrUsername}
              margin="normal"
              name="emailOrUsername"
              placeholder="Enter your email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.emailOrUsername}
              variant="outlined"
              InputProps={{
                inputProps: {tabIndex: 1},
                endAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon color="primary"/>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <InputLabel htmlFor="password">
                Password
              </InputLabel>
              <Link
                color="secondary"
                component={RouterLink}
                to="/authentication/password-recovery"
                variant="body1">
                Forgot password
              </Link>
            </Box>
            <TextField
              id="password"
              error={Boolean(touched.password && errors.password)}
              placeholder="Enter password"
              fullWidth
              helperText={touched.password && errors.password}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              value={values.password}
              variant="outlined"
              InputProps={{
                inputProps: {tabIndex: 2},
                endAdornment: (
                  <InputAdornment position="start" onClick={() => setShowPassword(!showPassword)} sx={{cursor: "pointer"}}>
                    {showPassword ? <VisibilityOutlinedIcon color="primary"/> : <VisibilityOffOutlinedIcon color="primary"/>}
                  </InputAdornment>
                )
              }}
            />
          </Box>
          {errors.submit && (
            <Box>
              <FormHelperText error>
                {/*{errors.submit}*/}
                The credentials you have entered are invalid.
              </FormHelperText>
            </Box>
          )}
          <Box display="flex" alignItems="center" mb={{xs: 3, md: 5}}>
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} sx={{p: "3px"}}/>
            <Typography variant="body1" ml={1}>
              Remember me
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              tabIndex={3}
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              SIGN IN
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}
