import {useTheme} from "@mui/styles"
import React from "react"
import {Helmet} from "react-helmet-async"
import {Box, Container, Paper} from "@mui/material"
import useSettings from "hooks/useSettings"
import CustomBreadcrumbs, {CustomBreadcrumbsProps} from "components/CustomBreadcrumbs"

interface Props {
  helmetTitle?: string
  title?: string
  breadcrumbs?: CustomBreadcrumbsProps["breadcrumbs"]
  card?: boolean
  examCode?: string
}

export default function LayoutWithBreadcrumbs({
  helmetTitle,
  title,
  breadcrumbs,
  card = true,
  examCode,
  children
}: React.PropsWithChildren<Props>) {
  const theme = useTheme()

  const {settings} = useSettings()

  return (
    <>
      <Helmet>
        <title>
          {helmetTitle}
        </title>
      </Helmet>
      <Box pt={3} pb={8} minHeight="100%" bgcolor="white.main">
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <CustomBreadcrumbs breadcrumbs={breadcrumbs.filter(Boolean)} title={title}/>
            {examCode && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  maxWidth: 120,
                  width: "100%"
                }}>
                <img
                  src={`/examIcons/${examCode}.png`}
                  alt={examCode}
                  style={{
                    width: "100%",
                    aspectRatio: 1,
                    objectFit: "contain"
                  }}
                />
              </Box>
            )}
          </Box>
          <Box mt={3}>
            {card ? (
              <Paper
                sx={{
                  padding: 4,
                  border: `solid 1px ${theme.palette.kpNeutralColors.light}`,
                  borderRadius: "32px"
                }}>
                {children}
              </Paper>
            ) : (
              <>
                {children}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  )
}
